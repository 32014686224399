// scrollUtils.js
export const handleScrollForActiveSection = (setActiveSection) => {
    const tableOfContents = document.getElementById("tableOfContents");

    if (!tableOfContents) {
        console.warn("Table of contents element not found.");
        return; // Exit if table of contents doesn't exist
    }

    const handleScroll = () => {
        const scrollPosition = window.scrollY + 90;
        const headings = Array.from(tableOfContents.children).map(heading => heading.hash.slice(1));
        const sectionOffsets = headings.map(id => {
            const section = document.getElementById(id);
            if (!section) return null; // Skip if the section is not found
            return { id, offset: section.offsetTop };
        }).filter(Boolean); // Remove any null values

        // Finding the active section
        let currentSection = sectionOffsets.find((section, i) => {
            let nextSectionOffset = sectionOffsets[i + 1]?.offset;

            if (i === sectionOffsets.length - 1) {
                // Handle the last section
                let lastSection = document.getElementById(section.id);
                if (!lastSection) return false;
                let lastSectionOffset = lastSection.offsetTop;
                let lastSectionHeight = lastSection.parentNode.clientHeight + lastSection.parentNode.nextSibling?.clientHeight || 0;

                return scrollPosition >= lastSectionOffset && scrollPosition < lastSectionOffset + lastSectionHeight;
            } else {
                let currentSection = section.offset;
                let nextSection = nextSectionOffset;

                return scrollPosition >= currentSection && scrollPosition < nextSection;
            }
        });

        // Setting the active section
        setActiveSection(currentSection?.id || "");
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () =>         window.removeEventListener("scroll", handleScroll);
};