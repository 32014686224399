import { service1, service2, service3, service4, service5, service6, service7, service8, serviceAppDev1, serviceAppDev2, serviceAppDev3, serviceAppDev4, serviceAppDev5, serviceCloud1, serviceCloud2, serviceCloud3, serviceCloud4, serviceCloud5, serviceCloud6, serviceCloud7, serviceCloud8, serviceDevops1, serviceDevops10, serviceDevops2, serviceDevops3, serviceDevops4, serviceDevops5, serviceDevops6, serviceDevops7, serviceDevops8, serviceDevops9, serviceIcon1, serviceIcon2, serviceIcon3, serviceIcon4, serviceIcon5, serviceIcon6, serviceIcon7, serviceIcon8, serviceIt1, serviceIt2, serviceIt3, serviceIt4, serviceIt5, serviceIt6, serviceIt7, serviceIt8, serviceKeyFeaturesBg1, serviceKeyFeaturesBg2, serviceKeyFeaturesBg3, serviceKeyFeaturesBg4, serviceKeyFeaturesBg5, serviceKeyFeaturesBg6, serviceKeyFeaturesBg7, serviceKeyFeaturesBg8, serviceQa1, serviceQa10, serviceQa2, serviceQa3, serviceQa4, serviceQa5, serviceQa6, serviceQa7, serviceQa8, serviceQa9, serviceSoftware1, serviceSoftware2, serviceSoftware3, serviceSoftware4, serviceSoftware5, serviceSoftware6, serviceSoftware7, serviceUiux1, serviceUiux2, serviceUiux3, serviceUiux4, serviceUiux5, serviceUiux6, serviceUiux7, serviceUiux8, serviceWebDev1, serviceWebDev2, serviceWebDev3, serviceWebDev4, serviceWebDev5 } from "../assets";

const services = [
  {
    title: "Web Development",
    description: "We offer custom web development services that ensure your website is not only visually appealing but also highly functional and user-friendly.",
    details: "Our custom web development services focus on creating websites that are not only visually appealing but also highly functional and user-friendly. We deliver websites that meet the unique needs of your business, ensuring they are responsive, scalable, and secure.",
    keyPoints: [
      {
        icon: serviceWebDev1,
        point: "Custom Front-end Development",
      },
      {
        icon: serviceWebDev2,
        point: "Robust Back-end Development",
      },
      {
        icon: serviceWebDev3,
        point: "CMS Integration & Customization",
      },
      {
        icon: serviceWebDev4,
        point: "E-commerce Solutions",
      },
      {
        icon: serviceWebDev5,
        point: "Responsive & Adaptive Design",
      },
    ],
    keyFeatures: [
      {
        name: "Custom Design",
        feature: "We craft bespoke designs that reflect your brand’s identity and engage your target audience.",
      },
      {
        name: "Responsive Development",
        feature: "Our websites are optimized for all devices, ensuring a seamless experience across desktops, tablets, and mobile devices.",
      },
      {
        name: "Scalability",
        feature: "Built to grow with your business, our websites can easily handle increased traffic and expanded features.",
      },
      {
        name: "Security",
        feature: "We prioritize security, implementing the latest protocols and best practices to protect your website and user data.",
      },
      {
        name: "User Experience(UX)",
        feature: "We focus on intuitive navigation and fast loading times, providing a smooth and enjoyable experience for your users.",
      },
      {
        name: "SEO Optimization",
        feature: "Our development process includes SEO best practices to improve your website’s visibility on search engines.",
      },
      {
        name: "Maintenance and Support",
        feature: "We offer ongoing maintenance and support to ensure your website remains up-to-date and fully functional.",
      },
    ],
    whyUs: [
      {
        name: "Expertise in Cutting-Edge Technologies",
        desc: "Our team uses the latest web development tools and frameworks, ensuring your website is built with the best technology available.",
      },
      {
        name: "Customized Solutions Tailored to Your Needs",
        desc: "We offer custom web development that aligns perfectly with your business goals, making your website a true reflection of your brand.",
      },
      {
        name: "Focus on User Experience (UX) and Conversion",
        desc: "We design user experiences that drive engagement and conversions, ensuring visitors have a positive and impactful journey on your site.",
      },
      {
        name: "Responsive and Mobile-First Approach",
        desc: "Our mobile-first development ensures your website performs seamlessly across all devices, from smartphones to desktops.",
      },
      {
        name: "Security-First Development",
        desc: "With rigorous security measures in place, we protect your website and user data from potential threats.",
      },
      {
        name: "Scalable and Future-Proof Design",
        desc: "Our websites are built to grow with your business, allowing easy integration of new features as your needs evolve.",
      },
      {
        name: "Proven Track Record of Success",
        desc: "We have a history of delivering successful projects across various industries, driving tangible business results.",
      },
      {
        name: "Ongoing Support and Maintenance",
        desc: "We provide continuous support and maintenance, ensuring your website stays updated and fully functional.",
      },
      {
        name: "Transparent and Collaborative Process",
        desc: "Our collaborative approach values your input and ensures your vision is realized throughout the development process.",
      },
    ],
    cta: {
      title: "Get Your Custom Website Today",
      description: "Ready to build a website that truly represents your brand? Let's create a site that not only looks great but also drives results. Reach out now to start your web development journey.",
    },
    link: "/services/web-development",
    image: service1,
    featuresBgImage: serviceKeyFeaturesBg1,
    icon: serviceIcon1,
  },
  {
    title: "Mobile App Development",
    description: "Our team develops innovative mobile applications that provide seamless experiences for your users, helping you reach a wider audience.",
    details: "At Techieonix, we specialize in developing innovative mobile applications that deliver seamless user experiences and help you connect with your audience on the go. Our mobile apps are designed to be intuitive, robust, and fully compatible across a wide range of devices.",
    keyPoints: [
      {
        icon: serviceAppDev1,
        point: "Cross-Platform Compatibility",
      },
      {
        icon: serviceAppDev2,
        point: "User-Centric Design",
      },
      {
        icon: serviceAppDev3,
        point: "Performance Optimization",
      },
      {
        icon: serviceAppDev4,
        point: "Security Features",
      },
      {
        icon: serviceAppDev5,
        point: "Seamless Integration",
      },
    ],
    keyFeatures: [
      {
        name: "Cross-Platform Compatibility",
        feature: "We develop apps that function smoothly on both iOS and Android platforms, ensuring broad accessibility.",
      },
      {
        name: "User-Centric Design",
        feature: "Our designs prioritize the user experience, offering intuitive interfaces and easy navigation to enhance user engagement.",
      },
      {
        name: "Performance Optimization",
        feature: "We build apps that are optimized for speed and efficiency, providing a smooth and responsive experience even with high user loads.",
      },
      {
        name: "Security Features",
        feature: "Our apps incorporate advanced security measures to protect user data and ensure compliance with industry standards.",
      },
      {
        name: "Custom Functionality",
        feature: "We tailor app features to your specific business needs, integrating functionalities that support your goals and enhance productivity.",
      },
      {
        name: "Seamless Integration",
        feature: "Our mobile apps integrate seamlessly with your existing systems, providing a cohesive digital experience.",
      },
      {
        name: "Ongoing Support",
        feature: "We offer continuous support and updates to keep your app running smoothly and aligned with evolving technologies.",
      },
    ],
    whyUs: [
      {
        name: "Expertise in Cross-Platform Development",
        desc: "Our team builds mobile apps that run seamlessly on both iOS and Android, maximizing your reach and impact.",
      },
      {
        name: "User-Centric Design",
        desc: "We prioritize intuitive design and smooth navigation, creating apps that engage users and enhance their experience.",
      },
      {
        name: "Performance Optimization",
        desc: "Our apps are optimized for speed and efficiency, ensuring a fast and responsive user experience even under heavy use.",
      },
      {
        name: "Robust Security Measures",
        desc: "We integrate advanced security features to protect user data and ensure compliance with industry standards.",
      },
      {
        name: "Custom Functionality",
        desc: "We tailor app features to meet your specific business needs, enhancing productivity and user satisfaction.",
      },
      {
        name: "Seamless Integration",
        desc: "Our apps integrate smoothly with your existing systems, providing a cohesive digital experience for your users.",
      },
      {
        name: "Proven Development Process",
        desc: "We follow a streamlined development process, delivering high-quality apps on time and within budget.",
      },
      {
        name: "Ongoing Support and Updates",
        desc: "We offer continuous support and updates to keep your app running smoothly and aligned with evolving technologies.",
      },
      {
        name: "Collaborative and Transparent Workflow",
        desc: "We work closely with you throughout the development process, ensuring your vision is realized in the final product.",
      },
    ],
    cta: {
      title: "Launch Your Mobile App with Us",
      description: "Ready to turn your app idea into reality? Our mobile app development team is here to create a seamless and engaging user experience. Contact us today to get started.",
    },
    link: "/services/mobile-app-development",
    image: service2,
    featuresBgImage: serviceKeyFeaturesBg2,
    icon: serviceIcon2,
  },
  {
    title: "IT Consulting",
    description: "Our IT consulting services provide expert advice and strategies to optimize your IT infrastructure and drive business growth.",
    details: "Our IT consulting services provide strategic guidance and expert advice to help you optimize your IT infrastructure and navigate the complexities of technology. We work closely with you to ensure your IT strategy is fully aligned with your business objectives, driving efficiency and innovation.",
    keyPoints: [
      {
        icon: serviceIt1,
        point: "Strategic IT Roadmap Development",
      },
      {
        icon: serviceIt2,
        point: "Technology Infrastructure Assessment",
      },
      {
        icon: serviceIt3,
        point: "Cloud Readiness & Migration Planning",
      },
      {
        icon: serviceIt4,
        point: "IT Cost Management & Optimization",
      },
      {
        icon: serviceIt5,
        point: "Cybersecurity & Risk Mitigation Strategies",
      },
      {
        icon: serviceIt6,
        point: "Vendor Selection & Contract Management",
      },
      {
        icon: serviceIt7,
        point: "Digital Transformation Guidance",
      },
      {
        icon: serviceIt8,
        point: "Business Continuity & Disaster Recovery Planning",
      },
    ],
    keyFeatures: [
      {
        name: "Strategic Planning",
        feature: "We assist in developing IT strategies that align with your business goals, ensuring long-term success.",
      },
      {
        name: "Technology Assessment",
        feature: "Our experts evaluate your current IT setup and recommend improvements to enhance performance and security.",
      },
      {
        name: "Cost Optimization",
        feature: "We help you reduce IT costs by identifying inefficiencies and recommending cost-effective solutions.",
      },
      {
        name: "Risk Management",
        feature: "We provide insights into potential risks and help you implement measures to safeguard your IT assets.",
      },
      {
        name: "Digital Transformation",
        feature: "We guide you through the process of adopting new technologies, ensuring a smooth transition and integration with existing systems.",
      },
      {
        name: "Vendor Management",
        feature: "We offer support in selecting and managing vendors, ensuring you get the best value and service from your technology partners.",
      },
      {
        name: "Ongoing Support",
        feature: "Our consulting services include continuous support to adapt your IT strategy as your business evolves.",
      },
    ],
    whyUs: [
      {
        name: "Strategic IT Roadmap Development",
        desc: "We help you create a strategic IT roadmap that aligns with your business goals, ensuring long-term success.",
      },
      {
        name: "Expert Technology Assessment",
        desc: "Our experts thoroughly evaluate your IT infrastructure, identifying areas for improvement to boost performance and security.",
      },
      {
        name: "Cost Optimization Strategies",
        desc: "We develop cost-effective IT solutions that reduce expenses while enhancing efficiency and productivity.",
      },
      {
        name: "Risk Management and Compliance",
        desc: "We provide comprehensive risk assessments and ensure your IT systems comply with industry standards and regulations.",
      },
      {
        name: "Tailored Digital Transformation Guidance",
        desc: "We guide you through adopting new technologies, ensuring a smooth transition that drives innovation and growth.",
      },
      {
        name: "Vendor Selection and Management",
        desc: "We assist in selecting and managing vendors, ensuring you receive the best value and services from your technology partners.",
      },
      {
        name: "Continuous Support and Improvement",
        desc: "Our ongoing consulting services adapt to your evolving needs, keeping your IT strategy current and effective.",
      },
      {
        name: "Proven Expertise Across Industries",
        desc: "With experience in diverse industries, we bring tailored, innovative IT solutions that address your unique challenges.",
      },
      {
        name: "Collaborative Approach to Consulting",
        desc: "We work closely with you, fostering a partnership that ensures our IT solutions are perfectly aligned with your business objectives.",
      },
    ],
    cta: {
      title: "Optimize Your IT Strategy",
      description: "Looking for expert IT guidance? Let’s work together to optimize your IT infrastructure and align it with your business objectives. Schedule a consultation with our experts today.",
    },
    link: "/services/it-consulting",
    image: service3,
    featuresBgImage: serviceKeyFeaturesBg3,
    icon: serviceIcon3,
  },
  {
    title: "UI/UX Design",
    description: "Our UI/UX design services focus on creating intuitive and engaging user experiences that delight your customers and improve satisfaction.",
    details: "Our UI/UX design services are dedicated to crafting engaging, intuitive, and visually captivating digital experiences that resonate with your users. We believe that great design is more than just aesthetics; it's about creating an experience that is functional, seamless, and delightful for the user.",
    keyPoints: [
      {
        icon: serviceUiux1,
        point: "User Research & Persona Development",
      },
      {
        icon: serviceUiux2,
        point: "Information Architecture & User Flows",
      },
      {
        icon: serviceUiux3,
        point: "Wireframing & Interactive Prototyping",
      },
      {
        icon: serviceUiux4,
        point: "Visual Design & Branding Integration",
      },
      {
        icon: serviceUiux5,
        point: "Interaction Design & Microinteractions",
      },
      {
        icon: serviceUiux6,
        point: "Usability Testing & Iterative Improvements",
      },
      {
        icon: serviceUiux7,
        point: "Responsive Design for Multi - Device Compatibility",
      },
      {
        icon: serviceUiux8,
        point: "Accessibility Compliance & Inclusive Design",
      },
    ],
    keyFeatures: [
      {
        name: "User Research & Analysis",
        feature: "We begin by understanding your users through thorough research and analysis, ensuring that every design decision is centered around their needs and behaviors.",
      },
      {
        name: "Information Architecture (IA)",
        feature: "We organize your content logically, creating a clear structure that allows users to navigate your product effortlessly.",
      },
      {
        name: "Wireframing & Prototyping",
        feature: "We develop wireframes and interactive prototypes to test and refine the user experience, aligning it with your goals and user expectations.",
      },
      {
        name: "Visual Design",
        feature: "We create visually stunning designs that reflect your brand identity while ensuring functionality and user engagement.",
      },
      {
        name: "Interaction Design",
        feature: "We focus on intuitive interactions that enhance the overall user experience, making it more engaging and enjoyable.",
      },
      {
        name: "Usability Testing",
        feature: "We conduct rigorous usability testing to identify and address any issues early, ensuring a seamless user experience.",
      },
      {
        name: "Responsive & Adaptive Design",
        feature: "We design for all screen sizes, ensuring a consistent and optimized experience across desktops, tablets, and mobile devices.",
      },
      {
        name: "Accessibility Compliance",
        feature: "Our designs adhere to the latest accessibility standards, making your digital products inclusive and usable for all users.",
      },
      {
        name: "Ongoing Design Iteration",
        feature: "We continuously gather feedback and data to iterate and improve the user experience, keeping your product relevant and effective.",
      },
    ],
    whyUs: [
      {
        name: "User-Centered Approach",
        desc: "We place users at the heart of our design process, ensuring that the final product is both functional and enjoyable to use.",
      },
      {
        name: "Collaboration & Transparency",
        desc: "We work closely with you throughout the design process, maintaining open communication and transparency to ensure your vision is realized.",
      },
      {
        name: "Expertise Across Industries",
        desc: "Our design team has experience across various industries, allowing us to bring unique perspectives and innovative solutions to your project.",
      },
      {
        name: "Focus on Results",
        desc: "We design with the end goal in mind—whether it's increasing user engagement, improving conversion rates, or building brand loyalty, our designs are crafted to deliver measurable results.",
      },
    ],
    cta: {
      title: "Design Experiences That Engage",
      description: "Elevate your digital presence with captivating UI/UX design. Let's create user experiences that not only look stunning but also resonate with your audience. Connect with us today.",
    },
    link: "/services/ui-ux-design",
    image: service4,
    featuresBgImage: serviceKeyFeaturesBg4,
    icon: serviceIcon4,
  },
  {
    title: "QA & Testing",
    description: "Ensure your software is reliable and performs optimally under all conditions with our comprehensive quality assurance and testing services.",
    details: "Our comprehensive QA and testing services guarantee the reliability and performance of your software. We conduct thorough testing to identify and resolve issues, ensuring a flawless user experience.",
    keyPoints: [
      {
        icon: serviceQa1,
        point: "Automated Testing",
      },
      {
        icon: serviceQa2,
        point: "Manual Testing",
      },
      {
        icon: serviceQa3,
        point: "Performance Testing",
      },
      {
        icon: serviceQa4,
        point: "Security Testing",
      },
      {
        icon: serviceQa5,
        point: "Compatibility Testing",
      },
      {
        icon: serviceQa6,
        point: "Regression Testing",
      },
      {
        icon: serviceQa7,
        point: "Usability Testing",
      },
      {
        icon: serviceQa8,
        point: "Load Testing",
      },
      {
        icon: serviceQa9,
        point: "End-to-End Testing",
      },
      {
        icon: serviceQa10,
        point: "Continuous Testing",
      },
    ],
    keyFeatures: [
      {
        name: "Automated Testing",
        feature: "We utilize automated testing tools to increase testing efficiency and coverage.",
      },
      {
        name: "Manual Testing",
        feature: "Our team performs manual testing to identify and address issues that automated tests might miss.",
      },
      {
        name: "Performance Testing",
        feature: "We evaluate your software’s performance under various conditions to ensure it can handle real-world use.",
      },
      {
        name: "Security Testing",
        feature: "We test your software for vulnerabilities and ensure it complies with security standards.",
      },
      {
        name: "Compatibility Testing",
        feature: "We ensure your software works seamlessly across different devices, browsers, and platforms.",
      },
      {
        name: "Regression Testing",
        feature: "We conduct regression testing to ensure new changes don’t introduce new bugs into your software.",
      },
    ],
    whyUs: [
      {
        name: "Comprehensive Automated Testing",
        desc: "We utilize advanced automated testing tools to increase efficiency, ensuring thorough coverage and quick issue identification.",
      },
      {
        name: "Rigorous Manual Testing",
        desc: "Our team conducts detailed manual testing to catch defects that automated tests might miss, ensuring a flawless user experience.",
      },
      {
        name: "Performance and Load Testing",
        desc: "We evaluate your software’s performance under various conditions, ensuring it can handle real-world scenarios without compromise.",
      },
      {
        name: "Security Testing and Compliance",
        desc: "We rigorously test your software for vulnerabilities, ensuring it meets industry security standards and protects user data.",
      },
      {
        name: "Cross-Platform Compatibility Testing",
        desc: "Our testing ensures your software functions seamlessly across different devices, browsers, and operating systems.",
      },
      {
        name: "Continuous Regression Testing",
        desc: "We perform ongoing regression testing to ensure that new updates or changes do not introduce new issues, maintaining stability.",
      },
      {
        name: "Usability Testing",
        desc: "We focus on the user experience, testing for ease of use and ensuring that your software meets user expectations.",
      },
      {
        name: "End-to-End Testing",
        desc: "Our end-to-end testing ensures that all components of your application work together harmoniously from start to finish.",
      },
      {
        name: "Ongoing QA Support",
        desc: "We provide continuous QA support to ensure that your software remains reliable and up-to-date as your business grows.",
      },
    ],
    cta: {
      title: "Ensure Quality with Expert Testing",
      description: "Ready to deliver flawless software? Our QA & testing services guarantee reliability and performance. Get in touch to ensure your product is market-ready.",
    },
    link: "/services/qa-and-testing",
    image: service5,
    featuresBgImage: serviceKeyFeaturesBg5,
    icon: serviceIcon5,
  },
  {
    title: "Software Architecture Solutions",
    description: "We provide software architecture solutions that ensure your applications are scalable, maintainable, and aligned with your business goals.",
    details: "Our Software Architecture Solutions are designed to create a solid foundation for your applications, ensuring they are scalable, maintainable, and fully aligned with your business objectives. We understand that the architecture of your software is critical to its long-term success, which is why our experienced architects work closely with you to design frameworks that not only meet current needs but also anticipate future challenges and opportunities.",
    keyPoints: [
      {
        icon: serviceSoftware1,
        point: "Scalability & Performance Tuning",
      },
      {
        icon: serviceSoftware2,
        point: "Security Architecture & Best Practices",
      },
      {
        icon: serviceSoftware3,
        point: "Microservices & API Design",
      },
      {
        icon: serviceSoftware4,
        point: "Cloud-Native & Hybrid Solutions",
      },
      {
        icon: serviceSoftware5,
        point: "Technology Stack Selection & Evaluation",
      },
      {
        icon: serviceSoftware6,
        point: "System Integration & Interoperability",
      },
      {
        icon: serviceSoftware7,
        point: "Documentation & Knowledge Transfer",
      },
    ],
    keyFeatures: [
      {
        name: "Comprehensive Requirements Analysis",
        feature: "We start with a detailed analysis of your business and technical needs to design an architecture that aligns with your strategic goals.",
      },
      {
        name: "Scalable Design",
        feature: "Our architectures are built to scale, ensuring your applications can grow seamlessly with your business.",
      },
      {
        name: "Maintainability",
        feature: "We design for ease of maintenance, reducing long-term management costs and complexity through modular and well-documented code structures.",
      },
      {
        name: "Performance Optimization",
        feature: "We enhance application performance with strategies that ensure speed, efficiency, and responsiveness.",
      },
      {
        name: "Security Integration",
        feature: "Security is embedded into our designs, protecting your applications and data from threats while ensuring compliance with industry standards.",
      },
      {
        name: "Technology Stack Selection",
        feature: "We help you choose the right technology stack, drawing on our expertise across a wide range of technologies to ensure reliability and innovation.",
      },
      {
        name: "Future-Proofing",
        feature: "Our designs are flexible and adaptable, anticipating future trends and technological advancements to keep your software relevant.",
      },
      {
        name: "Microservices Architecture",
        feature: "We offer microservices solutions for businesses seeking agility, allowing for independent development, deployment, and scaling of services.",
      },
      {
        name: "Cloud-Native Architecture",
        feature: "We design cloud-native architectures that maximize cloud computing benefits, providing scalability and resilience.",
      },
      {
        name: "Integration & Interoperability",
        feature: "Our architectures ensure seamless integration with existing systems and third-party services, enhancing overall functionality.",
      },
      {
        name: "Documentation & Training",
        feature: "We provide comprehensive documentation and training, empowering your team to effectively manage and evolve the architecture.",
      },
    ],
    whyUs: [
      {
        name: "Scalable Architecture Design",
        desc: "We design software architectures that are built to scale with your business, ensuring long-term growth and flexibility.",
      },
      {
        name: "Performance Optimization",
        desc: "Our architectures are optimized for speed and efficiency, ensuring your applications deliver peak performance under any load.",
      },
      {
        name: "Security-First Approach",
        desc: "We integrate robust security measures into your architecture, protecting your applications and data from potential threats.",
      },
      {
        name: "Technology Stack Selection",
        desc: "We guide you in choosing the best technology stack, ensuring that your architecture is both innovative and reliable.",
      },
      {
        name: "Microservices and API Design",
        desc: "We specialize in microservices architecture, enabling independent development and deployment of services for greater agility.",
      },
      {
        name: "Cloud-Native and Hybrid Solutions",
        desc: "We design cloud-native architectures that leverage the full power of cloud computing, providing scalability and resilience.",
      },
      {
        name: "Seamless Integration and Interoperability",
        desc: "Our architectures are designed to integrate seamlessly with existing systems and third-party services, enhancing overall functionality.",
      },
      {
        name: "Future-Proof Design",
        desc: "We anticipate future trends and design architectures that are adaptable, ensuring your software remains relevant over time.",
      },
      {
        name: "Comprehensive Documentation and Training",
        desc: "We provide detailed documentation and training, empowering your team to manage and evolve the architecture effectively.",
      },
    ],
    cta: {
      title: "Build a Scalable Foundation",
      description: "Lay the groundwork for long-term success with robust software architecture. Let's design a solution that scales with your business. Contact us to start planning.",
    },
    link: "/services/software-architecture-solutions",
    image: service6,
    featuresBgImage: serviceKeyFeaturesBg6,
    icon: serviceIcon6,
  },
  {
    title: "Cloud Solutions",
    description: "Our cloud solutions make your business more flexible and scalable. We handle everything from cloud migration to ongoing management, ensuring your cloud is secure and helps you reach your goals.",
    details: "Our Cloud Solutions are designed to enhance your business's agility and scalability, allowing you to fully leverage the potential of cloud technology. We offer a comprehensive range of cloud services, from initial migration to ongoing management, ensuring that your cloud environment is secure, efficient, and aligned with your business goals.",
    keyPoints: [
      {
        icon: serviceCloud1,
        point: "Cloud Strategy & Consultation",
      },
      {
        icon: serviceCloud2,
        point: "Cloud Infrastructure Design & Setup",
      },
      {
        icon: serviceCloud3,
        point: "Public, Private, & Hybrid Cloud Solutions",
      },
      {
        icon: serviceCloud4,
        point: "Security & Compliance Management",
      },
      {
        icon: serviceCloud5,
        point: "Cloud Cost Optimization & Management",
      },
      {
        icon: serviceCloud6,
        point: "Disaster Recovery & Business Continuity",
      },
      {
        icon: serviceCloud7,
        point: "Cloud Automation & DevOps Integration",
      },
      {
        icon: serviceCloud8,
        point: "Performance Monitoring & Scalability",
      },
    ],
    keyFeatures: [
      {
        name: "Cloud Migration",
        feature: "We manage the seamless transition of your applications and data to the cloud, minimizing downtime and ensuring a smooth process.",
      },
      {
        name: "Scalability",
        feature: "Our cloud solutions are built to scale with your business, providing the flexibility to handle growth and changing demands effortlessly.",
      },
      {
        name: "Security & Compliance",
        feature: "We implement robust security measures and ensure compliance with industry standards, protecting your data and cloud environment from threats.",
      },
      {
        name: "Cost Optimization",
        feature: "We help you manage and optimize cloud costs, ensuring you maximize value while controlling expenses.",
      },
      {
        name: "Cloud Management",
        feature: "Our services include ongoing monitoring, management, and support, ensuring your cloud infrastructure remains efficient and up-to-date.",
      },
      {
        name: "Disaster Recovery",
        feature: "We design and implement disaster recovery solutions that ensure business continuity, safeguarding your data and operations against unexpected disruptions.",
      },
      {
        name: "Multi-Cloud & Hybrid Solutions",
        feature: "We provide expertise in multi-cloud and hybrid cloud strategies, enabling you to integrate and manage different cloud environments effectively.",
      },
      {
        name: "Automation & DevOps Integration",
        feature: "Our cloud solutions incorporate automation and DevOps practices, streamlining processes and improving efficiency across your cloud infrastructure.",
      },
      {
        name: "Performance Monitoring",
        feature: "We continuously monitor cloud performance, making adjustments as needed to ensure optimal operation and responsiveness.",
      },
      {
        name: "Cloud-Native Development",
        feature: "We support cloud-native application development, helping you build and deploy applications that are optimized for cloud environments.",
      },
    ],
    whyUs: [
      {
        name: "Expertise Across Cloud Platforms",
        desc: "Our team has deep expertise across leading cloud platforms like AWS, Azure, and Google Cloud. This allows us to tailor solutions that best fit your specific needs, regardless of your preferred cloud provider.",
      },
      {
        name: "Proven Track Record",
        desc: "With a history of successful cloud migrations and implementations, we have the experience to handle complex projects, ensuring a smooth transition and ongoing performance.",
      },
      {
        name: "End-to-End Service",
        desc: "From initial consultation and planning to migration, management, and support, we offer a comprehensive suite of services, providing you with a seamless cloud experience.",
      },
      {
        name: "Focus on Security & Compliance",
        desc: "Security is our top priority. We ensure that your cloud environment is fortified against threats and complies with industry regulations, giving you peace of mind.",
      },
      {
        name: "Scalability & Flexibility",
        desc: "Our solutions are designed to grow with your business, providing the scalability and flexibility you need to adapt to changing demands without disruption.",
      },
      {
        name: "Continuous Optimization",
        desc: "We don’t just set up your cloud infrastructure and leave it at that. We continuously monitor and optimize your cloud environment to ensure it remains efficient, cost-effective, and aligned with your evolving needs.",
      },
      {
        name: "24/7 Support",
        desc: "Our dedicated support team is available around the clock to assist with any issues or questions, ensuring your cloud operations run smoothly without interruptions.",
      },
      {
        name: "Innovation-Driven",
        desc: "We stay at the forefront of cloud technology trends and innovations, integrating the latest advancements into your cloud strategy to keep your business competitive and forward-thinking.",
      },
    ],
    cta: {
      title: "Unlock the Power of the Cloud",
      description: "Ready to enhance your business agility and scalability? Our cloud experts are here to guide you through every step of your cloud journey. Reach out to us today.",
    },
    link: "/services/cloud-solutions",
    image: service7,
    featuresBgImage: serviceKeyFeaturesBg7,
    icon: serviceIcon7,
  },
  {
    title: "DevOps Solutions",
    description: "Our DevOps solutions streamline your development and operations processes, improving efficiency and accelerating time to market.",
    details: "Our DevOps Solutions are designed to bridge the gap between development and operations, streamlining processes to improve efficiency and accelerate your time to market. By integrating industry best practices, we ensure seamless collaboration across teams, enabling continuous delivery and faster innovation.",
    keyPoints: [
      {
        icon: serviceDevops1,
        point: "CI/CD Pipeline Design & Implementation",
      },
      {
        icon: serviceDevops2,
        point: "Infrastructure as Code (IaC) Deployment",
      },
      {
        icon: serviceDevops3,
        point: "Automated Testing & Quality Assurance",
      },
      {
        icon: serviceDevops4,
        point: "Monitoring & Alerting Systems Setup",
      },
      {
        icon: serviceDevops5,
        point: "Security Integration in DevOps (DevSecOps)",
      },
      {
        icon: serviceDevops6,
        point: "Continuous Deployment & Delivery",
      },
      {
        icon: serviceDevops7,
        point: "Collaboration & Workflow Automation Tools",
      },
      {
        icon: serviceDevops8,
        point: "DevOps Maturity Assessment & Optimization",
      },
      {
        icon: serviceDevops9,
        point: "Cloud Integration & Management",
      },
      {
        icon: serviceDevops10,
        point: "Containerization & Orchestration (Docker, Kubernetes)",
      },
    ],
    keyFeatures: [
      {
        name: "Continuous Integration & Continuous Delivery (CI/CD)",
        feature: "We implement CI/CD pipelines that automate the build, test, and deployment processes, ensuring rapid and reliable software delivery.",
      },
      {
        name: "Infrastructure as Code (IaC)",
        feature: "Our approach to Infrastructure as Code allows you to manage and provision your infrastructure using code, ensuring consistency, reducing errors, and speeding up deployments.",
      },
      {
        name: "Automation",
        feature: "We introduce automation at every stage of the development lifecycle, from code integration and testing to deployment and monitoring, reducing manual effort and minimizing the risk of human error.",
      },
      {
        name: "Collaboration Tools",
        feature: "We integrate tools that foster collaboration between development and operations teams, breaking down silos and enabling better communication and coordination.",
      },
      {
        name: "Monitoring & Logging",
        feature: "Our solutions include robust monitoring and logging practices, giving you real-time insights into system performance and allowing for quick identification and resolution of issues.",
      },
      {
        name: "Scalability & Flexibility",
        feature: "We design DevOps pipelines that are scalable and flexible, capable of adapting to the changing needs of your business and technology stack.",
      },
      {
        name: "Security Integration",
        feature: "Security is embedded throughout the DevOps lifecycle, from development to deployment, ensuring your applications are secure by design.",
      },
      {
        name: "Performance Optimization",
        feature: "We continuously optimize your DevOps processes to enhance performance, reduce bottlenecks, and ensure smooth, uninterrupted operations.",
      },
      {
        name: "Cloud Integration",
        feature: "Our DevOps solutions are cloud-ready, leveraging the power of cloud computing to enhance scalability, agility, and cost-effectiveness.",
      },
      {
        name: "DevOps Consulting & Training",
        feature: "We offer consulting services to assess and improve your current DevOps practices, along with training to empower your teams to implement and manage DevOps processes effectively.",
      },
    ],
    whyUs: [
      {
        name: "Expertise in Leading DevOps Tools",
        desc: "We have extensive experience with the leading DevOps tools and platforms, enabling us to create customized solutions that fit your specific needs.",
      },
      {
        name: "Tailored Solutions",
        desc: "We understand that every organization is different, so we tailor our DevOps solutions to align with your business goals, technology stack, and operational requirements.",
      },
      {
        name: "Proven Methodology",
        desc: "Our proven methodology combines best practices with cutting-edge technology to deliver reliable, efficient, and scalable DevOps processes.",
      },
      {
        name: "Speed & Agility",
        desc: "By streamlining development and operations, our DevOps solutions accelerate your time to market, allowing you to respond faster to market demands and opportunities.",
      },
      {
        name: "Focus on Continuous Improvement",
        desc: "We continuously refine and optimize your DevOps processes, ensuring that your operations become more efficient and your software delivery more reliable over time.",
      },
      {
        name: "Collaborative Approach",
        desc: "We work closely with your teams, fostering a culture of collaboration that enhances communication, reduces friction, and drives better outcomes.",
      },
      {
        name: "Security-First Mindset",
        desc: "Our DevOps solutions prioritize security at every stage, ensuring that your applications and infrastructure are protected from potential threats.",
      },
      {
        name: "24/7 Support",
        desc: "Our support team is available around the clock to assist with any DevOps-related issues, ensuring that your operations run smoothly without interruption.",
      },
      {
        name: "Innovation & Automation",
        desc: "We stay ahead of industry trends, integrating the latest innovations and automation techniques into your DevOps processes to keep your business competitive.",
      },
    ],
    cta: {
      title: "Accelerate Your Development Pipeline",
      description: "Want to streamline your development and operations? Let’s enhance your processes with our expert DevOps solutions. Contact us to start optimizing today.",
    },
    link: "/services/devops-solutions",
    image: service8,
    featuresBgImage: serviceKeyFeaturesBg8,
    icon: serviceIcon8,
  },
];

export default services;
