import React, { useEffect, useState } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

import { Popup } from '../Components';

export const CodeBlock = ({ code, language }) => {
    const [popup, setPopup] = useState({ show: false, state: null, message: "" });

    useEffect(() => hljs.highlightAll(), []);

    const copyCode = () => {
        navigator.clipboard.writeText(code).then(() => {
            setPopup({
                show: true,
                state: "copied",
                message: "Code copied successfully!"
            });
        }).catch(() => {
            setPopup({
                show: false,
                state: "error",
                message: "Failed to copy"
            });
        })
    }


    return (
        <>
            <div className="relative">
                <span className="absolute right-4 top-2 body-sm text-white cursor-pointer hover:bg-[--white-10] px-2 py-1" onClick={copyCode}>Copy</span>
                <pre className="whitespace-pre-wrap break-words">
                    <code className={`language-${language} break-words rounded-lg`}>
                        {code}
                    </code>
                </pre>
            </div>

            {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
        </>
    );
};