import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  location,
  logoFooter,
  phone,
  email as emailIcon,
  clutch,
  goodFirms,
} from "../assets";
import { Popup, SocialIcons } from "../Components";
import services from "../Data/Services";
import { validateEmail } from "../utils/formvalidate";

export const Footer = () => {
  const [email, setEmail] = useState("");
  const [popup, setPopup] = useState({ show: false, state: null, message: "" });
  const [recaptchaToken, setRecaptchaToken] = useState("");

  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LdvvlUqAAAAANRjjb-ZtQWsAiuqzZF6VO_quDwZ", {
          action: "submit",
        })
        .then((token) => setRecaptchaToken(token));
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation
    if (validateEmail(email)) {
      setPopup({
        show: true,
        state: "error",
        message: "Please provide a valid email",
      });
      return;
    }

    // Checking reCAPTCHA verification
    if (!recaptchaToken) {
      setPopup({
        show: true,
        state: "error",
        message: "Please complete the reCAPTCHA",
      });
    }

    try {
      const payload = {
        message: `For Newsletter Subscription: ${email}`,
        "g-recaptcha-response": recaptchaToken,
      };
      console.log("Submitting form with payload:", payload);

      const response = await fetch("https://formspree.io/f/mnnaekol", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setPopup({
          show: true,
          state: "success",
          message: "Form submitted successfully!",
        });
        setEmail("");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setPopup({
        show: true,
        state: "error",
        message:
          "Something went wrong while submitting the form! Please try again or contact our support at contact@techieonix.com",
      });
    }
  };

  return (
    <>
      <footer className="bg-[--black-primary] px-5 md:px-10 xl:px-[--px-xl] text-white theme-section">
        {/* Footer */}
        <section className="py-10 md:py-[--py-lg] xl:py-20 space-y-10 md:space-y-[--py-lg] xl:space-y-20 center-inner">
          {/* Logo */}
          <Link
            to="/"
            title="Techieonix International"
            className="block w-fit mx-auto"
          >
            <img
              className="h-[88px] sm:h-[108px] lg:h-[130px]"
              src={logoFooter}
              alt="Techieonix International"
            />
          </Link>

          <div className="max-xl:space-y-[--py-lg] xl:flex justify-between">
            {/* CTA and Social links */}
            <div className="sm:w-[405px] sm:mx-auto xl:mx-0 space-y-6 max-xl:text-center">
              <div className="space-y-3">
                <h5>Looking for more digital insights?</h5>
                <p className="opacity-50">
                  Get our latest blog posts, research reports, and thought
                  leadership right in your inbox each month.
                </p>
              </div>

              <form className="relative lg:w-[406px]" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Business Email*"
                  name="email"
                  id="email"
                  className="rounded-full ring-1 ring-[#ffffff80] py-2 pl-4 pr-[132px] lg:pr-[140px] bg-transparent w-full focus-visible:outline-0"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className="btn btn-white w-[120px] h-full absolute right-0"
                >
                  Send
                </button>
              </form>

              <div className="max-lg:text-center space-y-3 lg:space-y-4">
                <h5>Follow Us here</h5>
                <div className="flex gap-x-2.5 max-xl:justify-center *:p-2 *:ring-1 *:ring-white *:transition-colors">
                  <SocialIcons footer={true} />
                </div>
                <div className="mt-6 flex max-sm:flex-col max-sm:items-center gap-2.5 max-xl:justify-center">
                  <a href="https://clutch.co/" target="_blank">
                    <img src={clutch} alt="clutch" />
                  </a>
                  <a href="https://www.goodfirms.co/" target="_blank">
                    <img src={goodFirms} alt="good firms" />
                  </a>
                </div>
              </div>
            </div>

            {/* Web Links */}
            <div className="max-sm:space-y-8 sm:grid grid-cols-2 md:grid-cols-3 gap-6 xl:w-[725px]">
              {/* Company */}
              <div className="space-y-4">
                <span className="caption uppercase opacity-50">Company</span>
                <ul className="space-y-1.5 *:py-0.5">
                  <li>
                    {" "}
                    <Link to="/about-us" className="hover:underline">
                      About Us
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/contact-us" className="hover:underline">
                      Contact Us
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/blogs" className="hover:underline">
                      Blogs
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/careers" className="hover:underline">
                      Careers
                    </Link>{" "}
                  </li>
                </ul>
              </div>

              {/* Services */}
              <div className="space-y-4">
                <span className="caption uppercase opacity-50">Services</span>
                <ul className="space-y-1.5 *:py-0.5">
                  {services.map((service) => (
                    <li key={service.title}>
                      <Link to={service.link} className="hover:underline">
                        {service.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Contact */}
              <div className="space-y-4">
                <span className="caption uppercase opacity-50">Contacts</span>
                <ul className="space-y-3">
                  <li className="flex gap-x-2">
                    <img src={location} alt="location" />
                    <span>Karachi - Pakistan</span>
                  </li>

                  <li className="flex gap-x-2">
                    <img src={phone} alt="phone" />
                    <span>+92 (313) 129-7309</span>
                  </li>

                  <li className="flex gap-x-2">
                    <img src={emailIcon} alt="email" />
                    <span>contact@techieonix.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Copyright */}
        <section className="py-5 sm:pb-10 xl:pb-[--py-lg] max-md:space-y-3 md:flex items-center gap-x-5 border-t border-[--white-10] center-inner">
          <p className="opacity-50">
            &copy; 2024 Techieonix International. All Rights Reserved
          </p>
          <Link
            to="/privacy-policy"
            className="link hover:underline inline-block"
          >
            Privacy Policy
          </Link>
        </section>
      </footer>

      {/* Popup */}
      {popup.show && (
        <Popup
          state={popup.state}
          message={popup.message}
          closePopup={() => setPopup({ ...popup, show: false })}
        />
      )}
    </>
  );
};
