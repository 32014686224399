import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { CaseStudyComp, ContactUsForm, Cta, Footer, Navbar, OurClients } from '../Components';
import caseStudies from '../Data/CaseStudies';


export const CaseStudy = () => {
    const [caseStudy, setCaseStudy] = useState(null);
    const { caseStudyParam } = useParams();

    useEffect(() => {
        const selectedCase = caseStudies.find(item => item.link === caseStudyParam);
        setCaseStudy(selectedCase);
    }, [caseStudy, caseStudyParam]);


    return (
        <>
            {/* Navbar */}
            <Navbar />




            {/* Hero */}
            <section className='service-hero-bg theme-section'>
                <div className='px-5 md:px-10 xl:px-[--px-xl] pt-[130px] lg:pt-[150px] xl:pt-[170px] pb-10 lg:pb-[--py-lg] xl:pb-20 space-y-6 sm:space-y-10 text-white center'>
                    <div>
                        <h5>Case Study</h5>
                        <h1>{caseStudy?.title}</h1>
                    </div>

                    <div className='md:flex flex-wrap gap-6 md:w-full max-md:space-y-3 *:bg-[--white-10] *:space-y-6 *:p-5 *:lg:p-8 *:rounded-3xl *:md:w-80 *:lg:w-[375px] *:grow'>
                        {caseStudy?.client &&
                            <div>
                                <h5 className='pb-3 border-b border-[#ffffff4d]'>Client</h5>
                                <h4>{caseStudy?.client}</h4>
                            </div>
                        }

                        <div>
                            <h5 className='pb-3 border-b border-[#ffffff4d]'>Industry</h5>
                            <h4>{caseStudy?.industry}</h4>
                        </div>

                        <div>
                            <h5 className='pb-3 border-b border-[#ffffff4d]'>Core Technologies</h5>
                            <h4>{caseStudy?.coreTech}</h4>
                        </div>
                    </div>
                </div>
            </section>




            {/* Content */}
            <section className='px-5 md:px-10 xl:px-[--px-xl] max-sm:py-10 pt-10 lg:pt-[--py-lg] xl:pt-20 space-y-10 lg:space-y-[--py-lg] xl:space-y-20 center theme-section'>
                {caseStudy?.content.map(el => (
                    <div className='max-sm:space-y-3 max-lg:space-y-6 md:pb-10 lg:pb-[--py-lg] xl:pb-20 lg:flex gap-x-6 md:border-b border-[#00000033]'>
                        <h2 className='lg:w-[376px] xl:w-[405px] shrink-0'>{el.title}</h2>

                        {/* Text */}
                        {(Object.keys(el)[1] === "text" && Object.keys(el).length === 2) && <p>{el.text}</p>}


                        {/* Cards */}
                        {Object.keys(el)[1] === "cards" &&
                            <div className='sm:flex flex-wrap gap-3 md:gap-5 max-sm:space-y-3 sm:w-full text-white'>
                                {el.cards.map(card => (
                                    <div className='p-5 space-y-3 rounded-[--round] bg-[--black-primary] sm:w-[200px] grow'>
                                        <h5>{card.heading}</h5>
                                        <p>{card.text}</p>
                                    </div>
                                ))}
                            </div>
                        }


                        {/* Sections */}
                        {(Object.keys(el)[1] === "text" && Object.keys(el)[2] === "sections") &&
                            <div className='space-y-6 sm:space-y-10'>
                                <p>{el.text}</p>

                                {el.sections.map(sect => (
                                    <div className='space-y-3 sm:space-y-6'>
                                        <h3>{sect.title}</h3>
                                        <div className='max-sm:space-y-3 sm:flex flex-wrap gap-3 md:gap-5 sm:w-full'>
                                            {sect.cards.map(card => (
                                                <div className='p-5 space-y-3 rounded-[--round] bg-[--blue-primary-10] sm:w-[200px] grow'>
                                                    <h5>{card.heading}</h5>
                                                    <p>{card.text}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }


                        {/* Card */}
                        {Object.keys(el)[1] === "card" &&
                            <p className={`p-5 sm:p-6 ${el.black ? "bg-[--black-primary] text-white" : "bg-[--blue-primary-10]"} rounded-[--round]`}>
                                {el.card}
                            </p>
                        }


                        {/* Cards full */}
                        {(Object.keys(el)[1] === "text" && Object.keys(el)[2] === "cardsFull") &&
                            <div className='space-y-3 md:space-y-5'>
                                <p>{el.text}</p>
                                {el?.cardsFull.map(card => (
                                    <div className='p-5 sm:p-6 space-y-4 bg-[--blue-primary-10] rounded-[--round]'>
                                        <h4>{card.heading}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: card.text.replace(/\n/g, "<br /><br />") }} />
                                    </div>
                                ))}
                            </div>
                        }


                        {/* Cards Grid */}
                        {Object.keys(el)[1] === "cardsGrid" &&
                            <div className="sm:flex flex-wrap gap-3 md:gap-5 max-sm:space-y-3 sm:w-full">
                                {el?.cardsGrid.map(card => (
                                    <div className='p-5 space-y-4 rounded-[--round] sm:w-[200px] grow border border-[--black-primary]'>
                                        <img src={card.icon} alt={`${card.heading}`} />
                                        <div className='space-y-3'>
                                            <h5>{card.heading}</h5>
                                            <p>{card.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }


                        {/* Bullets */}
                        {(Object.keys(el)[1] === "text" && Object.keys(el)[2] === "bullets") &&
                            <div className='p-5 sm:p-6 space-y-4 bg-[--blue-primary-10] rounded-[--round]'>
                                <p>{el?.text}</p>
                                <ul className='list-disc list-inside'>
                                    {el?.bullets.map(bullet => (<li>{bullet}</li>))}
                                </ul>
                            </div>
                        }

                    </div>
                ))}



                {/* Tech Stack */}
                <div className='max-sm:space-y-3 max-lg:space-y-6 md:pb-10 lg:pb-[--py-lg] xl:pb-20 lg:flex gap-x-6 md:border-b border-[#00000033]'>
                    <h2 className='lg:w-[376px] xl:w-[405px] shrink-0'>Technologies Used</h2>
                    <div className='flex flex-wrap gap-2'>
                        {caseStudy?.technoloies.map(el => (
                            <div className='flex gap-x-2 px-3 lg:px-6 py-2 lg:py-3 rounded-3xl border border-[--black-primary]'>
                                <img src={el.logo} alt={el.name} className='size-6' />
                                <span>{el.name}</span>
                            </div>
                        ))}
                    </div>
                </div>


                {/* Outcome */}
                {caseStudy?.outcome &&
                    <div className='max-sm:space-y-3 max-lg:space-y-6 md:pb-10 lg:pb-[--py-lg] xl:pb-20 lg:flex gap-x-6 md:border-b border-[#00000033]'>
                        <h2 className='lg:w-[376px] xl:w-[405px] shrink-0'>Outcome</h2>
                        <p className='p-5 sm:p-6 rounded-[--round] bg-[--black-primary] text-white'>{caseStudy.outcome}</p>
                    </div>
                }


                {/* Conclusion */}
                {caseStudy?.conclusion &&
                    <div className='max-sm:space-y-3 max-lg:space-y-6 md:pb-10 lg:pb-[--py-lg] xl:pb-20 lg:flex gap-x-6 md:border-b border-[#00000033]'>
                        <h2 className='lg:w-[376px] xl:w-[405px] shrink-0'>Conclusion</h2>
                        <p dangerouslySetInnerHTML={{ __html: caseStudy?.conclusion.replace(/\n/g, "<br /><br />") }}
                            className={!(caseStudy?.conclusion && caseStudy?.outcome) && "p-5 md:p-6 rounded-[--round] bg-[--black-primary] text-white"}
                        />
                    </div>
                }
            </section>




            {/* Screenshots */}
            <section className='sm:py-10 sm:px-5 md:px-10 xl:px-[--px-xl] space-y-5 sm:space-y-10 center theme-section'>
                {
                    caseStudy?.screenshots.map((image, i) => (
                        <img src={image} alt={caseStudy.client + "-" + (i + 1)} className="mx-auto rounded-[--round]" />
                    ))
                }
            </section>




            {/* Testimonial */}
            {caseStudy?.testimonial &&
                <section className='max-md:px-5 max-lg:px-10 py-10 lg:py-[--py-lg] xl:py-20 space-y-6 sm:space-y-10 bg-[--blue-primary] text-white theme-section'>
                    <h1 className='text-center'>Client Testimonial</h1>

                    <h5 className='p-5 md:p-10 lg:w-[828px] sm:mx-8 md:mx-auto xl:w-[--title-intro-width] rounded-[--round] bg-[--white-10] text-center'>
                        {caseStudy?.testimonial.review}
                    </h5>

                    <div className='space-y-4 w-fit mx-auto text-center'>
                        <div className='p-2.5 bg-white rounded-full w-fit mx-auto'>
                            <img src={caseStudy?.testimonial.clientLogo} alt={caseStudy?.testimonial.client} className='size-10' />
                        </div>

                        <div>
                            <h6>{caseStudy?.testimonial.clientName}</h6>
                            <span>{caseStudy?.testimonial.client}</span>
                        </div>
                    </div>
                </section>
            }





            {/* CTA */}
            <Cta
                title="Let’s Create Your Success Story"
                text="Ready to take your business to the next level? Partner with us to turn your challenges into opportunities and drive meaningful results. Contact us today to start your journey towards success."
                linkText="Start a Project"
            />

            {/* Case Study Component */}
            <CaseStudyComp isBlack={true} hideCase={true} />

            {/* Our Clients */}
            <OurClients />

            {/* Contact us CTA */}
            <ContactUsForm />

            {/* Footer */}
            <Footer />
        </>
    )
};