import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Navbar, Footer, ContactUsForm, BlogCta, BlogCard } from "../Components";
import blogs from "../Data/Blogs";


export const Blogs = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const { search } = useLocation();

  const categories = [...new Set(blogs.map(blog => blog.category))].sort();
  const filteredBlogs = selectedCategory === "All" ? blogs : blogs.filter((blog) => blog.category === selectedCategory);

  useEffect(() => {
    const category = new URLSearchParams(search).get("category");
    !category || category === "" ? setSelectedCategory("All") : setSelectedCategory(category);
  }, [search]);


  return (
    <>
      {/* Navbar */}
      <Navbar />



      {/* Hero */}
      <section className="blogs-hero-bg theme-section md:content-end md:h-[340px]">
        <div className="max-sm:pt-[110px] max-md:pt-[130px] max-md:p-5 sm:pb-10 md:px-10 xl:px-[--px-xl] text-white center">
          <h1>Explore Cutting-Edge Digital Trends and Insights</h1>
        </div>
      </section>





      {/* Sorting */}
      <section className="p-5 md:px-10 md:py-5 xl:px-[--px-xl] xl:py-10 flex flex-wrap gap-2.5 md:gap-x-2.5 border-b border-[--black-primary-10] *:px-4 *:lg:px-8 *:py-2 *:lg:py-3 *:bg-[--blue-primary-10] *:rounded-full *:transition-colors *:cursor-pointer center">
        <div className={`hover:bg-[--black-primary] hover:text-white cursor-pointer ${selectedCategory === "All" && "!bg-[--black-primary] !text-white"}`}
          onClick={() => setSelectedCategory("All")}
        >
          All
        </div>

        {categories.map((category, i) => (
          <div key={i}
            className={`hover:bg-[--black-primary] hover:text-white cursor-pointer ${selectedCategory === category && "!bg-[--black-primary] !text-white"}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </div>
        ))}
      </section>





      {/* Blogs */}
      <section className="md:grid grid-cols-2 gap-6 max-md:space-y-6 px-[5px] sm:px-5 max-sm:pt-5 sm:py-10 md:p-10 xl:px-[--px-xl] xl:py-10 center">
        {filteredBlogs.map((blog, i) => (
          <BlogCard
            key={i}
            image={blog.imageHero}
            category={blog.category}
            link={blog.link}
            title={blog.title}
            date={blog.publishedDate}
            readTime={blog.readTime}
            description={blog.content[0].textIntro || blog.content[0].text}
            headingLarge={true}
          />
        ))}
      </section>





      {/* Cta */}
      <BlogCta />

      {/* Contact Us */}
      <ContactUsForm />

      {/* Footer */}
      <Footer />
    </>
  );
};
