import React from "react";
import { Link } from "react-router-dom";

import { ContactUsForm, Footer, Navbar, OurClients } from "../Components";
import {
  caseMba,
  caseIms,
  caseEntraSign,
  caseSk,
  caseMbaBg,
  caseImsBg,
  caseEntraSignBg,
  caseSkBg,
  caseBeTravel,
  caseBeTravelBg
} from "../assets";

const cases = [
  {
    project:
      "Transforming Online Presence: A Custom Website for M.B.A Consultants",
    text: "We built a custom, responsive website from scratch for M.B.A Consultants, a financial and management consulting firm. Using the MERN stack, we ensured seamless performance across all devices, with a focus on SEO optimization and easy navigation. The result was a significant boost in client inquiries and a professional online presence reflecting the firm's expertise.",
    link: "/case-studies/mba-consultants",
    image: caseMba,
    bgImage: caseMbaBg,
  },
  {
    project:
      "Building a Modern and Responsive Website for SK Printing Solution",
    text: "We developed a responsive, visually appealing website for SkPrintingSolution to enhance their online presence and showcase their products. Using ReactJS and deploying via Vercel and Heroku, we ensured scalability and seamless user experience across devices. Our comprehensive branding strategy further strengthened their identity, driving increased engagement and business growth.",
    link: "/case-studies/sk-printing-solution",
    image: caseSk,
    bgImage: caseSkBg,
  },
  {
    project:
      "Automated User Sign-In Tracking and Compliance Monitoring System for Microsoft Entra ID",
    text: "We partnered with an organization using Microsoft Entra ID for identity management to create a system for tracking user sign-ins and ensuring compliance. The challenge was capturing the last sign-in details and automating proactive monitoring. Our solution leveraged Azure tools to extract user data, identify inactive users, send notifications, and provide detailed reporting, helping the client maintain compliance and streamline operations.",
    link: "/case-studies/entrasign-compliance-tracker",
    image: caseEntraSign,
    bgImage: caseEntraSignBg,
  },
  {
    project:
      "End-to-End Development of a Comprehensive Sales and Inventory Management Platform",
    text: "We built a Sales and Inventory Management Platform for a growing printing solutions company facing inefficiencies due to manual processes. Using the MERN stack, we developed a scalable solution with real-time analytics, sales management, and inventory control. The platform improved operational efficiency, enhanced data accuracy, and supported the client’s transition to online sales, driving increased revenue and growth.",
    link: "/case-studies/ims-sk-printing-solution",
    image: caseIms,
    bgImage: caseImsBg,
  },
  {
    project: "Building a User-Centric and Simplified Travel Website",
    text: "We developed a user-centric travel website that simplifies trip planning by allowing users to enter their destination, check-in, and check-out dates. The website was built using canva, focusing on user-friendly design. The website shows top destinations for users to decide easily, and provides promo codes for travellers to avail discounts, and handles all your worries during trip, from flight and hotel bookings to managing whole tour activities, thus ensuring the customer satisfaction. This not only streamlines the user experience but also boosts customer engagement and drives business growth by converting casual browsers into loyal travellers.",
    link: "/case-studies/be-travel",
    image: caseBeTravel,
    bgImage: caseBeTravelBg,
  },
];

export const CaseStudies = () => {
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Hero */}
      <section className="caseStudies-hero-bg theme-section md:content-end md:h-[340px]">
        <div className="max-sm:pt-[110px] max-md:pt-[130px] max-md:p-5 sm:pb-10 md:px-10 xl:px-[--px-xl] text-white center">
          <h1>Success Stories That Speak for Themselves</h1>
        </div>
      </section>

      {/* Intro */}
      <p className="px-5 sm:px-[52px] md:px-20 xl:px-0 lg:py-[--py-lg] py-10 xl:py-20 xl:w-[--title-intro-width] mx-auto text-center">
        Discover how our innovative solutions have empowered businesses across
        industries to achieve exceptional results. From startups to enterprises,
        explore real-world success stories that showcase the impact of our
        expertise and commitment to excellence.
      </p>

      {/* Case Studies */}
      <section>
        {cases.map((caseStudy, i) => (
          <div
            key={i}
            className="m-[5px] lg:grid grid-cols-2 lg:min-h-[591px] lg:h-full"
          >
            {/* Background + Image */}
            <div
              className={`group h-[280px] sm:h-[450px] lg:h-full grid place-items-center lg:overflow-hidden max-lg:rounded-t-[--round] bg-cover bg-no-repeat
            ${
              i % 2 !== 0
                ? "lg:order-1 lg:rounded-r-[--round]"
                : "lg:rounded-l-[--round]"
            }`}
              style={{ backgroundImage: `url(${caseStudy.bgImage})` }}
            >
              <img
                src={caseStudy.image}
                alt="Case Study - MBA"
                className="max-sm:w-[264px] max-lg:w-[400px] lg:group-hover:scale-110 transition-transform duration-300 ease-in-out"
              />
            </div>

            {/* Info */}
            <div
              className={`px-5 py-10 sm:px-8 md:px-10 space-y-6 sm:space-y-12 bg-[--black-primary] text-white content-end max-lg:rounded-b-[--round]
            ${
              i % 2 !== 0 ? "lg:rounded-l-[--round]" : "lg:rounded-r-[--round]"
            }`}
            >
              <div className="space-y-4 sm:space-y-6">
                <h2>{caseStudy.project}</h2>
                <p>{caseStudy.text}</p>
              </div>
              <Link
                to={caseStudy.link}
                className="btn btn-blue max-sm:w-full max-lg:text-center"
              >
                View Case Study
              </Link>
            </div>
          </div>
        ))}
      </section>

      {/* Our Clients */}
      <OurClients />

      {/* Contact us form */}
      <ContactUsForm />

      {/* Footer */}
      <Footer />
    </>
  );
};
