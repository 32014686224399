import React from 'react';
import { Link } from 'react-router-dom'
import { Chevron } from '../Components'


export const BlogBreadCrumb = ({ category, title }) => {
    return (
        <section className="px-5 md:px-10 xl:px-[--px-xl] py-3 flex max-lg:flex-wrap gap-2 mt-[95px] center theme-section">
            <Link to="/" className="flex items-center gap-x-1.5 lg:gap-x-2.5 group">
                <span className="group-hover:underline">Home</span>
                <div className="-rotate-90">
                    <Chevron className="fill-[--black-primary] size-5" />
                </div>
            </Link>

            <Link to="/blogs" className="flex items-center gap-x-1.5 lg:gap-x-2.5 group">
                <span className="group-hover:underline">Blogs</span>
                <div className="-rotate-90">
                    <Chevron className="fill-[--black-primary] size-5" />
                </div>
            </Link>

            <Link to={`/blogs?category=${category}`} className="flex items-center gap-x-1.5 lg:gap-x-2.5 group">
                <span className="group-hover:underline">{category}</span>
                <div className="-rotate-90">
                    <Chevron className="fill-[--black-primary] size-5" />
                </div>
            </Link>

            <p className="opacity-50">{title}</p>
        </section>
    )
}