import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { readingTime } from 'reading-time-estimator';

import { BlogBreadCrumb, BlogCta, ClockBlog, CodeBlock, ContactUsForm, Footer, LatestBlogs, MetaDecorator, Navbar, Popup, ShareSocial } from '../../Components';
import { blog10, blog10BackendPackage, blog10TestaddUser, blog10TestDb, blog10TestLogin, blog10AddAdminUser, developerAnas } from '../../assets';
import { handleScrollForActiveSection } from "../../utils/blogActiveSection";


export const UserManagementSystemBackend = () => {
    const [activeSection, setActiveSection] = useState("");
    const [readTime, setReadTime] = useState(null);

    // Popup
    const [popup, setPopup] = useState({ show: false, state: null, message: "" });


    useEffect(() => {
        // Calculating reading time
        const blogContent = document.getElementById("blogContent").innerText;
        setReadTime(`${readingTime(blogContent).minutes} min read`);

        // Scrolling for table of contents' active section
        const cleanup = handleScrollForActiveSection(setActiveSection);
        return cleanup;
    }, []);


    return (
        <>
            {/* Meta Data */}
            <MetaDecorator
                title="Backend Basics for a MERN User Management System | Techieonix International"
                imageUrl="https://stotechieonixassets.file.core.windows.net/techieonix-assets/blog-8.jpg?sv=2022-11-02&ss=f&srt=o&sp=r&se=2025-10-28T03:57:49Z&st=2024-10-27T19:57:49Z&spr=https,http&sig=FJsQ8eGyXYsp16U4Cj7gjWiBzABFNSeDpVlwJGtVsTk%3D"
                type="article"
            />

            {/* Navbar */}
            <Navbar white={true} />

            {/* Breadcrumb */}
            <BlogBreadCrumb category="Development" title="Backend Basics for a MERN User Management System" />




            {/* Blog */}
            <section className="space-y-10 px-5 md:px-10 xl:px-[--px-xl] lg:pt-10 xl:pb-10 center theme-section">

                {/* Header Image */}
                <div className="space-y-2 blog-head-position">
                    <img src={blog10} alt="Backend for MERN based User Management System built by Techieonix" title="Backend for MERN based User Management System built by Techieonix" className="rounded-[--round]" />
                    <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                        Credit: Freepik | Backend for MERN based User Management System built by Techieonix
                    </p>
                </div>


                {/* Header / Intro */}
                <div className="space-y-4 lg:space-y-6 border-[--black-primary-10] md:pt-5 pb-5 border-b blog-head-position">
                    <div className="space-y-3">
                        <h1>Backend Basics for a MERN User Management System</h1>
                        <div className="flex gap-x-5 text-xs">
                            <div>November 23, 2024</div>
                            <div className="flex items-center gap-x-1 px-2">
                                <ClockBlog fill="#232326" />
                                <span>{readTime}</span>
                            </div>
                        </div>
                    </div>
                    <span className="chip">Development</span>
                    <div className="flex items-center gap-x-2 lg:gap-x-4">
                        <img src={developerAnas} alt="Developer Anas" className="rounded-full size-[52px]" />
                        <div>
                            <h5>Syed Anas Raza</h5>
                            <p className="text-xs">Full-Stack Developer at Techieonix</p>
                        </div>
                    </div>
                </div>




                <div className="lg:flex gap-x-6 max-lg:space-y-10">

                    {/* Table of Contents */}
                    <div className="space-y-3 sm:space-y-6 lg:shrink-0 top-0 lg:sticky lg:pt-28 lg:w-[240px] xl:w-[298px] lg:h-screen">
                        <h5>Table of Contents</h5>

                        <div id="tableOfContents" className="flex flex-col gap-y-2 opacity-50 mt-[21px] *:w-fit body-sm">
                            <a href="#lets-start-with-its-anatomy" className={`${activeSection === "lets-start-with-its-anatomy" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Let’s start with its Anatomy
                            </a>

                            <a href="#backend-folder-structure" className={`${activeSection === "backend-folder-structure" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Backend Folder Structure
                            </a>

                            <a href="#prerequisites-for-starting-a-mern-project" className={`${activeSection === "prerequisites-for-starting-a-mern-project" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Prerequisites for Starting a MERN Project
                            </a>

                            <a href="#nodejs" className={`${activeSection === "nodejs" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Node.js
                            </a>

                            <a href="#npm-node-package-manager" className={`${activeSection === "npm-node-package-manager" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                npm (Node Package Manager)
                            </a>

                            <a href="#mongodb" className={`${activeSection === "mongodb" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                MongoDB
                            </a>

                            <a href="#visual-studio-code-vs-code" className={`${activeSection === "visual-studio-code-vs-code" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Visual Studio Code (VS Code)
                            </a>

                            <a href="#building-the-backend-with-node-and-express" className={`${activeSection === "building-the-backend-with-node-and-express" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Building the Backend with Node and Express
                            </a>

                            <a href="#step-1-getting-started-prerequisites-and-setup" className={`${activeSection === "step-1-getting-started-prerequisites-and-setup" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 1: Getting Started: Prerequisites and Setup
                            </a>

                            <a href="#step-2-installing-libraries-and-packages" className={`${activeSection === "step-2-installing-libraries-and-packages" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 2: Installing Libraries & Packages
                            </a>

                            <a href="#step-3-setting-up-the-backend-with-express-and-nodejs" className={`${activeSection === "step-3-setting-up-the-backend-with-express-and-nodejs" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 3: Setting Up the Backend with Express and Node.js
                            </a>

                            <a href="#setting-environment-variables" className={`${activeSection === "setting-environment-variables" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                Setting Environment Variables
                            </a>

                            <a href="#database-configuration" className={`${activeSection === "database-configuration" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                Database Configuration
                            </a>

                            <a href="#data-modeling" className={`${activeSection === "data-modeling" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                Data modeling
                            </a>

                            <a href="#api-logic" className={`${activeSection === "api-logic" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                API Logic
                            </a>

                            <a href="#middleware" className={`${activeSection === "middleware" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                Middleware
                            </a>

                            <a href="#api-routes" className={`${activeSection === "api-routes" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                API Routes
                            </a>

                            <a href="#adding-default-admin-user-in-the-database" className={`${activeSection === "adding-default-admin-user-in-the-database" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                Adding Default Admin User in the Database
                            </a>

                            <a href="#step-4-express-server-configuration" className={`${activeSection === "step-4-express-server-configuration" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 4: Express Server Configuration
                            </a>

                            <a href="#putting-it-all-together" className={`${activeSection === "putting-it-all-together" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Putting it All Together
                            </a>

                            <a href="#lets-conclude" className={`${activeSection === "lets-conclude" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Let's Conclude
                            </a>
                        </div>
                    </div>





                    {/* Content */}
                    <div id="blogContent" className="space-y-6 sm:space-y-10 body-lg">

                        <h6 className="blog-card-highlight">After going through the <Link to="/blogs/discovering-the-mern-stack-a-starters-route-to-full-stack-javaScript" target="_blank" className="blog-link">fundamentals of MERN stack</Link> are you ready for the next piece? Let’s get into building a simple MERN based application. This hands-on will help you understand how these technologies work together to create a fully functional application.</h6>


                        {/* Let’s start with its Anatomy */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="lets-start-with-its-anatomy">Let’s start with its Anatomy</h2>
                            <p>MERN (MongoDB, Express.js, React, Node.js) is a powerful technology stack that covers both frontend and backend needs, making it ideal for full-stack developers looking to build dynamic and scalable application.</p>
                            <p>In this blog, we’ll walk through developing a full-stack web application for <b>User Management Systems</b> using the MERN stack. We’ll cover each step, from setting up the backend to building an interactive frontend, ensuring you have a comprehensive understanding of the entire development process.</p>
                        </div>


                        {/* Backend Folder Structure */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="backend-folder-structure">Backend Folder Structure</h3>
                            <CodeBlock language="plaintext"
                                code={`└── Your_ParentDirectory/
    ├── Backend/
    │    ├── config
    │        ├── database.js
    │        └── ...
    │    ├── controller
    │        ├── userController.js
    │    ├── model
    │        ├── userModel.js
    │        └── ...
    │    ├── route
    │        ├── userRoute.js
    │        └── ...
    │    ├── index.js
    └── Frontend`}
                            />
                        </div>


                        {/* Prerequisites for Starting a MERN Project */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="prerequisites-for-starting-a-mern-project">Prerequisites for Starting a MERN Project</h2>
                            <p>Before you dive into building a MERN application, make sure you have the following tools installed and set up correctly. This will help you avoid compatibility issues and get started smoothly.</p>
                        </div>


                        {/* Node.js */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="nodejs">Node.js</h3>

                            <p>Node.js is essential for running server-side JavaScript and setting up your backend as we got to know <Link to="/blogs/discovering-the-mern-stack-a-starters-route-to-full-stack-javaScript#nodejs" target="_blank" className="blog-link">Discovering the MERN Stack...</Link> blog.</p>

                            <ul className='list-disc list-inside'>
                                <li><b>Minimum Version</b>: Node.js v14.x</li>
                                <li><b>Recommended Version</b>: Node.js v16.x or later for optimal performance and support.</li>
                            </ul>

                            <p>To check if Node.js is installed and to see your version, run:</p>

                            <CodeBlock language="powershell"
                                code={`node -v`}
                            />

                            <p>If Node.js isn’t installed, download and install it from the <a href="https://nodejs.org/" target='_blank' rel="noreferrer" className="blog-link">Node.js</a> website</p>
                        </div>


                        {/* npm (Node Package Manager) */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="npm-node-package-manager">npm (Node Package Manager)</h3>

                            <p><span className="inline-code">npm</span>, which comes with Node.js, is needed to manage packages and dependencies in your project.</p>

                            <ul className='list-disc list-inside'>
                                <li><b>Minimum Version</b>: npm v6.x</li>
                                <li><b>Recommended Version</b>: npm 8.x or later</li>
                            </ul>

                            <p>To verify your npm installation and version, use:</p>

                            <CodeBlock language="powershell" code={`npm -v`} />

                            <p>If you need to update npm to the latest version, run:</p>

                            <CodeBlock language="powershell" code={`npm install -g npm`} />
                        </div>


                        {/* MongoDB */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="mongodb">MongoDB</h3>

                            <p>We also learned about MongoDB in last MERN blog (<Link to="/blogs/discovering-the-mern-stack-a-starters-route-to-full-stack-javaScript#mongodb" target="_blank" className="blog-link">Discovering the MERN Stack...</Link>), which is the database where you’ll store and retrieve your application data.</p>

                            <ul className='list-disc list-inside'>
                                <li><b>Minimum Version</b>: MongoDB v4.x or later</li>
                            </ul>
                        </div>


                        {/* MongoDB Compass */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4>MongoDB Compass</h4>

                            <p>To work with MongoDB, you can use either the CLI or a GUI. In this project, we’re using the GUI tool <b>MongoDB Compass</b> for managing a local database.</p>

                            <p>To set up MongoDB locally, download it from the <a href="https://www.mongodb.com/try/download/community" target="_blank" rel="noreferrer" className="blog-link">MongoDB installation page</a> and follow the instructions for your operating system. Alternatively, use <b>MongoDB Atlas</b> for a managed cloud database.</p>
                        </div>


                        {/* Visual Studio Code (VS Code) */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="visual-studio-code-vs-code">Visual Studio Code (VS Code)</h3>

                            <p>VS Code is a popular code editor with powerful extensions and tools for JavaScript development. You can download VS Code from its <a href="https://code.visualstudio.com/download" target="_blank" rel="noreferrer" className="blog-link">official website</a>.</p>

                            <p>With these installed, you’re ready to start building your MERN application!</p>
                        </div>

                        <hr />

                        {/* Building the Backend with Node and Express */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="building-the-backend-with-node-and-express">Building the Backend with Node and Express</h2>

                            <p>In this section, we’ll set up the backend of our User Management System using Node.js and Express to handle data flow, user authentication, and API endpoints by following these steps:</p>

                            <ul className="list-inside list-decimal">
                                <li>Getting Started: Prerequisites and Setup</li>
                                <li>Installing Libraries & Packages</li>
                                <li>Setting Up the Backend with Express and Node.js</li>
                                <li>Express Server Configuration</li>
                            </ul>
                        </div>


                        {/* Step 1: Getting Started: Prerequisites and Setup */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-1-getting-started-prerequisites-and-setup">Step 1: Getting Started: Prerequisites and Setup</h3>

                            <p>First, create the project directory and set up the backend structure.</p>

                            <ul className="space-y-3 list-disc list-inside">
                                <li className="space-y-1">
                                    <span>Open the Command Prompt (CMD) and run the following commands:</span>
                                    <CodeBlock language="powershell"
                                        code={`md my-mern-app
code my-mern-app`}
                                    />
                                </li>

                                <li className="space-y-1">
                                    <span>Once the folder opens in VS Code, launch a new terminal and execute:</span>
                                    <CodeBlock language="powershell"
                                        code={`cd my-mern-app
md backend
cd backend
npm init -y`}
                                    />
                                </li>
                            </ul>

                            <p>This creates a <span className="inline-code">package.json</span> file, which will manage your project's dependencies.</p>

                            <h6 className="blog-card-highlight"><span className="inline-code">npm init -y</span> command sets up the project without asking questions. If you prefer to customize your project details, you can simply run <span className="inline-code">npm init</span> instead.</h6>
                        </div>


                        {/* Step 2: Installing Libraries & Packages */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-2-installing-libraries-and-packages">Step 2: Installing Libraries & Packages</h3>

                            <p>Next, install the essential packages for your backend. Run the following command in the terminal within your project directory.</p>

                            <CodeBlock language="powershell"
                                code={`npm install bcryptjs cors dotenv express jsonwebtoken mongoose`}
                            />

                            <ul className="list-disc list-inside">
                                <li><b>express</b>: In our last blog (<Link to="/blogs/discovering-the-mern-stack-a-starters-route-to-full-stack-javaScript#expressjs" target="_blank" className="blog-link" rel="noreferrer">Discovering the MERN Stack…</Link>), we learnt what express is.</li>
                                <li><b>mongoose</b>: MongoDB ODM (Object Data Modeling) library for schema-based data modeling.</li>
                                <li><b>dotenv</b>: Loads environment variables from a .env file.</li>
                                <li><b>cors</b>: Middleware to enable Cross-Origin Resource Sharing, allowing resource access across different domains.</li>
                                <li><b>bcryptjs</b>: Library for hashing passwords and securing sensitive data using the bcrypt algorithm.</li>
                                <li><b>jsonwebtoken</b>: Provides a set of methods for creating, signing, and verifying JWTs (JSON Web Tokens).</li>
                            </ul>

                            <p>Your package.json should now look something like this:</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog10BackendPackage} alt="package.json file within backend folder" title="package.json file within backend folder" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                                    <span className="inline-code">package.json</span> file within backend folder - Techieonix
                                </p>
                            </div>
                        </div>


                        {/* Step 3: Setting Up the Backend with Express and Node.js */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-3-setting-up-the-backend-with-express-and-nodejs">Step 3: Setting Up the Backend with Express and Node.js</h3>

                            <p>Writing code in a single file can lead to a hard-to-maintain mess. To create a clean and maintainable structure, we'll implement the <b>MVC (Model-View-Controller)</b> architecture. This separates the application logic, making it easier to manage and scale.</p>
                        </div>


                        {/* Setting Environment Variables */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="setting-environment-variables">Setting Environment Variables</h4>

                            <p>Let’s start by setting up environment variables to use across the project for securely managing sensitive information like database credentials and other configuration settings.</p>

                            <ul className="list-disc list-inside">
                                <li>Create a .env file in backend directory for environment variables</li>
                                <li>Write all the environment variables used across the application.</li>
                            </ul>

                            <CodeBlock language="powershell"
                                code={`# /backend/.env
MONGO_URL = mongodb://127.0.0.1:27017/userManagementSystem
PORT = 5000
SECRET_KEY = ThisIsASecretKeyForUserManagementSystem`}
                            />

                            <p>Here’s a breakdown of each variable:</p>

                            <ul className="list-disc list-inside">
                                <li><b>Replace</b> <span className="inline-code">MONGO_URL</span>: Use your actual MongoDB connection string, which you can find in your MongoDB Atlas dashboard or from your local MongoDB (Compass) setup (for example., <span className="inline-code">mongodb://localhost:27017/your_database_name</span>).</li>
                                <li><b>Set the</b> <span className="inline-code">PORT</span>: Specify the desired & available port for your application to run your backend on.</li>
                                <li><b>Configure</b> <span className="inline-code">SECRET_KEY</span>: Set a secure, random string for signing JWTs to ensure authentication security.</li>
                            </ul>

                            <p>Make sure to replace <span className="inline-code">MONGO_URL</span> with your actual MongoDB connection string, your desired and available <span className="inline-code">PORT</span> and <span className="inline-code">SECRET_KEY</span> with a secure key for signing tokens.</p>
                        </div>


                        {/* Database Configuration */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="database-configuration">Database Configuration</h4>

                            <p>Once we’ve declared the environment variables, the next task is to connect the application to the database. This involves setting up a connection string to link your backend to MongoDB.</p>

                            <ul className="list-disc list-inside">
                                <li>Create a <b>config</b> folder in backend directory.</li>
                                <li>Create a file (for example: <span className="inline-code">dbConnection.js</span>) to handle the database connection.</li>
                            </ul>

                            <CodeBlock language="javascript"
                                code={`/* /backend/config/dbConnection.js */
const mongoose = require('mongoose');
exports.connect = () => {
    mongoose.connect(process.env.MONGO_URL || [yourConnectionString]).then(() => {
        console.log('DB CONNECTED SUCCESSFULLY');
    }).catch(err => {
        console.log('DB CONNECTION FAILED');
        console.error(err);
        process.exit(1);
    });
}`}
                            />

                            <p>The <span className="inline-code">connect</span> method establishes a connection between MongoDB and the application using Mongoose, logging a success message or an error message if the connection fails.</p>
                        </div>


                        {/* Data modeling */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="data-modeling">Data modeling</h4>

                            <p>Define the structure of your data model for users:</p>

                            <ul className="list-disc list-inside">
                                <li>Create a folder named <b>model</b> within backend directory.</li>
                                <li>Create a file (for example: <span className="inline-code">userModel.js</span>) defining the user schema.</li>
                            </ul>

                            <CodeBlock language="javascript"
                                code={`/* /backend/model/userModel.js */
const { Schema, model } = require('mongoose');
const userSchema = new Schema({
    name: {
        type: String,
        required: [true, 'Please provide your name']
    },
    email: {
        type: String,
        required: [true, 'Please provide your email']
    },
    password: {
        type: String,
        required: [true, 'Please enter password'],
        select: false
    },
    role: {
        type: String,
        required: [true, 'Please select user role'],
        enum: ['Admin', 'Manager', 'User']
    },
    token: String,
    dob: String,
    phone: String,
    address: String
});
module.exports = model('User', userSchema);`}
                            />

                            <p>Define a Mongoose schema for Users in Mongo database, including fields for <span className="inline-code">name</span>, <span className="inline-code">email</span>, <span className="inline-code">password</span>, <span className="inline-code">role</span>, <span className="inline-code">token</span>, <span className="inline-code">dob</span>, <span className="inline-code">phone</span>, and <span className="inline-code">address</span>. The following are the required fields:</p>

                            <ul className="list-disc list-inside">
                                <li><span className="inline-code">name</span></li>
                                <li><span className="inline-code">email</span></li>
                                <li><span className="inline-code">password</span> (<span className="inline-code">select: false</span> will hide the password as default when we fetch the user)</li>
                                <li><span className="inline-code">role</span> (limited to specific values: "Admin", "Manager", or "User")</li>
                            </ul>

                            <p>After defining the schema, export the model to make it accessible in other parts of the application, such as the controllers.</p>
                        </div>


                        {/* API Logic */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="api-logic">API Logic</h4>

                            <p>With the user model in place, the next step is to implement the controllers that will handle the API endpoints for CRUD (Create, Read, Update, Delete) operations. Start by creating a <b>controllers</b> folder in the backend directory.</p>
                        </div>


                        {/* Auth Controller */}
                        <div className="space-y-3 sm:space-y-6">
                            <h5 id="auth-controller">Auth Controller</h5>

                            <ul className="list-disc list-inside">
                                <li>Create a file (for example: <span className="inline-code">authController.js</span>) for login logic:</li>
                            </ul>

                            <CodeBlock language="javascript"
                                code={`/* /backend/controllers/authController.js */
const User = require('../model/userModel');
const bcrypt = require('bcryptjs');
const jwt = require('jsonwebtoken');
const login = async (req, res) => {
    try {
        const { email, password } = req.body;
        if (!(email && password)) {
            return res.status(400).send('Email or password not provided!');
        }
        
        const user = await User.findOne({ email }).select('+password');
        if (!user) {
            return res.status(404).send('Email not found');
        }
        
        const passwordMatch = await bcrypt.compare(password, user.password);
        if (!passwordMatch) {
            return res.status(401).send('Invalid password');
        }
        
        const jwtToken = jwt.sign(
            { id: user._id, email: user.email, role: user.role },
            process.env.SECRET_KEY,
            { expiresIn: '10d' }
        );
        user.token = jwtToken;
        await user.save();

        return res.status(200).send({
            message: 'Login successful',
            token: jwtToken,
            user: {
                id: user._id,
                email: user.email,
                role: user.role
            }
        });
    } catch (error) {
        console.error(error);
        return res.status(500).send('Internal Server Error');
    }
}
module.exports = { login };`}
                            />

                            <p>This function manages user authentication by verifying credentials and generating a JWT token. The <span className="inline-code">select("+password")</span> part indicates that password is needed when fetched.</p>

                            <p>The function then responds with success, or appropriate error messages for any failures. Finally, it's exported in other parts of the application (e.g., routing).</p>
                        </div>


                        {/* User Controller */}
                        <div className="space-y-3 sm:space-y-6">
                            <h5 id="user-controller">User Controller</h5>

                            <ul className="list-disc list-inside">
                                <li>Create a file in <b>controllers</b> folder (for example: <span className="inline-code">userController.js</span>) for performing CRUD operations on user.</li>
                                <li>Write functions that implements the crud operation on user module.</li>
                            </ul>

                            {/* Add user */}
                            <p className="font-bold">Add User</p>

                            <CodeBlock language="javascript"
                                code={`/* /backend/controllers/userController.js */
const addUser = async (req, res) => {
    try {
        const existingEmail = await User.findOne({ email: req.body.email });
        if (existingEmail) {
            return res.status(409).send('Email is already in use');
        }

        const saltRounds = 10;
        const encryptedPassword = await bcrypt.hash(req.body.password, saltRounds);

        const user = await User.create({ ...req.body, password: encryptedPassword });
        return res.status(200).json({
            message: 'User added successfully',
            user: {
                id: user._id,
                email: user.email,
                role: user.role
            }
        });
    } catch (error) {
        console.error(error);
        return res.status(500).send('Internal Server Error');
    }
}`}
                            />

                            <p><span className="inline-code">addUser</span> function that handles user creation which checks if a user with the provided email already exists in the database. If the email is unique, it hashes the user's password, creates a new user record in the database, and responds with a success message.</p>

                            {/* Fetch All Users */}
                            <p className="font-bold">Fetch All Users</p>

                            <CodeBlock language="javascript"
                                code={`/* /backend/controllers/userController.js */
const getUsers = async (req, res) => {
    try {
        const users = await User.find({});
        if (users.length == 0) {
            return res.status(404).send('Users not found');
        }
        return res.status(200).json({
            message: 'Users fetched successfully',
            users
        });
    } catch (error) {
        console.error(error);
        return res.status(500).send(error.message || 'Internal Server Error');
    }
}`}
                            />

                            <p>Define <span className="inline-code">getUsers</span> function that retrieves all users from the database. If no users are found, it responds with a message indicating that users were not found. If users are successfully fetched, it returns the users' data.</p>

                            {/* Fetch Single User By ID */}
                            <p className="font-bold">Fetch Single User By ID</p>

                            <CodeBlock language="javascript"
                                code={`/* /backend/controllers/userController.js */
const getUserById = async (req, res) => {
    try {
        const id = req.params.id;
        const user = await User.findById(id);
        if (!user) {
            return res.status(404).json('User not found');
        }
                            return res.status(200).send(user);
    } catch (error) {
                                console.error(error);
                            return res.status(500).json('Internal Server Error');
    }
}`}
                            />

                            <p>This <span className="inline-code">getUserById</span> function is retrieving a user by their ID from the request parameters. If the user is found, it responds with a 200 status and the user data, if not found, it returns a "User not found" message.</p>

                            {/* Delete User */}
                            <p className="font-bold">Delete User</p>

                            <CodeBlock language="javascript"
                                code={`/* /backend/controllers/userController.js */
const deleteUser = async (req, res) => {
    try {
        const id = req.params.id;
        const user = await User.findById(id);
        if (!user) {
            return res.status(404).send('User not found at given id');
        }
        await user.deleteOne();
        return res.status(200).json({
            message: 'User deleted successfully',
            user
        });
    } catch (error) {
        console.error(error);
        return res.status(500).json('Internal Server Error');
    }
}`}
                            />

                            <p>The <span className="inline-block">deleteUser</span> function handles the deletion of a user by the user ID. It then searches the user in the database. If it's not found, it returns an error message. Otherwise, it deletes the user with returns a success message.</p>

                            {/* Update User */}
                            <p className="font-bold">Update User</p>

                            <CodeBlock language="javascript"
                                code={`/* /backend/controllers/userController.js */
const updateUser = async (req, res) => {
    try {
        const id = req.params.id;
        let user = await User.findById(id);
        if (!user) {
            return res.status(404).send('User not found');
        }

        const existingEmail = await User.findOne({ email: req.body.email });
        if (existingEmail) {
            return res.status(409).send('Email is already in use');
        }

        await user.updateOne(req.body, { new: true });
        res.status(200).send('User updated successfully');
    } catch (error) {
        console.error(error);
        return res.status(500).send('Internal Server Error');
    }
}`}
                            />

                            <p>Implementing an asynchronous function <span className="inline-code">updateUser</span> that updates a user's details. If the user is not found, it responds with an error message. It’s also making sure that the email is unique. Upon successful update, it updates and returns a success message.</p>

                            <h6 className="blog-card-highlight">Each function (<span className="inline-code">addUser</span>, <span className="inline-code">getUsers</span>, <span className="inline-code">getUserById</span>, <span className="inline-code">deleteUser</span>, <span className="inline-code">updateUser</span>) includes error handling with appropriate status codes and messages to manage exceptions during database operations.</h6>

                            <p>Finally, export all of these above written functions</p>

                            <CodeBlock language="javascript" code={`module.exports = { addUser, getUsers, deleteUser, updateUser, getUserById };`} />

                            <p>With the core API logic set up in the controllers, the next step is to secure these routes with middleware for authenticated users.</p>
                        </div>


                        {/* Middleware */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="middleware">Middleware</h4>

                            <ul className="list-disc list-inside">
                                <li>Create a <b>middleware</b> folder in backend directory.</li>
                                <li>Create a file in this folder (for example: <span className="inline-code">auth.js</span>) for protecting routes.</li>
                            </ul>

                            <CodeBlock language="javascript"
                                code={`/* /backend/middleware/auth.js */
const jwt = require('jsonwebtoken');
const authorizeRoles = (...allowedRoles) => {
    return (req, res, next) => {
        try {
            const token = req.headers.authorization.split(' ')[1];
            if (!token || token === 'null') {
                return res.status(401).send('Authentication failed: No token provided');
            }

            const decodedToken = jwt.verify(token, process.env.SECRET_KEY);
            if (!allowedRoles.includes(decodedToken.role)) {
                return res.status(403).json({ role: decodedToken.role, message: 'Authorization failed: Access denied' });
            }
            req.user = decodedToken;
            next();
        } catch (error) {
            console.error('Authorization error:', error);
            return res.status(401).send('Authentication failed: Invalid token');
        }
    }
}
module.exports = { authorizeRoles };`}
                            />

                            <p>This middleware function checks if the user has the required roles to access certain routes.</p>

                            <p>The <span className="inline-code">select("+password")</span> middleware restricts access based on user roles by verifying the JWT token from the <span className="inline-code">Authorization</span> header. It returns a status (401 or 403) and an error message if authentication fails; otherwise, it is exported for protected routes.</p>
                        </div>


                        {/* API Routes */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="api-routes">API Routes</h4>

                            <p>With the controllers are handled and the middleware is set up, the next step is to define and organize routes for each API endpoint.</p>

                            <ul className="list-disc list-inside">
                                <li>Create a <b>route</b> folder in backend directory.</li>
                                <li>Create a file in this folder (for example: <span className="inline-code">userRoutes.js</span>) for user-related routes.</li>
                            </ul>

                            <CodeBlock language="javascript"
                                code={`/* /backend/route/userRoutes.js */
const express = require('express');
const { authorizeRoles } = require('../middleware/auth');
const { addUser, getUsers, getUserById, deleteUser, updateUser } = require('../controller/userController');
const { login } = require('../controller/authController');
const router = express.Router();

router.post('/login', login);

router.post('/users', authorizeRoles('Admin'), addUser);
router.get('/users', authorizeRoles('Admin', 'Manager'), getUsers);
router.get('/users/:id', authorizeRoles('Admin', 'Manager'), getUserById);
router.delete('/users/:id', authorizeRoles('Admin'), deleteUser);
router.put('/users/:id', authorizeRoles('Admin'), updateUser);

module.exports = router;`}
                            />

                            <p>This code sets up an Express router (<span className="inline-code">express.Router()</span>) for handling user-related routes. It uses <span className="inline-code">authorizeRoles</span> middleware to control access based on roles in most of the functions from controllers. Following are the APIs endpoints and its function:</p>

                            <ul className="list-disc list-inside">
                                <li><span className="inline-code">POST /login</span>: Calls <span className="inline-code">login</span> from <span className="inline-code">authController</span> for user login.</li>
                                <li><span className="inline-code">POST /users</span>: Calls <span className="inline-code">addUser</span> from <span className="inline-code">userController</span> to create a new user only if the user is an "Admin".</li>
                                <li><span className="inline-code">GET /users</span>: Calls <span className="inline-code">getUsers</span> from <span className="inline-code">userController</span> to fetch a list of users only if the user is "Admin" or "Manager".</li>
                                <li><span className="inline-code">GET /users/:id</span>: Calls <span className="inline-code">getUserById</span> from <span className="inline-code">userController</span> to get a specific user by ID if the user is "Admin" or "Manager".</li>
                                <li><span className="inline-code">DELETE /users/:id</span>: Calls <span className="inline-code">deleteUser</span> from <span className="inline-code">userController</span> to delete a user by ID only if the user is "Admin".</li>
                                <li><span className="inline-code">PUT /users/:id</span>: Calls <span className="inline-code">updateUser</span> from <span className="inline-code">userController</span> to update a user by ID only if the user is "Admin".</li>
                            </ul>

                            <p>Finally, these routes are exported to be used in the main application file (for example: <span className="inline-code">app.js</span>).</p>
                        </div>


                        {/* Adding Default Admin User in the Database */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="adding-default-admin-user-in-the-database">Adding Default Admin User in the Database</h4>

                            <p>To create an initial admin user when your application starts, add a function that creates an admin user.</p>

                            <ul className="list-disc list-inside">
                                <li>Create a <b>util</b> folder in backend directory.</li>
                                <li>Create a file in this folder (for example: <span className="inline-code">seed.js</span>) to write logic for adding the user.</li>
                            </ul>

                            <CodeBlock language="javascript" code={`/* /backend/util/seed.js */
require('../config/database').connect();
const User = require('../model/userModel');
const bcrypt = require('bcryptjs');
const jwt = require('jsonwebtoken');
const addUser = async (name, email, password, role) => {
    try {
        const existingEmail = await User.findOne({ email });
        if (existingEmail) {
            console.log({
                status: 409,
                message: 'Email already exists'
            })
            process.exit(1);
        }
        const saltRounds = 10;
        const encryptedPassword = await bcrypt.hash(password, saltRounds);
        const user = await User.create({ name, email, password: encryptedPassword, role });
        console.log({
            status: 200,
            message: 'User added successfully',
            user: {
                id: user._id,
                email: user.email,
                role: user.role
            }
        })
        process.exit(0);
    } catch (error) {
        console.error(error);
        console.log({
            status: 500,
            message: 'Internal Server Error'
        })
        process.exit(1);
    }
}

addUser('Test', 'test@gmail.com', 'abc123', 'Admin');`}
                            />

                            <p>This script connects to the database, checks email’s uniqueness, hashes the password, and creates the user. It saves the user to the database. If successful, it logs a success message with user info and token, otherwise it logs an error and exits. The function is called with sample data to add a default admin user:</p>
                        </div>


                        {/* Step 4: Express Server Configuration */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-4-express-server-configuration">Step 4: Express Server Configuration</h3>

                            <p>Now set up the Express server in the <span className="inline-code">server.js</span> file to get your backend server running. This will handle all incoming requests, connect to your database, and route them to the appropriate controllers.</p>

                            <ul className="list-disc list-inside">
                                <li>Create a file in this folder (for example: <span className="inline-code">server.js</span>) in backend directory.</li>
                            </ul>

                            <CodeBlock language="javascript"
                                code={`/* /backend/server.js */
require('dotenv').config();
require('./config/database').connect();
const express = require('express');
const cors = require('cors');
const userRoutes = require('./route/userRoutes');

const app = express();

app.use(express.json());
app.use(cors());
app.use('/api', userRoutes);

app.listen(process.env.PORT, () => console.log(\`Server is Listening on \${PORT}\`));`}
                            />

                            <p>This code initializes an Express server that loads environment variables from a <span className="inline-code">.env</span> file, connects to the database, and applies middleware:</p>

                            <ul className="list-disc list-inside">
                                <li><span className="inline-code">app.use(express.json())</span>: Enables JSON parsing for requests.</li>
                                <li><span className="inline-code">app.use(cors())</span>: Allows Cross-Origin Resource Sharing.</li>
                            </ul>

                            <p>User routes are mounted under <span className="inline-code">/api</span> (customizable endpoint). Finally, the server starts on the specified port (defaulting to 5000 which is changeable) with a confirmation log.</p>
                        </div>


                        {/* Putting it All Together */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="putting-it-all-together">Putting it All Together</h2>

                            <p>Now, let’s bring everything together by running the API we have set up so far. Execute the following command to run the Express server:</p>

                            <CodeBlock language="javascript"
                                code={`cd backend
node server.js`}
                            />
                        </div>


                        {/* Create An Admin User */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4>Create An Admin User</h4>

                            <p>First create an admin user who can manage other users by executing the utility script.</p>

                            <ul className="space-y-3 list-disc list-inside">
                                <li className="space-y-1">
                                    <span>Open a new terminal window in the root project directory and run the following command to navigate to the util folder in the backend directory:</span>
                                    <CodeBlock language="powershell" code={`cd backend/util`} />
                                </li>
                                <li className="space-y-1">
                                    <span>
                                        Open the <span className="inline-code">seed.js</span> file in your code editor and adjust the function call within it to set the desired credentials for the admin user (e.g., <span className="inline-code">email</span>, <span className="inline-code">password</span>).
                                    </span>
                                    <div className="space-y-2 max-sm:py-3">
                                        <img src={blog10AddAdminUser} alt="Add default admin user" title="Add default admin user" className="mx-auto rounded-[--round]" />
                                        <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">Add default admin user - Techieonix</p>
                                    </div>
                                </li>
                                <li className="space-y-1">
                                    <span>Execute the Script</span>
                                    <CodeBlock language="powershell" code={`node seed.js`} />
                                </li>
                            </ul>
                        </div>


                        {/* Login with the admin user */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4>Login with the admin user</h4>

                            <p>After creating the user login with the admin user.</p>

                            <ul className="list-disc list-inside">
                                <li>Open postman and create a new request.</li>
                                <li>Select the request type to POST and enter the API url (<span className="inline-code">http://localhost:5000/api/login)</span></li>
                                <li>Select <span className="inline-code">Body</span> {">"} <span className="inline-code">raw</span> {">"} <span className="inline-code">JSON</span></li>
                                <li>Enter <span className="inline-code">email</span> and <span className="inline-code">password</span> as payload and submit the request</li>
                                <li>Copy this given confidential token and save it.</li>
                            </ul>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog10TestLogin} alt="Login" title="Login" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">Login - Techieonix</p>
                            </div>
                        </div>


                        {/* Creating a new User */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4>Creating a new User</h4>

                            <p>Since you've logged in as an admin user, you can call an API. Let's create a new user by calling the <span className="inline-code">POST /api/users</span>.</p>

                            <ul className="list-disc list-inside">
                                <li>Create a new request</li>
                                <li>Select the request type to POST and enter the API url (<span className="inline-code">http://localhost:5000/api/users)</span></li>
                                <li>In Authorization section, select Auth Type as <span className="inline-code">Bearer Token</span> and paste token you copied</li>
                                <li>Select <span className="inline-code">Body</span> {">"} <span className="inline-code">raw</span> {">"} <span className="inline-code">JSON</span></li>
                                <li>Write the payload with your desired details and hit Send button</li>
                            </ul>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog10TestaddUser} alt="Add user" title="Add user" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">Add user - Techieonix</p>
                            </div>

                            <p>The below given response confirms that the user has added successfully to the database. You can then check MongoDB to verify that the user appears in the <span className="inline-code">users</span> collection.</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog10TestDb} alt="Users MongoDB database" title="Users MongoDB database" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">Users MongoDB database - Techieonix</p>
                            </div>
                        </div>


                        {/* CTA */}
                        <div className="p-5 sm:p-8 md:p-10 flex flex-col gap-y-6 bg-[#EAEBF3] rounded-[--round]">
                            <h3>Ready to build a scalable and efficient backend for your next project?</h3>

                            <h5>At Techieonix, we specialize in creating robust backend systems, seamless API integrations, and scalable full-stack solutions using the MERN stack. Whether you’re starting from scratch or looking to enhance your existing system, we can help you bring your vision to life.</h5>

                            <h5>Let’s make it happen! Get in touch with us today to discuss how we can turn your ideas into reality.</h5>

                            <Link to="/contact-us" className="btn btn-black self-end text-center">Let’s Discuss</Link>
                        </div>


                        {/* Let's conclude */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="lets-conclude">Let's Conclude</h2>

                            <p>🎉 After setting up all the routes, controllers, and models, you’ve successfully completed the backend part of your project! Well done — you’ve laid the foundation for your application’s functionality and ensured that your data flows seamlessly through the server.</p>
                        </div>


                        <p className="font-bold">We will soon publish a new blog to guide you in bringing your project to life by building the frontend. Subscribe to stay updated!</p>


                        {/* Outro */}
                        <div className="space-y-6 bg-[--blue-primary] md:px-20 xl:py-12 p-5 sm:p-8 rounded-[--round] text-white">
                            <div className="flex items-center gap-x-2 lg:gap-x-4">
                                <img src={developerAnas} alt="Developer Anas" className="rounded-full size-[52px]" />
                                <div>
                                    <h5>Syed Anas Raza</h5>
                                    <div className="text-xs">Full-Stack Developer at Techieonix</div>
                                </div>
                            </div>



                            <div className="space-y-3">
                                <h4>Backend Basics for a MERN User Management System</h4>

                                <div className="flex gap-x-5 text-xs">
                                    <p>November 23, 2024</p>
                                    <div className="flex items-center gap-x-1 px-2">
                                        <ClockBlog fill="white" />
                                        <span>{readTime}</span>
                                    </div>
                                </div>

                                <span className="border-white chip">Development</span>
                            </div>


                            <div className="space-y-2">
                                <p className="font-semibold">Share Link</p>
                                <div className="flex max-lg:flex-wrap gap-2.5 *:border-white *:p-2 *:border">
                                    <ShareSocial white={true} setPopup={setPopup} title="Backend Basics for a MERN User Management System" />
                                </div>
                            </div>
                        </div>

                    </div>





                    {/* Share */}
                    <div className="top-0 sticky space-y-6 max-lg:hidden pt-28 w-20 xl:w-[107px] h-screen shrink-0">
                        <h5 className="text-end">Share</h5>
                        <div className="flex flex-col justify-end items-end gap-y-2.5 *:border-[--black-primary] *:p-2 *:border">
                            <ShareSocial setPopup={setPopup} title="Backend Basics for a MERN User Management System" />
                        </div>
                    </div>

                </div>
            </section>




            {/* All Blogs */}
            <LatestBlogs />

            {/* CTA */}
            <BlogCta />

            {/* Contact Us Form */}
            <ContactUsForm />

            {/* Footer */}
            <Footer />

            {/* Popup */}
            {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
        </>
    )
}