import React, { useEffect, useState } from 'react';

import { Popup, SocialIcons } from '../Components';
import { validateEmail } from '../utils/formvalidate';


export const BlogCta = () => {
    const [email, setEmail] = useState("");
    const [popup, setPopup] = useState({ show: false, state: null, message: "" });
    const [recaptchaToken, setRecaptchaToken] = useState("");


    useEffect(() => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute("6LdvvlUqAAAAANRjjb-ZtQWsAiuqzZF6VO_quDwZ", { action: "submit" }).then((token) => setRecaptchaToken(token));
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Email validation
        if (validateEmail(email)) {
            setPopup({
                show: true,
                state: "error",
                message: "Please provide a valid email"
            });
            return;
        }

        // Checking reCAPTCHA verification
        if (!recaptchaToken) {
            setPopup({
                show: true,
                state: "error",
                message: "Please complete the reCAPTCHA"
            });
        }

        try {
            const payload = {
                message: `For Newsletter Subscription: ${email}`,
                "g-recaptcha-response": recaptchaToken
            };
            console.log("Submitting form with payload:", payload);

            const response = await fetch("https://formspree.io/f/mnnaekol", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                setPopup({
                    show: true,
                    state: "success",
                    message: "Form submitted successfully!"
                });
                setEmail("");
            } else {
                throw new Error("Network response was not ok");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            setPopup({
                show: true,
                state: "error",
                message: "Something went wrong while submitting the form! Please try again or contact our support at contact@techieonix.com"
            });
        }
    };


    return (
        <>
            <section className="max-lg:px-5 py-10 md:p-10 lg:py-[--py-lg] space-y-6 text-center bg-[--black-primary] text-white theme-section">
                <div className='space-y-3 text-center'>
                    <h3>Looking for more digital insights?</h3>
                    <p className="opacity-80 md:w-[454px] mx-auto">Get our latest blog posts, research reports, and thought leadership right in your inbox each month.</p>
                </div>

                <form className="relative md:w-[406px] mx-auto" onSubmit={handleSubmit}>
                    <input type="text" placeholder="Business Email*" name="email" id="email"
                        className="rounded-full ring-1 ring-[#ffffff80] py-2 pl-4 pr-[132px] lg:pr-[140px] bg-transparent w-full focus-visible:outline-0"
                        value={email} onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="submit" className="btn btn-white w-[120px] h-full absolute right-0">Send</button>
                </form>

                <div className="max-lg:text-center space-y-6 mx-auto w-fit">
                    <h5>Follow Us here</h5>
                    <div className="flex gap-x-2.5 max-lg:justify-center *:p-2 *:ring-1 *:ring-white *:transition-colors">
                        <SocialIcons footer={true} />
                    </div>
                </div>
            </section>



            {/* Popup */}
            {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
        </>
    )
}