import React, { useState } from "react";
import { Link } from "react-router-dom";

import Marquee from "react-fast-marquee";

import { ClientTestimonials, OurClients, Navbar, Footer, ContactUsForm, CaseStudyComp, LatestBlogs } from "../Components";
import { arrowFull, arrow, key1, key2, key3, key4, key5, dash } from "../assets";
import services from "../Data/Services";


const keyPoints = [
  {
    name: "Experienced team",
    description: "Our team comprises seasoned professionals with diverse expertise in various IT domains, ensuring top- notch service delivery.",
    icon: key1,
  },
  {
    name: "Innovative solutions",
    description: "We leverage the latest technologies to develop solutions that are not only effective but also future - proof, keeping your business ahead of the curve.",
    icon: key2,
  },
  {
    name: "Customer centric approach",
    description: "We prioritize our clients' needs, working closely with them to understand their goals and deliver customized solutions that drive real results.",
    icon: key3,
  },
  {
    name: "Proven track record",
    description: "With numerous successful projects across different industries, our track record speaks for itself. We take pride in the lasting impact our solutions have on our clients' businesses.",
    icon: key4,
  },
  {
    name: "Global Reach",
    description: "Serving clients worldwide, we understand the nuances of different markets and deliver solutions that cater to a global audience.",
    icon: key5,
  },
];


export const Home = () => {
  const [showKeyPoints, setShowKeyPoints] = useState([false, false, false, false, false]);
  const [showServices, setShowServices] = useState([false, false, false, false, false, false, false, false]);

  const toggleMobileSections = (i, showSection, setShowSection) => {
    showSection[i] ? setShowSection(showSection.map(() => false)) : setShowSection(showSection.map((_, index) => index === i));
  };


  return (
    <>
      {/* Navbar */}
      <Navbar />




      {/* Hero */}
      <section className="home-hero-bg h-home-hero flex flex-col justify-between max-md:space-y-10 pt-[135px] md:pt-[155px] xl:pt-[178px] pb-10 theme-section">

        <div className="md:w-[679px] lg:w-[775px] xl:w-[833px] max-sm:px-5 max-md:px-10 max-lg:px-10 space-y-6 md:space-y-6 mx-auto text-center text-white">
          <div className="space-y-3">
            <h1 className="display">
              <span>Transforming Ideas into </span>
              <br className="max-lg:hidden" />
              <span>Innovative Solutions</span>
            </h1>

            <p>At Techieonix, we turn vision into reality with customized IT solutions. Whether launching a new product or enhancing your digital presence, our expert team delivers innovation and success. Partner with us to transform your ideas into results and stay ahead in a competitive market.</p>
          </div>

          <Link to="/contact-us" className="btn btn-white px-8 py-3 max-md:w-full">Start Your Journey with Us</Link>
        </div>



        <Marquee>
          <div className="p-1 md:py-3 md:px-[--py-lg] flex gap-x-[120px] bg-[--white-10] text-white">
            <h4>Empowering Businesses with Cutting-Edge IT Services and Products</h4>
            <img src={arrowFull} alt="arrow" />

            <h4>Tech that transforms, Innovation that inspires</h4>
            <img src={arrowFull} alt="arrow" />

            <h4>Empowering Innovation Through Support</h4>
            <img src={arrowFull} alt="arrow" />

            <h4>Future-Focused Solutions for Your Business</h4>
            <img src={arrowFull} alt="arrow" />
          </div>
        </Marquee>
      </section>




      {/* About */}
      <section className="xl:h-[386px] xl:content-center max-sm:px-5 max-md:px-[52px] max-xl:px-10 max-lg:py-10 max-xl:py-[--py-lg] theme-section">
        <div className="md:px-10 xl:px-0 xl:w-[--title-intro-width] space-y-6 mx-auto text-center text-[--black-primary]">
          <h1>About Techieonix</h1>
          <p>Welcome to Techieonix, your trusted digital partner. With over a decade of IT experience, we deliver tailored solutions to meet each client's unique needs. Our mission is to empower businesses through innovative technology, enhancing efficiency and driving growth. From web and mobile development to cloud solutions, AI integrations and IT consulting, we are committed to excellence and building long-term, trusted partnerships.</p>
        </div>
      </section>




      {/* Key points */}
      <section className="bg-[--white-primary] theme-section">
        <div className="px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 sm:flex gap-x-6 gap-y-11 flex-wrap justify-center max-sm:space-y-11 center">
          {keyPoints.map((point, i) => (
            <div key={i} className={`relative group p-5 md:pt-8 sm:pb-9 md:pb-[--px-xl] lg:hover:pb-5 bg-white space-y-5 rounded-[15px] lg:max-h-[212px] sm:w-[205px] md:w-[230px] shrink-0 lg:hover:max-h-[1000px] h-full shadow-card transition-[max-height] duration-300
              ${showKeyPoints[i] ? "max-h-[1000px] md:!pb-5" : "max-md:flex gap-x-5 items-center max-md:space-y-0"}`}
              onClick={() => toggleMobileSections(i, showKeyPoints, setShowKeyPoints)}
            >
              <div className={`max-sm:flex gap-x-5 items-center sm:space-y-5 lg:h-[--px-xl] lg:group-hover:h-auto
              ${showKeyPoints[i] ? "md:h-auto" : "md:h-[--px-xl]"}`}>
                <img src={point.icon} alt={point.name} />
                <h5>{point.name}</h5>
              </div>

              <p className={`lg:max-h-0 lg:group-hover:max-h-[1000px] lg:text-transparent lg:group-hover:text-[--black-primary] transition-colors duration-700 lg:overflow-y-hidden lg:group-hover:overflow-y-auto
              ${showKeyPoints[i] ? "max-h-[1000px] text-[--black-primary] overflow-y-auto" : "max-h-0 text-transparent overflow-y-hidden max-lg:hidden"}`}
              >
                {point.description}
              </p>

              <div className={`absolute right-5 bottom-0 bg-[--blue-primary] p-2 rounded-full transform translate-y-1/2 lg:group-hover:rotate-180
              ${showKeyPoints[i] && "max-lg:rotate-180"}`}
              >
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
          ))}
        </div>
      </section>




      {/* Services */}
      <section className="px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 space-y-10 xl:space-y-[54px] center theme-section">
        <div className="md:px-10 xl:p-0 xl:w-[--title-intro-width] space-y-6 text-center mx-auto">
          <h1>Our Services</h1>
          <p>Discover our range of specialized services designed to meet the diverse needs of your business. From development to consulting, our expertise ensures your project’s success from start to finish.</p>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          {services.map((service, i) => (
            <div key={i}
              className={`p-5 rounded-xl from-[#fff1dd1a] from-[12%] via-[#fb83021a] via-[49%] to-[#3e55cf24] to-[98%] shadow-card relative group overflow-hidden lg:h-[300px] lg:hover:h-auto md:bg-[#F1F4F9] lg:hover:bg-transparent lg:hover:bg-gradient-to-br max-md:flex justify-between gap-x-5
              ${showServices[i] ? "max-lg:bg-transparent max-lg:h-auto max-lg:bg-gradient-to-br items-start" : "bg-[--black-primary] items-center max-lg:h-[300px] max-md:h-auto"}`}
              onClick={() => toggleMobileSections(i, showServices, setShowServices)}
            >
              <img src={service.icon} alt={service.title}
                className={`relative left-0 lg:group-hover:left-full lg:group-hover:-translate-x-full duration-500 lg:duration-700 transition-[left] max-md:hidden
                  ${showServices[i] ? "max-lg:left-full max-lg:-translate-x-full" : "max-lg:left-0"}
                  `}
              />

              <div className={`lg:space-y-20 lg:group-hover:space-y-5 w-service-card lg:translate-y-32 xl:translate-y-[153px] lg:group-hover:translate-y-0 transition-transform duration-300 max-md:grow ${showServices[i] ? "md:space-y-5 md:translate-y-0" : "md:space-y-20 md:translate-y-32"}`}>
                <h4 className={`${!showServices[i] && "max-md:text-white"} md:!w-full`} style={{ width: "calc(100% - 52px)" }}>
                  {service.title}
                </h4>

                <div className={`space-y-5 ${showServices[i]
                  ? "max-md:max-h-full max-md:mt-5 max-md:pt-2.5 max-md:border-t border-[--black-primary-10]"
                  : "max-md:max-h-0 max-md:text-transparent"
                  }`}
                >
                  <p className="md:line-clamp-6 lg:line-clamp-none">{service.details}</p>
                  <Link to={service.link} className="link link-underline">View {service.title}</Link>
                </div>
              </div>

              <div className="size-8 md:size-9 lg:size-14 rounded-full bg-white absolute md:bottom-5 right-5">
                <img src={dash} alt="Toggle service" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                <img src={dash} alt="Toggle service"
                  className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-90 lg:group-hover:rotate-0
                    ${showServices[i] && "max-lg:rotate-0"} transition-transform duration-300`}
                />
              </div>
            </div>
          ))}
        </div>
      </section>




      {/* What Our Clients Say */}
      <section className="bg-[--black-primary] theme-section">
        <div className="px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 max-md:space-y-6 md:flex gap-x-6 justify-between center">
          <h1 className="max-md:text-center sm:px-8 md:px-0 text-white">What Our Clients Say</h1>

          <ClientTestimonials />
        </div>
      </section>




      {/* Our Case Studies */}
      <CaseStudyComp />

      {/* Our Clients */}
      <OurClients />

      {/* Our Latest Blog */}
      <LatestBlogs />

      {/* Contact us form */}
      <ContactUsForm />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Home;