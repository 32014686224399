export const validateEmail = (email) => {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return !emailRegex.test(email);
}


export const validateName = (key, value) => {
    if (value.trim() === "") return `Please provide your ${key}`;
    if (value.trim().length > 50) return `Your ${key} must not exceed 50 characters`;

    return false;
}


export const validatePhoneNumber = (phoneNumber) => {
    const regex = /^(?=.*\d)[0-9+-]*$/;
    const phone = phoneNumber.replace(/ /g, "");

    if (phone.trim() === "") return "Please provide your phone number";
    if (phone.trim().length < 7 || !regex.test(phone)) return "Please enter a valid phone number";
    if (phone.trim().length > 17) return "Please enter a valid phone number(less than 17 digits)";

    return false;
}


export const validateTextArea = (key, value, max) => {
    const regex = /^[a-zA-Z0-9 .,'"()!]*$/;

    if (value.trim() !== "") {
        if (value.trim().length >= max) return `Your ${key} must not exceed ${max} characters`;
        if (!regex.test(value)) return `You can only enter the following special characters: . , " ' ( ) !`;
    }

    return false;
}


export const validateResume = (resume) => {
    const maxSizeInBytes = 2 * 1024 * 1024;
    const pdf = "application/pdf";
    const docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    const doc = "application/msword";

    if (!resume) return "Please attach your resume / CV";
    if (resume.size >= maxSizeInBytes) return "The size of your must be under 2MB";
    if (!(resume.type === pdf || resume.type === docx || resume.type === doc)) return "Please provide your resume in PDF or Word format";

    return false;
}