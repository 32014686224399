import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { logo, logoWhite, navClose, navHamburger } from "../assets";
import { Arrow, NavEmail, Chevron } from "../Components";
import services from "../Data/Services";


export const Navbar = ({ white = false }) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showNavServices, setShowNavServices] = useState(false);
  const [showNavCompany, setShowNavCompany] = useState(false);
  const params = useParams();


  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolledUp(currentScroll > 0 ? currentScroll < lastScrollTop : false);
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => { document.body.style.overflow = showNavMenu ? "hidden" : "auto" }, [showNavMenu]);

  useEffect(() => closeNav(), [params]);

  const closeNav = () => {
    setShowNavMenu(false);
    setShowNavServices(false);
    setShowNavCompany(false);
  }

  const toggleSubmenus = (e, setSubmenu) => {
    e?.stopPropagation();
    setSubmenu(false);
  }


  return (
    <nav
      className={`${isScrolledUp ? "fixed bg-white text-[--black-primary] nav-slide-in shadow-nav"
        : "absolute bg-transparent text-white transition-colors duration-300"}
        ${showNavMenu && "!bg-[--black-primary]"} ${white ? "shadow-nav" : "group"}
        top-0 lg:hover:bg-white hover:text-[--black-primary] w-full z-20 border-b-2 border-[--white-10]`}
    >
      <div className={`px-5 md:px-[45px] xl:px-[--px-xl] h-[90px] flex justify-between items-center center max-lg:relative
        ${showNavMenu && "max-lg:bg-[--black-primary]"}`}>

        {/* Hamburger / Nav Toggler - Small Screens */}
        <img
          src={navHamburger}
          alt="Hamburger"
          title="Toggle Navbar"
          className={`${showNavMenu ? "hidden" : "lg:hidden"}`}
          onClick={() => setShowNavMenu(true)}
        />
        <img
          src={navClose}
          alt="Close"
          title="Toggle Navbar"
          className={`${showNavMenu ? "lg:hidden" : "hidden"} fade-in`}
          onClick={closeNav}
        />




        {/* Techienoix Logo (For default and hover states) */}
        {white ?
          // Logo for blog pages
          <Link to="/">
            <img src={logo} alt="Logo" title="Techieonix International Logo" className={`h-9 ${showNavMenu && "max-lg:hidden"}`} />
          </Link>
          :
          // Logo for other pages
          <Link to="/">
            <img src={logoWhite} alt="Logo" title="Techieonix International Logo"
              className={`h-9 lg:group-hover:hidden ${isScrolledUp ? "hidden" : "block"} ${showNavMenu && "!block"}`}
            />
            <img src={logo} alt="Logo" title="Techieonix International Logo"
              className={`h-9 lg:group-hover:block ${isScrolledUp ? "block" : "hidden"} ${showNavMenu && "!hidden"}`}
            />
          </Link>
        }




        {/* Links */}
        {showNavMenu &&
          <div className="fixed h-screen w-full bg-[#00000080] top-0 left-0 -z-10 max-md:hidden max-lg:block"
            onClick={() => setShowNavMenu(false)} />
        }
        <ul className={`max-lg:absolute top-[92px] max-lg:bg-[--black-primary] max-md:w-full max-lg:w-[384px] h-sm-nav max-lg:left-0 max-md:p-[25px] max-lg:px-[45px] max-lg:py-[25px] max-lg:space-y-1 max-lg:text-white slide-from-left lg:flex gap-x-2.5
        ${!showNavMenu && "hidden"} ${white && "text-[--black-primary]"}`}
        >
          <li>
            <Link to="/" className="nav-hover-underline block max-lg:border-b max-lg:border-[--white-10] pr-2 pt-1 pb-3 cursor-pointer lg:px-2 lg:py-1">Home</Link>
          </li>




          <li className="dropdown nav-hover-underline" onClick={() => setShowNavServices(true)}>
            {/* Large Screen service link on hover */}
            <Link to="/services" className="flex items-center gap-x-2 cursor-pointer lg:px-2 lg:py-1 max-lg:hidden">
              <span>Services</span>
              <div className="max-lg:-rotate-90">
                <Chevron className="fill-current" />
              </div>
            </Link>

            {/* Small Screen service link on click */}
            <div className="flex items-center justify-between gap-x-2 border-b border-[--white-10] pr-2 pt-1 pb-3 lg:hidden">
              <Link to="/services">Services</Link>
              <div className="max-lg:-rotate-90">
                <Chevron className="fill-current" />
              </div>
            </div>

            <div className="absolute top-8 left-0 w-full h-[31px] max-lg:hidden" />

            <ul
              className={`${!showNavServices && "hidden"
                } absolute top-0 lg:top-[63px] left-0 bg-[--black-primary] lg:bg-[--white-primary] w-full h-sm-nav lg:w-max p-6 space-y-1 slide-from-left dropdown-menu rounded-b-[10px] text-white lg:text-[--black-primary] shadow-nav z-10`}
            >
              <li className="lg:hidden flex justify-between items-center w-full max-lg:border-b max-lg:border-[--white-10] pr-2 pt-1 pb-3 lg:px-2 lg:py-1" onClick={(e) => toggleSubmenus(e, setShowNavServices)}>
                <div className="flex items-center gap-x-1">
                  <Arrow className="fill-white" />
                  <span>Back</span>
                </div>
                <span className="px-2 bg-[--blue-primary] rounded-full">
                  Services
                </span>
              </li>
              {services.map((service) => (
                <li key={service.title}>
                  <Link
                    to={service.link}
                    className="hover:text-[--blue-primary] max-lg:border-b max-lg:border-[--white-10] pr-2 pt-1 pb-3 lg:px-2 lg:py-1 block"
                  >
                    {service.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>




          <li>
            <Link to="/case-studies" className="nav-hover-underline block max-lg:border-b max-lg:border-[--white-10] pr-2 pt-1 pb-3 cursor-pointer lg:px-2 lg:py-1">Case Studies</Link>
          </li>




          <li>
            <Link to="/blogs" className="nav-hover-underline block max-lg:border-b max-lg:border-[--white-10] pr-2 pt-1 pb-3 cursor-pointer lg:px-2 lg:py-1">Blogs</Link>
          </li>




          <li className="nav-hover-underline flex items-center max-lg:justify-between gap-x-2 max-lg:border-b max-lg:border-[--white-10] pr-2 pt-1 pb-3 cursor-pointer lg:px-2 lg:py-1 dropdown" onClick={() => setShowNavCompany(true)}>
            <span>Company</span>
            <div className="max-lg:-rotate-90"><Chevron className="fill-current" /></div>
            <div className="absolute top-8 left-0 w-full h-[31px] max-lg:hidden" />
            <ul className={`${!showNavCompany && "hidden"} dropdown-menu p-6 space-y-1 absolute top-0 lg:top-[63px] left-0 bg-[--black-primary] lg:bg-[--white-primary] rounded-b-[10px] text-white lg:text-[--black-primary] h-sm-nav w-full lg:w-[200px] slide-from-left shadow-nav z-10`}>
              <li
                className="pb-3 pt-1 pr-2 lg:px-2 lg:py-1 cursor-pointer max-lg:border-b border-[--white-10] lg:hidden flex justify-between items-center w-full"
                onClick={(e) => toggleSubmenus(e, setShowNavCompany)}
              >
                <div className="flex items-center gap-x-1">
                  <Arrow className="fill-white" />
                  <span>Back</span>
                </div>
                <span className="px-2 bg-[--blue-primary] rounded-full">Company</span>
              </li>
              <li>
                <Link to="/about-us" className="block pb-3 pt-1 pr-2 lg:px-2 lg:py-1 cursor-pointer max-lg:border-b border-[--white-10] hover:text-[--blue-primary]">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us" className="block pb-3 pt-1 pr-2 lg:px-2 lg:py-1 cursor-pointer max-lg:border-b border-[--white-10] hover:text-[--blue-primary]">Contact Us</Link>
              </li>
              <li>
                <Link to="/careers" className="block pb-3 pt-1 pr-2 lg:px-2 lg:py-1 cursor-pointer max-lg:border-b border-[--white-10] hover:text-[--blue-primary]">Careers</Link>
              </li>
            </ul>
          </li>
        </ul>




        {/* CTA button - (768 - Large) */}
        <Link to="/contact-us"
          className={`btn max-md:hidden group-hover:bg-[--blue-primary] group-hover:text-white ${isScrolledUp ? "btn-blue" : "btn-white"} ${white && "btn-blue"}`}
        >Free Consultation</Link>

        {/* CTA Icon - (0 - 767) */}
        <Link to="/contact-us"
          className={`size-10 rounded-full grid place-items-center md:hidden
            ${(isScrolledUp || white) && "text-white !bg-[--blue-primary]"}
            ${showNavMenu ? "text-white bg-[--blue-primary] fade-in" : "text-[#1C1B1F] bg-white"}`}
        >
          <NavEmail className="fill-current" />
        </Link>
      </div>
    </nav>
  );
};
