import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { readingTime } from 'reading-time-estimator';

import { BlogBreadCrumb, BlogCta, ClockBlog, CodeBlock, ContactUsForm, Footer, LatestBlogs, MetaDecorator, Navbar, Popup, ShareSocial } from '../../Components';
import { blog11, blog11AddUser, blog11DeleteUser, blog11FrontendPackage, blog11Login, blog11Ui, blog11UpdateUser, blog11Users, developerAnas } from '../../assets';
import { handleScrollForActiveSection } from "../../utils/blogActiveSection";


export const UserManagementSystemFrontend = () => {
    const [activeSection, setActiveSection] = useState("");
    const [readTime, setReadTime] = useState(null);

    // Popup
    const [popup, setPopup] = useState({ show: false, state: null, message: "" });


    useEffect(() => {
        // Calculating reading time
        const blogContent = document.getElementById("blogContent").innerText;
        setReadTime(`${readingTime(blogContent).minutes} min read`);

        // Scrolling for table of contents' active section
        const cleanup = handleScrollForActiveSection(setActiveSection);
        return cleanup;
    }, []);


    return (
        <>
            {/* Meta Data */}
            <MetaDecorator
                title="Crafting the Frontend for a MERN-Based User Management System | Techieonix International"
                imageUrl="https://stotechieonixassets.file.core.windows.net/techieonix-assets/blog-8.jpg?sv=2022-11-02&ss=f&srt=o&sp=r&se=2025-10-28T03:57:49Z&st=2024-10-27T19:57:49Z&spr=https,http&sig=FJsQ8eGyXYsp16U4Cj7gjWiBzABFNSeDpVlwJGtVsTk%3D"
                type="article"
            />

            {/* Navbar */}
            <Navbar white={true} />

            {/* Breadcrumb */}
            <BlogBreadCrumb category="Development" title="Crafting the Frontend for a MERN-Based User Management System" />




            {/* Blog */}
            <section className="space-y-10 px-5 md:px-10 xl:px-[--px-xl] lg:pt-10 xl:pb-10 center theme-section">

                {/* Header Image */}
                <div className="space-y-2 blog-head-position">
                    <img src={blog11} alt="Frontend for MERN based User Management System built by Techieonix" title="Frontend for MERN based User Management System built by Techieonix" className="rounded-[--round]" />
                    <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                        Frontend for MERN based User Management System built by Techieonix | Credit: Freepik
                    </p>
                </div>


                {/* Header / Intro */}
                <div className="space-y-4 lg:space-y-6 border-[--black-primary-10] md:pt-5 pb-5 border-b blog-head-position">
                    <div className="space-y-3">
                        <h1>Crafting the Frontend for a MERN-Based User Management System</h1>
                        <div className="flex gap-x-5 text-xs">
                            <div>November 28, 2024</div>
                            <div className="flex items-center gap-x-1 px-2">
                                <ClockBlog fill="#232326" />
                                <span>{readTime}</span>
                            </div>
                        </div>
                    </div>
                    <span className="chip">Development</span>
                    <div className="flex items-center gap-x-2 lg:gap-x-4">
                        <img src={developerAnas} alt="Developer Anas" className="rounded-full size-[52px]" />
                        <div>
                            <h5>Syed Anas Raza</h5>
                            <p className="text-xs">Full-Stack Developer at Techieonix</p>
                        </div>
                    </div>
                </div>




                <div className="lg:flex gap-x-6 max-lg:space-y-10">

                    {/* Table of Contents */}
                    <div className="space-y-3 sm:space-y-6 lg:shrink-0 top-0 lg:sticky lg:pt-28 lg:w-[240px] xl:w-[298px] lg:h-screen">
                        <h5>Table of Contents</h5>

                        <div id="tableOfContents" className="flex flex-col gap-y-2 opacity-50 mt-[21px] *:w-fit body-sm">
                            <a href="#user-management-system-frontend" className={`${activeSection === "user-management-system-frontend" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                User Management System: Frontend
                            </a>

                            <a href="#building-the-frontend-with-react" className={`${activeSection === "building-the-frontend-with-react" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Building the Frontend with React
                            </a>

                            <a href="#step-1-set-up-the-project" className={`${activeSection === "step-1-set-up-the-project" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 1: Set Up the Project
                            </a>

                            <a href="#step-2-install-necessary-libraries" className={`${activeSection === "step-2-install-necessary-libraries" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 2: Install Necessary Libraries
                            </a>

                            <a href="#step-3-set-up-tailwind-css" className={`${activeSection === "step-3-set-up-tailwind-css" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 3: Set up Tailwind CSS
                            </a>

                            <a href="#step-4-build-ui-and-integrate-backend-apis" className={`${activeSection === "step-4-build-ui-and-integrate-backend-apis" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 4: Build UI & Integrate Backend APIs
                            </a>

                            <a href="#login-page" className={`${activeSection === "login-page" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                Login Page
                            </a>

                            <a href="#home-page" className={`${activeSection === "home-page" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                Home Page
                            </a>

                            <a href="#user-page" className={`${activeSection === "user-page" && "text-[--blue-primary] font-semibold"} hover:underline ml-8`}>
                                User Page
                            </a>

                            <a href="#step-5-set-up-routes" className={`${activeSection === "step-5-set-up-routes" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Step 5: Set up Routes
                            </a>

                            <a href="#run-and-test-the-application" className={`${activeSection === "run-and-test-the-application" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Run and Test the Application
                            </a>

                            <a href="#start-the-servers" className={`${activeSection === "start-the-servers" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Start the Servers
                            </a>

                            <a href="#log-in-to-the-application" className={`${activeSection === "log-in-to-the-application" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Log in to the Application
                            </a>

                            <a href="#add-user" className={`${activeSection === "add-user" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Add User
                            </a>

                            <a href="#delete-user" className={`${activeSection === "delete-user" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Delete User
                            </a>

                            <a href="#edit-user" className={`${activeSection === "edit-user" && "text-[--blue-primary] font-semibold"} hover:underline ml-4`}>
                                Edit User
                            </a>

                            <a href="#troubleshooting" className={`${activeSection === "troubleshooting" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Troubleshooting
                            </a>

                            <a href="#faq" className={`${activeSection === "faq" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                FAQ
                            </a>

                            <a href="#mission-accomplished" className={`${activeSection === "mission-accomplished" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Mission Accomplished ✅
                            </a>
                        </div>
                    </div>





                    {/* Content */}
                    <div id="blogContent" className="space-y-6 sm:space-y-10 body-lg">

                        <h6 className="blog-card-highlight">After setting up the backend in our <Link to="/blogs/discovering-the-mern-stack-a-starters-route-to-full-stack-javaScript" target="_blank" className="blog-link">last MERN blog</Link>, it’s time to bring the application to life by creating the frontend. This blog will guide you through setting up React, designing the user interface, and connecting it with the backend to complete your MERN stack application.</h6>


                        {/* User Management System: Frontend */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="user-management-system-frontend">User Management System: Frontend</h2>

                            <p>For building a modern frontend, requires the right tools and a clear process. In this guide, we’ll explore how to create a sleek and interactive user interface using React for component-based development and Tailwind CSS for streamlined styling.</p>

                            <p>By the end, you’ll have a clear understanding of how to combine these powerful tools to create a frontend that is both functional and visually appealing. Let’s dive into the step-by-step process and bring your project to life.</p>
                        </div>


                        {/* Building the Frontend with React */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="building-the-frontend-with-react">Building the Frontend with React</h2>

                            <p>To develop frontend, we’d need the same tools and technologies as used in the Backend blog as prerequisites which are:</p>

                            <ul className="list-inside list-disc">
                                <li>Nodejs</li>
                                <li>NPM (Node Package Manager)</li>
                                <li>VS Code</li>
                            </ul>

                            <p>Since we’ve already setup these in our last backend blog, we can move on to create the react app. If you haven’t installed the above-mentioned requirements, follow the <Link to="/blogs/backend-basics-for-a-mern-user-management-system#prerequisites-for-starting-a-mern-project" target="_blank" className="blog-link">procedure</Link> to move further.</p>

                            <ul className="list-inside list-decimal">
                                <li>Set Up the Project</li>
                                <li>Install necessary libraries</li>
                                <li>Set up Tailwind CSS</li>
                                <li>Build UI & Integrate Backend APIs</li>
                            </ul>
                        </div>


                        {/* Step 1: Set Up the Project */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-1-set-up-the-project">Step 1: Set Up the Project</h3>

                            <p>First things first, we need to create the base for our React app. Open the <span className="inline-code">my-mern-app</span> directory in VS Code, launch a new terminal, and run the following commands to set up the project:</p>

                            <CodeBlock language={"powershell"}
                                code={`npx create-react-app frontend   # Creates react application
cd frontend
npm i
npm start`}
                            />

                            <p>This will create a fresh React application and launch the development server, allowing you to see your changes in real-time.</p>

                            <p>Once the app is created and the server is running, the folder structure will look like this:</p>

                            <CodeBlock language={"plaintext"}
                                code={`└── ParentDirectory/
    ├── frontend/
    │    ├── public
    │        ├── favicon.ico
    │        ├── index.html
    │        ├── logo192.png
    │        ├── logo512.png
    │        ├── manifest.json
    │        └── robots.txt
    │    ├── src
    │        ├── userController.js
    │        ├── App.css
    │        ├── App.js
    │        ├── App.test.js
    │        ├── index.css
    │        ├── index.js
    │        ├── logo.svg
    │        ├── reportWebVitals.js
    │        └── setupTests.js
    │   ├── .gitignore
    │   ├── package-lock.json
    │   ├── package.json
    │   └── README.md
    └── backend`}
                            />
                        </div>


                        {/* Step 2: Install necessary libraries */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-2-install-necessary-libraries">Step 2: Install Necessary Libraries</h3>

                            <p>After building up the react frontend, our application needs a few libraries to make it efficient and dynamic. In this step, we’ll install the following dependencies:</p>

                            <CodeBlock language={"powershell"} code={`npm i axios react-router-dom`} />

                            <ul className="list-inside list-disc">
                                <li><b>axios</b>: A promise-based HTTP client for making requests to APIs.</li>
                                <li><b>react-router-dom</b>: For handling routing and navigation in React applications.</li>
                            </ul>

                            <p>Your <span className="inline-code">package.json</span> file will look something like this after the installation:</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog11FrontendPackage} alt="package.json file within frontend folder" title="package.json file within frontend folder" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                                    <span className="inline-code">package.json</span> file within frontend folder - Techieonix
                                </p>
                            </div>
                        </div>


                        {/* Step 3: Set up Tailwind CSS */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-3-set-up-tailwind-css">Step 3: Set up Tailwind CSS</h3>

                            <p>To make our application look modern and responsive, we’ll use <b>Tailwind CSS</b>, a utility-first CSS framework. Tailwind helps us rapidly create custom designs without writing custom CSS from scratch.</p>

                            <ul className="list-inside list-disc">
                                <li>Visit <a href="https://tailwindcss.com/docs/installation/play-cdn" target="_blank" rel="noreferrer" className="blog-link">tailwindcss.com</a> & copy the CDN mentioned in step 1.</li>
                                <li>Paste the CDN link into the <span className="inline-code">{`<head>`}</span> section of <span className="inline-code">public/index.html</span>.</li>
                            </ul>

                            <p>With Tailwind now integrated, we can start styling our pages with ease.</p>
                        </div>


                        {/* Step 4: Build UI & Integrate Backend APIs */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-4-build-ui-and-integrate-backend-apis">Step 4: Build UI & Integrate Backend APIs</h3>

                            <p>In our User Management System, we will need three core pages: <b>Login</b>, <b>Home</b>, and <b>User</b> Page. These pages will work together to allow users to login, view a list of users, and perform <b>CRUD</b> (Create, Read, Update, Delete) operations on user data.</p>
                        </div>


                        {/* Login Page */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="login-page">Login Page</h4>

                            <p>In login page, users will enter their credentials to authenticate themselves. To create this page:</p>

                            <ul className="list-inside list-disc">
                                <li>Create a <span className="inline-code">pages</span> folder in the <span className="inline-code">src</span> directory.</li>
                                <li>Inside the <span className="inline-code">pages</span> folder, create a file named <span className="inline-code">Login.jsx</span>.</li>
                            </ul>

                            <CodeBlock language={"javascript"}
                                code={`/* /src/pages/Login.jsx */
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.post("http://localhost:5000/api/login", { email, password }).then(res => {
            console.log('User added successfully:', res);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            navigate("/")
        }).catch(err => {
            console.error(err);
            setError(err?.response?.data);
        })
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold text-center">Login</h2>

                {error && <p className="text-red-500 text-center">{error}</p>}

                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="border border-gray-300 rounded px-4 py-2"
                        required
                    />
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            className="border border-gray-300 rounded px-4 py-2 w-full"
                            required
                        />
                        <span
                            onClick={() => setShowPassword(!showPassword)}
                            className={\`absolute right-2 top-2 text-blue-500 font-bold cursor-pointer \${showPassword && "bg-blue-100"} p-1 text-sm select-none\`}
                        >
                            Show
                        </span>
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white rounded py-2 mt-4 hover:bg-blue-600"
                    >
                        Log In
                    </button>
                </form>

            </div>
        </div>
    )
}
export default Login;`}
                            />

                            <p>The <span className="inline-code">Login</span> component will:</p>

                            <ul className="list-inside list-disc">
                                <li><b>Manage State</b>: Using React’s <span className="inline-code">useState</span>, we’ll store the email, password, error messages, and visibility toggle for the password field.</li>
                                <li><b>Handle Navigation</b>: Using <span className="inline-code">useNavigate</span>, we’ll redirect the user to the homepage after a successful login.</li>
                                <li><b>Submit Function</b>: The form data will be sent to the backend using Axios. On success, we’ll store the JWT token and user details in local storage and navigate to the homepage. If there’s an error, we will display an appropriate error message.</li>
                            </ul>

                            <p>With this flow in place, the login page will be fully functional, enabling users to log in securely and navigate to the app.</p>
                        </div>


                        {/* Home Page */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="home-page">Home Page</h4>

                            <p>After login the user is navigated to the home page, which is the heart of user management, where admins can efficiently manage the users of the system.</p>

                            <ul className="list-inside list-disc">
                                <li>Create a file named <span className="inline-code">Home.jsx</span> in <span className="inline-code">pages</span> folder.</li>
                            </ul>

                            <CodeBlock language={"javascript"}
                                code={`/* /src/pages/Home.jsx */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

const Home = () => {
    const [users, setUsers] = useState(null);
    const [error, setError] = useState("");

    useEffect(() => getUsers(), []);

    const getUsers = () => {
        axios.get("http://localhost:5000/api/users", {
            headers: { "Authorization": \`Bearer ${localStorage.getItem("token")}\` }
        }).then(res => {
            console.log(res.data);
            setUsers(res.data.users);
        }).catch(err => {
            console.error(err);
            setUsers([]);
            setError(err?.response?.data);
        })
    }

    const deleteUser = (id) => {
        axios.delete(\`http://localhost:5000/api/users/\${id}\`, {
            headers: { "Authorization": \`Bearer \${localStorage.getItem("token")}\` }
        }).then(res => {
            console.log(res.data);
            getUsers();
        }).catch(err => {
            console.error(err);
            setUsers([]);
            setError(err?.response?.data);
        })
    }

    return (
        <main className="min-h-screen bg-gray-100 p-10">
            <h1 className="text-3xl font-bold text-center mb-6">User Management</h1>
            <div className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-6">
                <div className="flex justify-end gap-x-5">
                    <Link to="/login" className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded">Login</Link>
                    <Link to="/user" className="bg-green-500 hover:bg-green-600 text-white px-3 py-2 rounded">Add user</Link>
                </div>

                {error && <p className="text-red-500 text-center">{error}</p>}

                <table className="min-w-full bg-white mt-6">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Name</th>
                            <th className="py-2 px-4 border-b">Email</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map(user => (
                            <tr key={user._id} className="hover:bg-gray-100 cursor-pointer">
                                <td className="py-2 px-4 border-b">{user.name}</td>
                                <td className="py-2 px-4 border-b">{user.email}</td>
                                <td className="py-2 px-4 border-b text-center">
                                    <Link to={\`/user?updateUser=\${user._id}\`}
                                        className="bg-yellow-500 hover:bg-yellow-600 text-white px-3 py-1 rounded mr-2"
                                    >
                                        Edit
                                    </Link>
                                    <button
                                        onClick={() => deleteUser(user._id)}
                                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </main>
    )
}
export default Home;`}
                            />

                            <p>The home page displays a list of all users in a dynamic table.</p>

                            <ul className="list-inside list-disc">
                                <li><b>State Management</b>: <span className="inline-code">users</span>'s state stores users' data, and <span className="inline-code">error</span>'s state handles errors using <span className="inline-code">useState</span>.</li>
                                <li><b>Data Fetching</b>: On load, <span className="inline-code">useEffect</span> calls <span className="inline-code">getUsers</span>, which fetches users from an API with authorization that we set in the backend.</li>
                                <li><b>UI</b>: Renders a table of users with actions:
                                    <ul className="list-inside list-[circle] ml-8">
                                        <li><b>Edit User</b>: Links to the <span className="inline-code">user</span> page with the user ID as a query parameter for further editing via a PUT request.</li>
                                        <li><b>Delete User</b>: Calls <span className="inline-code">deleteUser</span> function which sends a delete request for a user by ID, refreshing the user list afterward.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>


                        {/* User Page */}
                        <div className="space-y-3 sm:space-y-6">
                            <h4 id="user-page">User Page</h4>

                            <p>The <span className="inline-code">User</span> page is used for both adding and editing user information. Whether we’re creating a new user or modifying an existing one, the process will be handled seamlessly by this page.</p>

                            <ul className="list-inside list-disc">
                                <li>Create a file named <span className="inline-code">User.jsx</span> in <span className="inline-code">pages</span> folder for both adding and updating user information.</li>
                            </ul>

                            <CodeBlock language={"javascript"}
                                code={`/* /src/pages/User.jsx */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const User = () => {
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        role: "",
        dob: "",
        phone: "",
        address: ""
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { search } = useLocation();

    useEffect(() => {
        const userId = new URLSearchParams(search).get("updateUser");
        axios.get(\`http://localhost:5000/api/users/\${userId}\`, {
            headers: { Authorization: \`Bearer \${localStorage.getItem("token")}\` }
        }).then(res => {
            console.log(res.data);
            setUser(res.data);
        }).catch(err => console.error(err));
    }, [search]);

    const handleSubmit = e => {
        e.preventDefault();
        axios.post("http://localhost:5000/api/users", user, {
            headers: { Authorization: \`Bearer \${localStorage.getItem("token")}\` }
        }).then(res => {
            console.log(res.data);
            navigate("/");
        }).catch(err => {
            console.error(err);
            setError(err?.response?.data);
        })
    }
    const handleUpdate = e => {
        e.preventDefault();
        const userId = new URLSearchParams(search).get("updateUser");
        axios.put(\`http://localhost:5000/api/users/\${userId}\`, user, {
            headers: { Authorization: \`Bearer \${localStorage.getItem("token")}\` }
        }).then(res => {
            console.log(res.data);
            navigate("/");
        }).catch(err => {
            console.error(err);
            setError(err?.response?.data);
        })
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="w-full max-w-lg p-8 space-y-6 bg-white shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold text-center">Add New User</h2>

                {error && <p className="text-red-500 text-center">{error}</p>}

                <form onSubmit={user ? handleUpdate : handleSubmit} className="flex flex-col gap-4">
                    <input
                        type="text"
                        name="name"
                        value={user?.name}
                        onChange={e => setUser({ ...user, name: e.target.value })}
                        placeholder="John Doe*"
                        className="border border-gray-300 rounded px-4 py-2"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        value={user?.email}
                        onChange={e => setUser({ ...user, email: e.target.value })}
                        placeholder="john@example.com*"
                        className="border border-gray-300 rounded px-4 py-2"
                        required
                    />
                    {!user && <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={user?.password}
                            onChange={e => setUser({ ...user, password: e.target.value })}
                            placeholder="helloWorld*"
                            className="border border-gray-300 rounded px-4 py-2 w-full"
                            required
                        />
                        <span
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-2 top-2 text-blue-500 font-bold cursor-pointer hover:bg-blue-100 p-1 text-sm select-none"
                        >
                            {showPassword ? "Hide" : "Show"}
                        </span>
                    </div>}
                    <select
                        name="role"
                        value={user?.role}
                        onChange={e => setUser({ ...user, role: e.target.value })}
                        className="border border-gray-300 rounded px-4 py-2"
                        required
                    >
                        <option value="">Select Role</option>
                        <option value="Admin">Admin</option>
                        <option value="User">User</option>
                        <option value="Manager">Manager</option>
                    </select>
                    <input
                        type="date"
                        name="dob"
                        value={user?.dob}
                        onChange={e => setUser({ ...user, dob: e.target.value })}
                        placeholder="Date of Birth"
                        className="border border-gray-300 rounded px-4 py-2"
                    />
                    <input
                        type="text"
                        name="phone"
                        value={user?.phone}
                        onChange={e => setUser({ ...user, phone: e.target.value })}
                        placeholder="+923111234567"
                        className="border border-gray-300 rounded px-4 py-2"
                    />
                    <textarea
                        name="address"
                        value={user?.address}
                        onChange={e => setUser({ ...user, address: e.target.value })}
                        placeholder="Flat # 1, abc apartment, xyz street, Pakistan"
                        className="border border-gray-300 rounded px-4 py-2"
                    />
                    <button
                        type="submit"
                        className="bg-blue-500 text-white rounded py-2 mt-4 hover:bg-blue-600"
                    >
                        {user ? "Update" : "Add User"}
                    </button>
                </form>
            </div>
        </div>
    )
}
export default User;`}
                            />

                            <p>Here’s a breakdown of the User page</p>

                            <ul className="list-inside list-disc">
                                <li><b>State Management</b>: Uses <span className="inline-code">useState</span> to handle user data, password visibility (<span className="inline-code">showPassword</span>), error messages (<span className="inline-code">error</span>), and update mode (<span className="inline-code">isUpdating</span>).</li>
                                <li><b>Routing</b>: Retrieves query parameters with <span className="inline-code">useLocation</span>, and uses <span className="inline-code">useNavigate</span> to redirect after form submission.</li>
                                <li><b>Data Fetching</b>: On load, <span className="inline-code">useEffect</span> checks for a <span className="inline-code">userId</span> in the URL to decide if it should fetch and load user data for editing. Requests include an authorization token from <span className="inline-code">localStorage</span>.</li>
                                <li><b>Form Submission</b>: <span className="inline-code">handleSubmit</span> adds a new user via <span className="inline-code">POST</span>, and <span className="inline-code">handleUpdate</span> modifies an existing user with PUT, both redirecting on success.</li>
                                <li><b>Button Text</b>: Toggles button text between "Add User" and "Update" based on <span className="inline-code">isUpdating</span>.</li>
                                <li><b>Error Handling</b>: Displays API errors in <span className="inline-code">error</span>.</li>
                            </ul>
                        </div>


                        {/* Step 5: Set up Routes */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="step-5-set-up-routes">Step 5: Set up Routes</h3>

                            <p>With all our pages set up, it’s time to wire them together. Open the <span className="inline-code">src/App.js</span> file and configure the routes using <span className="inline-code">react-router-dom</span>:</p>

                            <CodeBlock language={"javascript"}
                                code={`/* /src/App.js */
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import User from './pages/User';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/user" element={<User />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App`}
                            />
                        </div>

                        <p>With the core pages and components in frontend are all set up, you’re ready to move forward to finishing touches and testing your application.</p>


                        {/* Run and Test the Application */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="run-and-test-the-application">Run and Test the Application</h2>

                            <p>After building the MERN stack application, it’s time to see everything in action! In this section, we’ll go through the steps to run both the frontend and backend servers, test the functionalities, and manage users via the admin panel. So, let’s get started.</p>
                        </div>


                        {/* Start the Servers */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="start-the-servers">Start the Servers</h3>

                            <p>The first step in testing our application is to start both the frontend and backend servers. Here’s how to do it:</p>

                            <ul className="space-y-3 list-inside list-disc">
                                <li className="space-y-1">
                                    <span><b>Start the Frontend Server</b>: In a terminal window, navigate to the frontend directory and run the following command to launch the React development server:</span>
                                    <CodeBlock language={"powershell"} code={`npm start`} />
                                </li>

                                <li className="space-y-1">
                                    <span><b>Start the Backend Server</b>: Open a new terminal window, navigate to the backend directory, and start the backend server by running:</span>
                                    <CodeBlock language={"powershell"} code={`node app.js`} />
                                </li>
                            </ul>

                            <p>Once the frontend is started, it'll generate a URL (like <span className="inline-code">http://localhost:3000</span>), browse the URL in the browser. The interface will look like as shown in screenshot 1:</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog11Ui} alt="Home page" title="Home page" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                                    Screenshot 1 - Home page - Techieonix
                                </p>
                            </div>
                        </div>

                        <h6 className="blog-card-highlight">We created an admin user in our previous article. Visit <Link to="/blogs/backend-basics-for-a-mern-user-management-system#putting-it-all-together" target="_blank" className="blog-link">this link</Link> to learn how we set up a default admin user.</h6>


                        {/* Log in to the Application */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="log-in-to-the-application">Log in to the Application</h3>

                            <p>Log in with the admin credentials as shown in screenshot 2. You should now have access to the full range of features, including user management, as an admin.</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog11Login} alt="Login screen" title="Login screen" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                                    Screenshot 2 - Login screen - Techieonix
                                </p>
                            </div>
                        </div>


                        {/* Add User */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="add-user">Add User</h3>

                            <p>Now that you’re logged in, you can begin adding users to the system. Simply click the "Add User" button on the homepage to open the user creation form. Fill in the necessary user details (e.g., name, email, password) and click "Add User" button to create the new user. Refer to screenshot 3,</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog11AddUser} alt="Add new user page" title="Add new user page" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                                    Screenshot 3 - Add new user page - Techieonix
                                </p>
                            </div>

                            <p>Once the new user is added, you will be redirected back to the homepage, where the user will appear in the list.</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog11Users} alt="Users list" title="Users list" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                                    Screenshot 4 - Users list - Techieonix
                                </p>
                            </div>
                        </div>


                        {/* Delete User */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="delete-user">Delete User</h3>

                            <p>Creating a new user will redirect you back to the home page. As an admin, you can delete any of the existing users by clicking the <b>Delete</b> button next to their names, as shown in screenshot 5.</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog11DeleteUser} alt="Delete user" title="Delete user" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                                    Screenshot 5 - Delete user - Techieonix
                                </p>
                            </div>
                        </div>


                        {/* Edit User */}
                        <div className="space-y-3 sm:space-y-6">
                            <h3 id="edit-user">Edit User</h3>

                            <p>To edit a user’s details, click the <b>Edit</b> button next to the user you wish to modify. This will take you to the user form, pre-filled with the current information. Make the necessary changes and click the <b>Update</b> button to save the modifications, as shown in screenshot 6.</p>

                            <div className="space-y-2 max-sm:py-3">
                                <img src={blog11UpdateUser} alt="Edit User" title="Edit User" className="mx-auto rounded-[--round]" />
                                <p className="pt-2.5 border-t text-[#23232666] text-center text-xs">
                                    Screenshot 6 - Edit User - Techieonix
                                </p>
                            </div>

                            <p>Once updated, the user’s details will be reflected immediately on the homepage, and they will be stored in the database.</p>
                        </div>


                        {/* CTA */}
                        <div className="p-5 sm:p-8 md:p-10 flex flex-col gap-y-6 bg-[#EAEBF3] rounded-[--round]">
                            <h3>Ready to build powerful and scalable web applications?</h3>

                            <h5>At Techieonix, we specialize in creating robust backend systems and crafting seamless frontend experiences using the MERN stack. Whether you need a complete solution or just a helping hand with a specific part of your project, we’re here to make it happen.</h5>

                            <h5>Let us help you bring your vision to life! Contact us today to discuss how we can turn your ideas into reality.</h5>

                            <Link to="/contact-us" className="btn btn-black self-end text-center">Let’s Discuss</Link>
                        </div>


                        {/* Troubleshooting */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="troubleshooting">Troubleshooting</h2>

                            <ul className="space-y-3 sm:space-y-6 list-inside list-decimal">
                                <li><b>Common Database Issues</b>
                                    <ul className="ml-8 list-inside list-[circle] space-y-2 mt-2">
                                        <li>
                                            <b>Problem</b>: MongoDB connection errors<br />
                                            <b className="ml-[26px]">Solution</b>: Check the connection URI, MongoDB server status, and IP whitelisting for MongoDB Atlas.
                                        </li>

                                        <li>
                                            <b>Problem</b>: Data not saving correctly<br />
                                            <b className="ml-[26px]">Solution</b>: Ensure Mongoose schemas match the data structure, and check for validation errors.
                                        </li>
                                    </ul>
                                </li>


                                <li><b>API and Server Issues</b>
                                    <ul className="ml-8 list-inside list-[circle] space-y-2 mt-2">
                                        <li>
                                            <b>Problem</b>: Backend not responding<br />
                                            <b className="ml-[26px]">Solution</b>: Verify the server is running on the correct port, and check API endpoints for typos or route mismatches.
                                        </li>
                                    </ul>
                                </li>


                                <li><b>Frontend Issues</b>
                                    <ul className="ml-8 list-inside list-[circle] space-y-2 mt-2">
                                        <li>
                                            <b>Problem</b>: Unable to fetch data from API<br />
                                            <b className="ml-[26px]">Solution</b>: Check if the API endpoint is correct and if the backend server is running. Confirm that HTTP methods match (e.g., GET, POST).
                                        </li>

                                        <li>
                                            <b>Problem</b>: State not updating correctly; for e.g, the user is not being removed from the screen after deletion until the page is reloaded.<br />
                                            <b className="ml-[26px]">Solution</b>: Verify if you’re using state management hooks or libraries correctly and check for unnecessary re-renders.
                                        </li>
                                    </ul>
                                </li>


                                <li><b>Authentication Problems</b>
                                    <ul className="ml-8 list-inside list-[circle] space-y-2 mt-2">
                                        <li>
                                            <b>Problem</b>: JWT token not stored correctly<br />
                                            <b className="ml-[26px]">Solution</b>: Ensure the token is saved in localStorage or cookies after login and sent in headers properly for protected routes.
                                        </li>

                                        <li>
                                            <b>Problem</b>: Unauthorized access errors on protected routes<br />
                                            <b className="ml-[26px]">Solution</b>: Double-check middleware and verify the user’s role or token validity.
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </div>


                        {/* FAQ */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="faq">FAQ</h2>
                            <ul className="space-y-3 sm:space-y-6 list-inside list-decimal">
                                <li>
                                    <b>How do I set up the environment variables?</b><br />
                                    <span className="ml-[26px]">Create a <span className="inline-code">.env</span> file at the root of backend folder, add variables (like <span className="inline-code">DB_URI</span> for the database connection), and access them in the code using <span className="inline-code">process.env</span>.</span>
                                </li>
                                <li>
                                    <b>Why do I need both a backend and a frontend folder?</b><br />
                                    <span className="ml-[26px]">This separation keeps concerns isolated: the backend handles data processing and API logic, while the frontend focuses on user interaction and display.</span>
                                </li>
                                <li>
                                    <b>How do I debug errors in my API calls?</b><br />
                                    <span className="ml-[26px]">Use <span className="inline-code">console.log</span> statements, browser developer tools, or Postman to check API request and response payloads. Make sure endpoints and request methods match.</span>
                                </li>
                                <li>
                                    <b>Can I deploy this app as-is to production?</b><br />
                                    <span className="ml-[26px]">For production, optimize by handling environment variables securely, applying rate limiting, securing sensitive data, ensuring HTTPS in your deployment setup, and replacing your local URLs <span className="inline-code">http://localhost:PORT</span> with your production domain.</span>
                                </li>
                            </ul>

                        </div>


                        {/* Mission Accomplished */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="mission-accomplished">Mission Accomplished ✅</h2>
                            <p>We have successfully completed both the backend and frontend of our User Management System, built using the MERN stack. With these skills, you're ready to create professional-grade and full-stack applications. 🚀</p>
                        </div>


                        <p className="font-bold">🌟 Subscribe to our newsletter to stay updated on the latest information in technology, AI, and more!</p>


                        {/* Outro */}
                        <div className="space-y-6 bg-[--blue-primary] md:px-20 xl:py-12 p-5 sm:p-8 rounded-[--round] text-white">
                            <div className="flex items-center gap-x-2 lg:gap-x-4">
                                <img src={developerAnas} alt="Developer Anas" className="rounded-full size-[52px]" />
                                <div>
                                    <h5>Syed Anas Raza</h5>
                                    <div className="text-xs">Full-Stack Developer at Techieonix</div>
                                </div>
                            </div>



                            <div className="space-y-3">
                                <h4>Crafting the Frontend for a MERN-Based User Management System</h4>

                                <div className="flex gap-x-5 text-xs">
                                    <p>November 28, 2024</p>
                                    <div className="flex items-center gap-x-1 px-2">
                                        <ClockBlog fill="white" />
                                        <span>{readTime}</span>
                                    </div>
                                </div>

                                <span className="border-white chip">Development</span>
                            </div>


                            <div className="space-y-2">
                                <p className="font-semibold">Share Link</p>
                                <div className="flex max-lg:flex-wrap gap-2.5 *:border-white *:p-2 *:border">
                                    <ShareSocial white={true} setPopup={setPopup} title="Crafting the Frontend for a MERN-Based User Management System" />
                                </div>
                            </div>
                        </div>

                    </div>





                    {/* Share */}
                    <div className="top-0 sticky space-y-6 max-lg:hidden pt-28 w-20 xl:w-[107px] h-screen shrink-0">
                        <h5 className="text-end">Share</h5>
                        <div className="flex flex-col justify-end items-end gap-y-2.5 *:border-[--black-primary] *:p-2 *:border">
                            <ShareSocial setPopup={setPopup} title="Crafting the Frontend for a MERN-Based User Management System" />
                        </div>
                    </div>

                </div>
            </section>




            {/* All Blogs */}
            <LatestBlogs />

            {/* CTA */}
            <BlogCta />

            {/* Contact Us Form */}
            <ContactUsForm />

            {/* Footer */}
            <Footer />

            {/* Popup */}
            {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
        </>
    )
}