import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { CaseStudyComp, ContactUsForm, Footer, Navbar, OurClients, TechStack } from "../Components";

import { serviceCardBg1, serviceCardBg2, serviceCardBg3, serviceCardBg4, serviceCardBg5, serviceCardBg6, serviceCardBg7, serviceCardBg8, servicesWhyUsBg1, servicesWhyUsBg2, servicesWhyUsBg3, servicesWhyUsBg4, servicesWhyUsBg5 } from "../assets";


const services = [
  {
    service: "Web Development",
    description: "Our custom web development services ensure your website is not only visually appealing but also highly functional and user - friendly.We create responsive, scalable, and secure websites tailored to your business needs.",
    image: serviceCardBg1,
    link: "/services/web-development"
  },
  {
    service: "Mobile App Development",
    description: "We develop innovative mobile applications that provide seamless user experiences, helping you engage your audience on the go.Our apps are designed to be intuitive, robust, and compatible across various devices.",
    image: serviceCardBg2,
    link: "/services/mobile-app-development"
  },
  {
    service: "IT Consulting",
    description: "Our IT consulting services offer strategic guidance and expert advice to optimize your IT infrastructure.We help you navigate the complexities of technology, ensuring your IT strategy aligns with your business objectives.",
    image: serviceCardBg3,
    link: "/services/it-consulting"
  },
  {
    service: "UI / UX Design",
    description: "Our UI / UX design services focus on creating engaging and intuitive user experiences.We ensure that your digital products are not only functional but also visually captivating, enhancing user satisfaction and loyalty.",
    image: serviceCardBg4,
    link: "/services/ui-ux-design"
  },
  {
    service: "QA & Testing",
    description: "Our comprehensive QA and testing services guarantee the reliability and performance of your software.We conduct thorough testing to identify and resolve issues, ensuring a flawless user experience.",
    image: serviceCardBg5,
    link: "/services/qa-and-testing"
  },
  {
    service: "Software Architecture Solutions",
    description: "We provide software architecture solutions that ensure your applications are scalable, maintainable, and aligned with your business goals.Our architects design robust frameworks that support long - term growth.",
    image: serviceCardBg6,
    link: "/services/software-architecture-solutions"
  },
  {
    service: "Cloud Solutions",
    description: "Our cloud solutions enhance your business agility and scalability.We offer secure and efficient cloud services, from migration to management, helping you leverage the full potential of cloud technology.",
    image: serviceCardBg7,
    link: "/services/cloud-solutions"
  },
  {
    service: "DevOps Solutions",
    description: "Our DevOps solutions streamline your development and operations processes, improving efficiency and accelerating time to market.We integrate best practices to ensure seamless collaboration and continuous delivery.",
    image: serviceCardBg8,
    link: "/services/devops-solutions"
  }
];

const whyUsContent = [
  {
    name: "Custom Development",
    description: "Development that reflects the distinct needs of your business.",
    bgImage: servicesWhyUsBg1
  },
  {
    name: "Scalable Solutions",
    description: "Flexible and scalable, built to support your growth journey.",
    bgImage: servicesWhyUsBg2
  },
  {
    name: "Security First",
    description: "Security is our top priority, protecting your data and applications.",
    bgImage: servicesWhyUsBg3
  },
  {
    name: "Innovative Designs",
    description: "Creative, user-centric designs that captivate and engage.",
    bgImage: servicesWhyUsBg4
  },
  {
    name: "Efficient Processes",
    description: "Streamlined processes that accelerate delivery and maximize efficiency.",
    bgImage: servicesWhyUsBg5
  }
];


export const Services = () => {
  const [activeServiceOnMobile, setActiveServiceOnMobile] = useState(null);
  const whyUsRef = useRef(null);

  const applyBackground = (i, image) => {
    if (whyUsRef.current && window.outerWidth > 1023) {
      whyUsRef.current.style.backgroundImage = `url("${image}")`;

      Array.from(whyUsRef.current.children).forEach((_, index) => {
        const hoveredCard = whyUsRef.current.children[index];
        const cardText = hoveredCard.children[2];
        const cardNo = hoveredCard.children[0];

        if (index === i) {
          cardNo.classList.remove("lg:opacity-50");
          cardText.classList.remove("hidden");
          hoveredCard.style.backgroundColor = "#00000080";
        } else {
          cardNo.classList.add("lg:opacity-50");
          cardText.classList.add("hidden");
          hoveredCard.style.backgroundColor = "transparent";
        }
      });
    } else {
      whyUsRef.current.style.backgroundImage = "none";
    }
  };

  useEffect(() => applyBackground(0, servicesWhyUsBg1), []);

  const toggleServicesOnMobile = (i) => activeServiceOnMobile === i ? setActiveServiceOnMobile(null) : setActiveServiceOnMobile(i);


  return (
    <>
      {/* Navbar */}
      <Navbar />




      {/* Hero */}
      <section className="service-hero-bg theme-section md:content-end md:h-[340px]">
        <div className="max-sm:pt-[110px] max-md:pt-[130px] max-md:p-5 sm:pb-10 md:px-10 xl:px-[--px-xl] text-white center">
          <h1>Services</h1>
        </div>
      </section>




      {/* Introduction */}
      <section className="max-sm:px-5 max-md:px-[52px] max-xl:px-20 max-lg:py-10 max-xl:py-[--py-lg] space-y-6 sm:space-y-10 xl:w-[--title-intro-width] xl:h-[551px] xl:content-center mx-auto text-center">
        <h1>Innovative Solutions for Modern Businesses</h1>
        <p>
          At Techieonix, we take pride in offering a wide range of IT services that are meticulously crafted to address the diverse and ever-evolving demands of modern businesses. In an increasingly digital world, staying ahead of the curve requires a partner who understands the intricacies of technology and its impact on your business operations. Our team of seasoned professionals is dedicated to delivering innovative solutions that not only fulfill your immediate needs but also provide a solid foundation for long-term success.
          <br />
          <br />
          We approach every project with a commitment to excellence, ensuring that our solutions are robust, scalable, and aligned with your business objectives. From the initial consultation to the final delivery, we work closely with you to understand your unique challenges and goals, allowing us to tailor our services to your specific requirements. Our expertise spans across various domains, enabling us to offer a holistic suite of services that drive growth, enhance efficiency, and empower your business to thrive in a competitive landscape.
        </p>
      </section>




      {/* Services */}
      <section className="bg-[--black-primary] theme-section">
        <div className="center">
          <div className="sticky top-0 h-dvh flex items-center justify-center max-sm:px-5 max-lg:px-10">
            <h1 className="heading-xl text-white text-center lg:w-[935px] xl:w-[1130px]">
              Tailored Facilities We Provide To Meet Your Needs
            </h1>
          </div>

          <div className="pb-[100svh] sm:w-[430px] md:w-[679px] lg:w-[935px] xl:w-[1052px] mx-auto space-y-72 z-10 relative max-sm:flex max-sm:flex-col max-sm:items-center max-sm:justify-center">
            {services.map((item, i) => (
              <div key={i}
                className={`group px-5 pt-5 sm:py-10 md:pt-20 space-y-5 lg:hover:pb-10 sm:w-[360px] md:w-[562px] md:h-[346px] lg:w-[632px] w-[280px] h-[400px] rounded-[--round] text-white content-end bg-[#14141499] lg:hover:bg-blend-multiply transition-all duration-300 bg-cover max-sm:bg-center
                  ${i === 5 && "max-sm:h-[450px]"} ${i % 2 !== 0 && "sm:ml-auto"}
                  ${activeServiceOnMobile === i && "max-lg:pb-5 max-lg:bg-blend-multiply"}`}
                style={{ backgroundImage: `url("${item.image}")` }}
                onClick={() => toggleServicesOnMobile(i)}
              >
                <h2>{item.service}</h2>
                <div className={`max-h-0 overflow-y-hidden lg:group-hover:max-h-96 transition-all duration-300 space-y-5
                ${activeServiceOnMobile === i && "max-lg:max-h-96"}`}
                >
                  <p>{item.description}</p>
                  <Link to={item.link} className="link link-underline text-white after:bg-white lg:hover:after:bg-white">
                    View {item.service}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>




      {/* Tech Stack */}
      <TechStack />

      {/* Case Studies */}
      <CaseStudyComp isBlack={true} />




      {/* Why Choose Us */}
      <section className="theme-section">
        <h1 className="p-5 sm:p-10 xl:px-[--px-xl] center">Why Choose Us</h1>

        <div ref={whyUsRef}
          className="flex max-lg:gap-x-[5px] max-lg:overflow-x-auto lg:grid grid-cols-5 lg:bg-center lg:bg-cover lg:rounded-[--round] max-lg:!bg-none lg:divide-x divide-[#ffffff80] text-white"
        >
          {whyUsContent.map((item, i) => (
            <div key={i} className={`px-5 py-10 max-sm:w-4/5 max-md:w-3/4 max-lg:w-2/5 h-[400px] lg:!bg-none max-lg:shrink-0 max-lg:rounded-[10px]
              ${i === 0 && "lg:rounded-l-[--round]"}
              ${i === whyUsContent.length - 1 && "lg:rounded-r-[--round]"}`}
              style={{ backgroundImage: `url("${item.bgImage}")` }}
              onMouseEnter={() => applyBackground(i, item.bgImage)}
            >
              <h1 className="heading-xl lg:opacity-50">0{i + 1}</h1>
              <h3 className="mt-10 text-wrap">{item.name}</h3>
              <p className="mt-4 text-fadein max-lg:!block">{item.description}</p>
            </div>
          ))}
        </div>
      </section>




      {/* Our Clients */}
      <OurClients />

      {/* Contact us form */}
      <ContactUsForm />

      {/* Footer */}
      <Footer />
    </>
  );
};
