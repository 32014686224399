import { techBackendExpress, techBackendNodejs, techDbMongodb, techFrontendHtml, techFrontendJavascript, techFrontendReact, techFrontendTailwind, techProjectConfluence, techPlatformGithub, techPlatformHeroku, techPlatformVercel, techProjectJira, techUiuxFigma, caseImsPic1, caseImsPic2, caseSkResult1, caseSkResult2, caseSkResult3, caseSkResult4, caseSkPic1, caseSkPic4, caseSkPic2, caseSkPic3, caseMbaKey1, caseMbaKey2, caseMbaKey3, caseMbaPic1, caseMbaPic2, caseMbaPic3, caseEntraSignPic, caseEntraSignTechAutomation, caseEntraSignTechLogicApps, caseEntraSignTechMsEntraId, caseEntraSignTechPowershell, caseSkLogo, caseMbaLogo, caseImsPic3, techUiuxCanva, caseBeTravelPic1, caseBeTravelPic2, caseBeTravelPic3, caseBeTravelPic4 } from "../assets";


const caseStudies = [
    // IMS
    {
        title: "End-to-End Development of a Comprehensive Sales and Inventory Management Platform",
        client: "SK Printing Solution",
        industry: "Wholesale and Distribution",
        coreTech: "MERN, Heroku, Github, JIRA/Confluence",
        content: [
            {
                title: "Client Overview",
                text: "Our client, a growing printing solutions company, faced significant challenges in managing their sales, inventory, and financial records. Their existing processes were largely manual, leading to inefficiencies, data inaccuracies, and difficulties in generating meaningful reports."
            },
            {
                title: "Project Scope",
                text: "The client approached us with a request to build a comprehensive digital platform that would streamline their sales and inventory management processes. The project aimed to enhance data accuracy, provide real-time analytics, and support their expansion into online sales."
            },
            {
                title: "Key Challenges",
                cards: [
                    {
                        heading: "Data Collection",
                        text: "The initial phase involved gathering detailed requirements from the client, understanding their workflow, and translating these into a digital solution."
                    },
                    {
                        heading: "Design and UX",
                        text: "Creating an intuitive user interface that would cater to both tech-savvy users and those less familiar with digital tools."
                    },
                    {
                        heading: "Technology Stack",
                        text: "Selecting and implementing the right technology stack to ensure the platform's scalability, security, and ease of use."
                    }
                ]
            },
            {
                title: "Solution",
                text: "We developed a robust Sales and Inventory Management Platform using the MERN stack (MongoDB, Express.js, React.js, Node.js) deployed on Heroku, ensuring a scalable, efficient, and user-friendly solution.",
                sections: [
                    {
                        title: "Features Implemented",
                        cards: [
                            {
                                heading: "Dashboard",
                                text: "A comprehensive dashboard providing insights into total stock, items sold, and total sales with interactive charts for data visualization."
                            },
                            {
                                heading: "Sales Management",
                                text: "A detailed sales management module where users can add new sales, update balances, and conduct balance inquiries."
                            },
                            {
                                heading: "Inventory Management",
                                text: "A dedicated section to manage inventory, including tracking stock levels, categorizing products, and updating stock records."
                            },
                            {
                                heading: "Sales Analysis",
                                text: "An advanced sales analysis feature offering KPIs and trends over time, helping the client make data-driven decisions."
                            },
                            {
                                heading: "Search Functionality",
                                text: "Implemented search features across various modules to enhance data retrieval efficiency."
                            }
                        ]
                    },
                    {
                        title: "Development Process",
                        cards: [
                            {
                                heading: "Requirement Gathering",
                                text: "Collaborated closely with the client to gather requirements, focusing on their business needs and pain points."
                            },
                            {
                                heading: "Design & Prototyping",
                                text: "Designed the user interface using Figma, ensuring a clean and modern look aligned with the client’s branding."
                            },
                            {
                                heading: "Backend Development",
                                text: "Built a secure and scalable backend using Node.js and Express.js, with MongoDB serving as the NoSQL database."
                            },
                            {
                                heading: "Frontend Development",
                                text: "Developed the frontend with React.js, focusing on responsiveness and ease of navigation."
                            },
                            {
                                heading: "Version Control",
                                text: "Utilized GitHub for code versioning, enabling seamless collaboration and continuous integration."
                            },
                            {
                                heading: "Deployment",
                                text: "Deployed the platform on Heroku, leveraging its capabilities as a PaaS to manage scaling and maintenance efficiently."
                            }
                        ]
                    }
                ]
            }
        ],
        technoloies: [
            {
                logo: techFrontendReact,
                name: "ReactJS"
            },
            {
                logo: techFrontendHtml,
                name: "Html5"
            },
            {
                logo: techFrontendTailwind,
                name: "Tailwind CSS"
            },
            {
                logo: techFrontendJavascript,
                name: "JavaScript"
            },
            {
                logo: techBackendNodejs,
                name: "NodeJS"
            },
            {
                logo: techBackendExpress,
                name: "ExpressJS"
            },
            {
                logo: techDbMongodb,
                name: "MongoDB"
            },
            {
                logo: techPlatformHeroku,
                name: "Heroku"
            },
            {
                logo: techPlatformGithub,
                name: "GitHub"
            }
        ],
        outcome: "The new platform drastically improved the client’s operational efficiency. It automated many of their previously manual processes, provided real-time analytics, and allowed for better inventory control. The platform also supported the client's transition to online sales, contributing to an increase in overall sales volume.",
        screenshots: [caseImsPic1, caseImsPic2, caseImsPic3],
        testimonial: {
            review: "The client was highly satisfied with the platform, noting significant improvements in data accuracy, operational efficiency, and user satisfaction. The system’s scalability has also positioned them well for future growth.",
            clientLogo: caseSkLogo,
            clientName: "Muhammad Shehryar",
            client: "SK Printing Solution"
        },
        link: "ims-sk-printing-solution"
    },

    // SK
    {
        title: "Building a Modern and Responsive Website for SK Printing Solution",
        client: "SK Printing Solution",
        industry: "Manufacturing and Wholesale",
        coreTech: "ReactJS, Vercel, Github, JIRA/Confluence",
        content: [
            {
                title: "Client Overview",
                text: "SkPrintingSolution is a small but rapidly growing business that specializes in a wide range of printing solutions, including standees, Panaflex inks, kiosk tables, roll-up stands, and eyelets. With a solid local reputation, the business aimed to expand its reach by establishing a strong online presence through a new website, SK Printing Solution."
            },
            {
                title: "Project Objectives",
                card: "The primary goal was to develop a modern, visually appealing, and responsive website that effectively showcased SkPrintingSolution's products and services. The website needed to be easy to navigate, optimized for various devices, and built using a scalable tech stack. Additionally, the business required a cohesive branding strategy to maintain consistency across digital and physical platforms."
            },
            {
                title: "Challenges",
                cards: [
                    {
                        heading: "Design and User Experience",
                        text: "The website had to be visually attractive while ensuring a seamless user experience. The design needed to reflect the professionalism of the business and appeal to potential customers."
                    },
                    {
                        heading: "Responsiveness",
                        text: "The website needed to be fully responsive, ensuring optimal performance across all devices, including desktops, tablets, and smartphones."
                    },
                    {
                        heading: "Technology Stack",
                        text: "A robust and scalable technology stack was required to support the website's performance and future growth."
                    },
                    {
                        heading: "Branding Consistency",
                        text: "Ensuring consistent branding across the website, social media platforms, and physical marketing materials was crucial for creating a strong brand identity."
                    },
                    {
                        heading: "Project Management",
                        text: "Effective coordination across a distributed team was necessary to deliver the project on time and within scope."
                    }
                ]
            },
            {
                title: "Our Approach",
                text: "As a team at Techieonix, we approached the project through a structured and collaborative process:",
                cardsFull: [
                    {
                        heading: "Planning and Requirements Gathering",
                        text: "We began by conducting thorough discussions with the client to understand their goals, target audience, and specific needs. This allowed us to create a detailed project plan with clear milestones and deliverables.\n<b>Tools used: JIRA</b> for project management, <b>Confluence</b> for documentation."
                    },
                    {
                        heading: "Design and Development",
                        text: "Design: We focused on creating a clean, modern, and aesthetically pleasing design that would resonate with the target audience. The website's design was aligned with SkPrintingSolution's brand identity, using colors and elements that reflected the business's professionalism.\n<b>Responsiveness:</b> Utilizing <b>ReactJS, HTML, CSS,</b> and <b>JavaScript</b>, we developed a fully responsive website. This ensured that the website provided an optimal user experience on all devices, from large desktop screens to small mobile phones.\n<b>Frontend Development:</b> The frontend was carefully crafted to be intuitive and easy to navigate, enhancing user engagement and ensuring that visitors could easily find the information they needed.\n<b>Backend Development:</b> We built a robust backend that supported the website's functionalities and ensured scalability for future growth.\n<b>Deployment:</b> The website was deployed using <b>Vercel</b> for the frontend and <b>Heroku</b> for the backend. This setup provided reliable and fast performance, along with easy scalability.\n<b>Version Control:</b> We used <b>GitHub</b> for version control, enabling seamless collaboration among the team and ensuring that all changes were tracked and managed efficiently."
                    },
                    {
                        heading: "Branding and Marketing:",
                        text: "We created a comprehensive branding strategy that included the design of social media announcements, physical standees, and other promotional materials. This ensured that SkPrintingSolution's brand was consistently represented across all platforms, both online and offline."
                    },
                    {
                        heading: "Testing and Launch",
                        text: "We conducted extensive testing to ensure that the website was free of bugs and functioned flawlessly across different devices and browsers. After final approval from the client, the website was successfully launched, marking a significant milestone for SkPrintingSolution."
                    }
                ]
            },
            {
                title: "Results",
                cardsGrid: [
                    {
                        icon: caseSkResult1,
                        heading: "Enhanced Online Presence",
                        text: "The launch of SK Printing Solution  significantly boosted the business's online presence, making it easier for customers to discover and engage with their services."
                    },
                    {
                        icon: caseSkResult2,
                        heading: "Responsive Design",
                        text: "The fully responsive design ensured that the website delivered an optimal experience across all devices, leading to increased user satisfaction and engagement."
                    },
                    {
                        icon: caseSkResult3,
                        heading: "Consistent Branding",
                        text: "The cohesive branding strategy across digital platforms and physical marketing materials established a strong and consistent brand identity for SkPrintingSolution."
                    },
                    {
                        icon: caseSkResult4,
                        heading: "Effective Project Management",
                        text: "The use of JIRA and Confluence enabled smooth project management, ensuring timely delivery and high-quality results."
                    }
                ]
            }
        ],
        technoloies: [
            {
                logo: techFrontendReact,
                name: "ReactJS"
            },
            {
                logo: techFrontendHtml,
                name: "Html5"
            },
            {
                logo: techFrontendTailwind,
                name: "Tailwind CSS"
            },
            {
                logo: techFrontendJavascript,
                name: "JavaScript"
            },
            {
                logo: techPlatformHeroku,
                name: "Heroku"
            },
            {
                logo: techProjectJira,
                name: "JIRA"
            },
            {
                logo: techPlatformVercel,
                name: "Vercel"
            },
            {
                logo: techPlatformGithub,
                name: "GitHub"
            },
            {
                logo: techProjectConfluence,
                name: "Confluence"
            }
        ],
        conclusion: "This project demonstrates Techieonix's expertise in delivering comprehensive digital solutions that go beyond simply building a website. By focusing on design, responsiveness, and branding, we were able to help SkPrintingSolution establish a powerful and professional online presence. The case study reflects our commitment to quality, attention to detail, and the ability to deliver results that drive business success.\nThis successful collaboration has positioned SkPrintingSolution to continue growing its business and reaching a wider audience through its new online platform.",
        screenshots: [caseSkPic1, caseSkPic2, caseSkPic3, caseSkPic4],
        testimonial: {
            review: "The team at Techieonix is incredibly responsive and collaborative. They kept us informed at every stage of the project and welcomed our feedback. The final product was exactly what we envisioned, and we're thrilled with the results.",
            clientLogo: caseSkLogo,
            clientName: "Muhammad Shehryar",
            client: "SK Printing Solution"
        },
        link: "sk-printing-solution"
    },

    // MBA
    {
        title: "Transforming Online Presence: A Custom Website for M.B.A Consultants",
        client: "M.B.A Consultants",
        industry: "Financial and Management Consulting",
        coreTech: "HTML/TailwindCSS, Vercel, SEO Optimization, Github",
        content: [
            {
                title: "Project Overview",
                text: "M.B.A Consultants required a professional, responsive website to showcase their services in tax, corporate governance, accounting, and management consulting. The goal was to build a website from scratch that reflected their expertise and was accessible on all major platforms."
            },
            {
                title: "Challenges",
                cards: [
                    {
                        heading: "From Scratch Development",
                        text: "Building a custom solution that meets all client requirements without relying on pre-existing templates."
                    },
                    {
                        heading: "Responsiveness",
                        text: "Ensuring the site was fully responsive across desktop, tablet, and mobile devices."
                    },
                    {
                        heading: "Content Structuring",
                        text: "Organizing detailed service offerings for easy navigation."
                    }
                ]
            },
            {
                title: "Solution",
                card: "We developed the M.B.A Consultants website using the MERN stack (MongoDB, Express.js, React, Node.js), with HTML, CSS, and JavaScript for front-end development. The site was made fully responsive and optimized for performance on all major platforms, ensuring a seamless user experience. Vercel was chosen for hosting due to its scalability, and GitHub was used for version control."

            },
            {
                title: "Key Features",
                cardsGrid: [
                    {
                        icon: caseMbaKey1,
                        heading: "Custom Design",
                        text: "The website was built from scratch, ensuring a unique and tailored experience."
                    },
                    {
                        icon: caseMbaKey2,
                        heading: "Fully Responsive",
                        text: "Optimized for all major devices, including desktops, tablets, and smartphones."
                    },
                    {
                        icon: caseMbaKey3,
                        heading: "SEO Optimization",
                        text: "Built with SEO best practices to enhance search engine visibility."
                    }
                ]
            }
        ],
        technoloies: [
            {
                logo: techFrontendHtml,
                name: "Html5"
            },
            {
                logo: techFrontendTailwind,
                name: "Tailwind CSS"
            },
            {
                logo: techFrontendJavascript,
                name: "JavaScript"
            },
            {
                logo: techPlatformVercel,
                name: "Vercel"
            },
            {
                logo: techPlatformGithub,
                name: "GitHub"
            },
            {
                logo: techUiuxFigma,
                name: "Figma"
            }
        ],
        outcome: "The new website has significantly boosted M.B.A Consultants’ online presence, leading to increased client inquiries. The custom design and responsive layout have been particularly well-received, showcasing the firm’s expertise effectively.",
        screenshots: [caseMbaPic1, caseMbaPic2, caseMbaPic3],
        testimonial: {
            review: "We are thrilled with the new website. It truly reflects our brand and has made it easier for clients to engage with our services. The team’s professionalism and technical expertise were evident throughout the project.",
            clientLogo: caseMbaLogo,
            clientName: "Majid Bashir",
            client: "CEO at MBA Consultants"
        },
        link: "mba-consultants"
    },

    // Entra Sign
    {
        title: "Automated User Sign-In Tracking and Compliance Monitoring System for Microsoft Entra ID",
        industry: "Information Technology (IT) Services",
        coreTech: "Microsoft Entra ID, Azure Automation, Identity and Access Management (IAM), Node.js",
        content: [
            {
                title: "Client Overview",
                text: "Our client, an organization utilizing Microsoft Entra ID for identity management, needed a solution to track user sign-ins and maintain compliance with internal policies. Additionally, they sought to proactively monitor their ecosystem to ensure that all users were actively engaging with their systems."
            },
            {
                title: "Challenge",
                card: "Capturing the last sign-in details of users within Microsoft Entra ID was not straightforward. The task required a deep understanding of Microsoft Entra ID and proficiency in various development and automation tools. The challenge was to create a solution that not only tracked user activity but also ensured compliance and provided proactive monitoring capabilities.",
                black: true
            },
            {
                title: "Solution",
                text: "Our team developed a comprehensive system designed to automate the process of fetching user sign-in data from Microsoft Entra ID. By leveraging Azure’s powerful tools, we created a solution that:",
                bullets: [
                    "Extracts user sign-in data from Entra ID.",
                    "Identifies users who had not logged in for the past 30 days.",
                    "Automatically sends an email to the inactive user, with the Entra ID admin copied, ensuring both compliance and proactive monitoring.",
                    "Provides detailed reporting to help administrators maintain oversight of user activity and system engagement."
                ]

            }
        ],
        technoloies: [
            {
                logo: caseEntraSignTechMsEntraId,
                name: "Microsoft Entra ID"
            },
            {
                logo: caseEntraSignTechAutomation,
                name: "Azure Automation Account"
            },
            {
                logo: techBackendNodejs,
                name: "Node.Js"
            },
            {
                logo: techFrontendJavascript,
                name: "JavaScript"
            },
            {
                logo: caseEntraSignTechLogicApps,
                name: "Azure Logic Apps"
            },
            {
                logo: caseEntraSignTechPowershell,
                name: "PowerShell"
            }
        ],
        outcome: "The implementation of this automated system ensured that our client could maintain compliance with internal policies and monitor their ecosystem proactively. The solution not only streamlined operations but also provided critical insights into user activity, helping the client manage their resources more effectively.",
        conclusion: "This project showcased our ability to deliver customized IT solutions that address complex challenges. By integrating multiple Azure services and custom development, we provided a solution that met the client's needs for compliance, monitoring, and operational efficiency.",
        screenshots: [caseEntraSignPic],
        link: "entrasign-compliance-tracker"
    },

    // BeTravel
    {
        title: "Building a User-Centric and Simplified Travel Website",
        client: "BeTravel",
        industry: "Travel",
        coreTech: "Canva, Figma, Vercel, Github, JIRA/Confluence",
        content: [
            {
                title: "Project Overview",
                text: "We developed a user-friendly platform from scratch that showcases everything BeTravel offers to its travelers. The cohesive and visually appealing design aligns with travelers' interests and reflects BeTravel’s mission and values toward its users. Through effective project management practices, we ensured the timely delivery of the platform while maintaining high-quality standards."
            },
            {
                title: "Our Approach",
                text: "As a team at Techieonix, we approached the project through a structured and collaborative process:",
                cardsFull: [
                    {
                        heading: "Design Tools",
                        text: "We employed Canva for designing visual elements, ensuring a consistent and engaging user interface."
                    },
                    {
                        heading: "Project Management",
                        text: "We leveraged JIRA for task tracking, progress monitoring, and team collaboration."
                    },
                    {
                        heading: "Deployment Strategy",
                        text: "We utilized the Github and Vercel for deployment."
                    },
                ]
            },
            {
                title: "Implementation",
                text: "To make the website user-centric, the implementation took the following steps.",
                cardsFull: [
                    {
                        heading: "Frontend Development",
                        text: "We developed interactive and responsive user interfaces using React.js, enhancing user engagement."
                    },
                    {
                        heading: "Design Integration",
                        text: "We integrated Canva-designed visuals into the platform, maintaining design consistency and brand identity."
                    },
                    {
                        heading: "Project Coordination",
                        text: "We utilized JIRA to assign tasks, monitor progress, and ensure alignment with project timelines and objectives."
                    },
                ]
            },
        ],
        technoloies: [
            {
                logo: techUiuxCanva,
                name: "Canva"
            },
            {
                logo: techUiuxFigma,
                name: "Figma"
            },
            {
                logo: techPlatformVercel,
                name: "Vercel"
            },
            {
                logo: techPlatformGithub,
                name: "GitHub"
            },
        ],
        outcome: "We delivered a fully functional platform that met all project objectives and client expectations, marking a successful launch. The platform received positive feedback from the BeTravel community, particularly for its usability and design. Additionally, it led to increased participation in open-source projects hosted on the platform, highlighting its effectiveness in enhancing user engagement.",
        screenshots: [caseBeTravelPic1, caseBeTravelPic2, caseBeTravelPic3, caseBeTravelPic4],
        link: "be-travel"
    },
];

export default caseStudies;