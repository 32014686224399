import React from "react";

export const Arrow = ({ className }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className={className} xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_545_3527" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" />
            </mask>
            <g mask="url(#mask0_545_3527)">
                <path d="M9 19L2 12L9 5L10.4 6.4L5.825 11H22V13H5.825L10.425 17.6L9 19Z" />
            </g>
        </svg>
    )
}

export const Chevron = ({ className }) => {
    return (
        <svg className={className} width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_629_2658" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                <rect x="0.667969" width="24" height="24" />
            </mask>
            <g mask="url(#mask0_629_2658)">
                <path d="M12.668 15.4L6.66797 9.4L8.06797 8L12.668 12.6L17.268 8L18.668 9.4L12.668 15.4Z" />
            </g>
        </svg>
    )
}

export const NavEmail = ({ className, size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" className={className} xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_714_4188" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" />
            </mask>
            <g mask="url(#mask0_714_4188)">
                <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z" />
            </g>
        </svg>
    )
}

export const TechieonixLogoBlog = ({ black }) => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill={black ? "white" : "#273581"} xmlns="http://www.w3.org/2000/svg">
            <rect width="52.0001" height="52" rx="26" fill={black ? "#232326" : "white"} />
            <path d="M17.4576 17.457H12V23.1519H17.4576V17.457Z" />
            <path d="M21.2539 12V17.4031H34.6177V34.5969H27.1148V40H39.9997V12H21.2539Z" />
            <path d="M32.4068 23.3906H26.9492V28.611H32.4068V23.3906Z" />
            <path d="M24.1011 23.3906H17.457V28.611H24.1011V23.3906Z" />
            <path d="M24.1017 34.5524V39.9992H12V28.6094H17.4052V34.5524H24.1017Z" />
        </svg>
    )
}

export const ClockBlog = ({ fill }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 12 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1509_26788" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
                <rect width="12" height="12" />
            </mask>
            <g mask="url(#mask0_1509_26788)">
                <path d="M7.5 10C6.38333 10 5.4375 9.6125 4.6625 8.8375C3.8875 8.0625 3.5 7.11667 3.5 6C3.5 4.89167 3.8875 3.94792 4.6625 3.16875C5.4375 2.38958 6.38333 2 7.5 2C8.60833 2 9.55208 2.38958 10.3313 3.16875C11.1104 3.94792 11.5 4.89167 11.5 6C11.5 7.11667 11.1104 8.0625 10.3313 8.8375C9.55208 9.6125 8.60833 10 7.5 10ZM7.5 9C8.33333 9 9.04167 8.70833 9.625 8.125C10.2083 7.54167 10.5 6.83333 10.5 6C10.5 5.16667 10.2083 4.45833 9.625 3.875C9.04167 3.29167 8.33333 3 7.5 3C6.66667 3 5.95833 3.29167 5.375 3.875C4.79167 4.45833 4.5 5.16667 4.5 6C4.5 6.83333 4.79167 7.54167 5.375 8.125C5.95833 8.70833 6.66667 9 7.5 9ZM8.6375 7.8625L9.35 7.15L8 5.8V4H7V6.2125L8.6375 7.8625ZM1 4.5V3.5H3V4.5H1ZM0.5 6.5V5.5H3V6.5H0.5ZM1 8.5V7.5H3V8.5H1Z" />
            </g>
        </svg>
    )
}