import React from 'react';
import { NavEmail } from '../Components';
import { clipboard, error } from '../assets';


export const Popup = ({ state, message, closePopup }) => {
    return (
        <>
            <div className="fixed top-0 left-0 h-screen w-full bg-[#22222580] z-20 !m-0" />
            <div className="p-6 sm:p-10 w-[280px] sm:w-[400px] space-y-6 fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white rounded-xl z-30">
                {state === "success" ?
                    <div className='p-3 rounded-full bg-[#27813526] w-fit mx-auto'>
                        <NavEmail className="fill-[--black-primary]" size={36} />
                    </div>
                    :
                    <img src={state === "error" ? error : clipboard} alt={state === "error" ? "Error" : "Clipboard"} loading='lazy' className="mx-auto" />
                }

                <p className="text-center">{message}</p>

                <button className="btn btn-blue w-36 mx-auto block" onClick={closePopup}>
                    OK
                </button>
            </div>
        </>
    )
};