import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';

import { readingTime } from 'reading-time-estimator';

import { LatestBlogs, BlogBreadCrumb, ContactUsForm, Footer, Navbar, Popup, ShareSocial, BlogCta, TechieonixLogoBlog, ClockBlog, MetaDecorator } from '../Components';
import { handleScrollForActiveSection } from '../utils/blogActiveSection';
import blogs from '../Data/Blogs';


export const Blog = () => {
    const [activeSection, setActiveSection] = useState("");
    const [readTime, setReadTime] = useState(null);
    const [blog, setBlog] = useState(null);
    const { blogParam } = useParams();

    // Popup
    const [popup, setPopup] = useState({ show: false, state: null, message: "" });


    useEffect(() => {
        setBlog(blogs.find(item => item.link === blogParam));

        // Calculating reading time
        const blogContent = document.getElementById("blogContent").innerText;
        setReadTime(`${readingTime(blogContent).minutes} min read`);

        // Scrolling for table of contents' active section
        const cleanup = handleScrollForActiveSection(setActiveSection);
        return cleanup;
    }, [blogParam, blog?.imageHero, blog?.title]);


    return (
        <>
            {/* Meta Data */}
            <MetaDecorator
                title={`${blog?.title} | Techieonix International`}
                imageUrl={blog?.imageHero}
                type="article"
            />

            {/* Navbar */}
            <Navbar white={true} />

            {/* Breadcrumb */}
            <BlogBreadCrumb category={blog?.category} title={blog?.title} />




            {/* Blog */}
            <section className="max-md:p-5 md:px-10 xl:px-[--px-xl] md:py-10 sm:space-y-10 center theme-section">

                {/* Header Image */}
                <div className="space-y-2 lg:ml-[264px] xl:ml-[322px] lg:mr-[104px] xl:mr-[131px]">
                    <img src={blog?.imageHero} alt={blog?.category + " Blog"} title={blog?.title} className="rounded-[--round]" />
                    <p className="pt-2.5 border-t text-xs text-[#23232666] text-center">
                        Image taken from Freepik
                    </p>
                </div>


                {/* Header / Intro */}
                <div className="py-5 space-y-4 lg:space-y-6 border-b border-[--black-primary-10] lg:ml-[264px] xl:ml-[322px] lg:mr-[104px] xl:mr-[131px]">
                    <div className="space-y-3">
                        <h1>{blog?.title}</h1>
                        <div className="flex gap-x-5 text-xs">
                            <div>{blog?.publishedDate}</div>
                            <div className="flex items-center gap-x-1 px-2">
                                <ClockBlog fill="#232326" />
                                <span>{readTime}</span>
                            </div>
                        </div>
                    </div>
                    <span className="chip">
                        {blog?.category}
                    </span>
                    <div className="flex gap-x-2 lg:gap-x-4 items-center">
                        <TechieonixLogoBlog black={true} />
                        <div>
                            <h5>{blog?.writer}</h5>
                            <p className="text-xs">{blog?.writerDesignation}</p>
                        </div>
                    </div>
                </div>




                <div className="max-lg:space-y-10 lg:flex gap-x-6">

                    {/* Table of Contents */}
                    <div className="max-sm:hidden shrink-0 lg:sticky top-0 lg:h-screen lg:w-[240px] xl:w-[298px] lg:pt-28 space-y-6">
                        <h5>Table of Contents</h5>
                        <div id="tableOfContents" className="flex flex-col gap-y-2 body-sm opacity-50 mt-[21px] *:w-fit">
                            {blog?.content.map((item, i) => (
                                <a
                                    key={i}
                                    href={`#${item.title.toLowerCase().replace(/ /g, '-')}`}
                                    className={`${activeSection === item.title.toLowerCase().replace(/ /g, '-') && "text-[--blue-primary] font-semibold"} hover:underline`}
                                >
                                    {item.title}
                                </a>
                            ))}

                            <a href="#conclusion" className={`${activeSection === "conclusion" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Conclusion
                            </a>
                        </div>
                    </div>



                    {/* Content */}
                    <div id="blogContent" className="space-y-10 body-lg">
                        {blog?.content.map((section, i) => (
                            <div key={`section${i}`} className="space-y-3 sm:space-y-6">
                                <h2 id={section.title.toLowerCase().replace(/ /g, '-')}>{section.title}</h2>

                                {/* Text Intro */}
                                {section.textIntro && <p>{section.textIntro}</p>}

                                {/* Image */}
                                {section.image &&
                                    <div className="space-y-2">
                                        <img src={section.image} alt={section.title} title={section.title} className="mx-auto rounded-[--round]" />
                                        <p className="pt-2.5 border-t text-xs text-[#23232666] text-center">
                                            Image taken from Freepik
                                        </p>
                                    </div>
                                }

                                {/* Bullets */}
                                {section.bullets &&
                                    <ul className="list-disc list-inside space-y-1">
                                        {section.bullets.map((point, i) => (
                                            <li key={i}>{point}</li>
                                        ))}
                                    </ul>
                                }

                                {/* Text */}
                                <p>{section.text}</p>
                            </div>
                        ))}


                        {/* CTA */}
                        <div className="flex flex-col gap-y-6 p-5 sm:p-8 xl:p-10 bg-[#EAEBF3] rounded-[--round]">
                            <h5>{blog?.cta}</h5>
                            <Link to="/contact-us" className="btn btn-black w-full sm:w-fit sm:self-end text-center">
                                Let’s Discuss
                            </Link>
                        </div>


                        {/* Conclusion */}
                        <div className="space-y-3 md:space-y-6">
                            <h2 id="conclusion">Conclusion</h2>
                            <p>{blog?.conclusion}</p>
                        </div>


                        {/* Outro */}
                        <div className="p-5 sm:p-8 md:p-10 xl:px-20 xl:py-12 space-y-6 bg-[--blue-primary] text-white rounded-[--round]">
                            <div className="flex items-center gap-x-2 lg:gap-x-4">
                                <TechieonixLogoBlog black={false} />
                                <div>
                                    <h5>{blog?.writer}</h5>
                                    <div className="text-xs">{blog?.writerDesignation}</div>
                                </div>
                            </div>

                            <div className="space-y-3">
                                <h4>{blog?.title}</h4>
                                <div className="flex gap-x-5 text-xs">
                                    <p>{blog?.publishedDate}</p>
                                    <div className="flex items-center gap-x-1 px-2">
                                        <ClockBlog fill="white" />
                                        <span>{readTime}</span>
                                    </div>
                                </div>
                            </div>

                            <span className="chip border-white">{blog?.category}</span>

                            <div className="space-y-2">
                                <p className="font-semibold">Share Link</p>
                                <div className="flex max-lg:flex-wrap gap-2.5 *:p-2 *:border *:border-white">
                                    <ShareSocial
                                        white={true}
                                        setPopup={setPopup}
                                        title={blog?.title}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* Share */}
                    <div className="max-lg:hidden shrink-0 sticky top-0 h-screen w-20 xl:w-[107px] pt-28 space-y-6">
                        <h5 className="text-end">Share</h5>
                        <div className="flex flex-col gap-y-2.5 items-end justify-end *:p-2 *:border *:border-[--black-primary]">
                            <ShareSocial setPopup={setPopup} title={blog?.title} />
                        </div>
                    </div>
                </div>
            </section>



            {/* All Blogs */}
            <LatestBlogs />

            {/* CTA */}
            <BlogCta />

            {/* Contact Us Form */}
            <ContactUsForm />

            {/* Footer */}
            <Footer />

            {/* Popup */}
            {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
        </>
    );
}