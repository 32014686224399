import React from "react";
import { Link } from "react-router-dom";

import blogs from "../Data/Blogs";
import { BlogCard } from "../Components";


export const LatestBlogs = () => {

  const latestBlogs = (() => {
    const screenWidth = window.innerWidth;
    const blogCount = screenWidth >= 768 && screenWidth <= 1023 ? 2 : 3;

    return blogs
      .filter(blog => window.location.pathname !== `/blogs/${blog.link}`)
      .sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate))
      .slice(0, blogCount);
  })();



  return (
    <section className="sm:px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 space-y-6 sm:space-y-10 center theme-section">

      {/* Title */}
      <div className="max-sm:px-5 max-xl:px-8 space-y-3 sm:space-y-6 text-center">
        <h1>Our Latest Blog</h1>
        <p className="xl:w-[--title-intro-width] mx-auto">Stay updated with the latest trends, insights, and news from the IT industry. Our blog features articles, guides, and expert opinions to help you stay informed and ahead of the curve</p>
      </div>




      {/* Blogs */}
      <div className="max-md:space-y-6 md:grid grid-cols-2 lg:grid-cols-3 gap-x-6">
        {latestBlogs.map((blog, i) => (
          <BlogCard
            key={i}
            image={blog.imageHero}
            category={blog.category}
            link={`/blogs/${blog.link}`}
            title={blog.title}
            date={blog.publishedDate}
            readTime={blog.readTime}
            description={blog.content[0].text || blog.content[0].textIntro}
          />
        ))}
      </div>




      <Link to="/blogs" className="btn btn-blue w-full sm:w-fit max-sm:text-center sm:mx-auto sm:block">View All Blogs</Link>
    </section>
  );
};
