import React, { useState, useEffect } from "react";

import { ClientTestimonials, Footer, Navbar, OurClients, SocialIcons, Popup } from "../Components";
import { email, phone, location } from "../assets";
import { Link } from "react-router-dom";
import { validateEmail, validateName, validatePhoneNumber, validateTextArea } from "../utils/formvalidate";


export const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessEmail: "",
    businessPhone: "",
    company: "",
    message: "",
    accept: false,
  });

  // Popup
  const [popup, setPopup] = useState({ show: false, state: null, message: "" });

  // Recaptcha
  const [recaptchaError, setRecaptchaError] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");


  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LdvvlUqAAAAANRjjb-ZtQWsAiuqzZF6VO_quDwZ", { action: "submit" }).then((token) => setRecaptchaToken(token));
    });
  }, []);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, businessEmail, businessPhone, message, accept } = formData;

    // Validating first and last names
    const firstNameValidation = validateName("first name", firstName);
    const lastNameValidation = validateName("last name", lastName);
    if (firstNameValidation || lastNameValidation) {
      setPopup({
        show: true,
        state: "error",
        message: firstNameValidation || lastNameValidation
      });
      return;
    }

    // Email validation
    if (validateEmail(businessEmail)) {
      setPopup({
        show: true,
        state: "error",
        message: "Please provide a valid email"
      });
      return;
    }

    // Validating Phone Number
    if (businessPhone && businessPhone.trim() !== "") {
      const phoneValidation = validatePhoneNumber(businessPhone);
      if (phoneValidation) {
        setPopup({
          show: true,
          state: "error",
          message: phoneValidation
        });
        return;
      }
    }

    // Message validation
    const messageValidation = validateTextArea("message", message, 400);
    if (messageValidation) {
      setPopup({
        show: true,
        state: "error",
        message: messageValidation
      });
      return;
    }

    // Checking whether the checkbox for accepting the Privacy Policy has been checked
    if (!accept) {
      setPopup({
        show: true,
        state: "error",
        message: "Please accept the Privacy Policy before submitting"
      });
      return;
    }

    // Checking reCAPTCHA verification
    if (!recaptchaToken) {
      setRecaptchaError("Please complete the reCAPTCHA.");
      return;
    }

    try {
      const payload = {
        ...formData,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        "g-recaptcha-response": recaptchaToken
      };
      console.log("Submitting form with payload:", payload);

      const response = await fetch("https://formspree.io/f/mnnaekol", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        setFormData({
          firstName: "",
          lastName: "",
          businessEmail: "",
          businessPhone: "",
          company: "",
          message: "",
          accept: ""
        });
        setPopup({
          show: true,
          state: "success",
          message: "Form submitted successfully!"
        });
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setPopup({
        show: true,
        state: "error",
        message: "Something went wrong while submitting the form! Please try again or contact our support at contact@techieonix.com"
      });
    }
  };


  return (
    <>
      {/* Navbar */}
      <Navbar />




      {/* Hero */}
      <section className="contactUs-hero-bg theme-section md:content-end md:h-[340px]">
        <div className="max-sm:pt-[110px] max-md:pt-[130px] max-md:p-5 sm:pb-10 md:px-10 xl:px-[--px-xl] text-white center">
          <h1>Contact Us</h1>
        </div>
      </section>




      {/* Main */}
      <section className="lg:pt-[--py-lg] md:px-10 md:pt-10 xl:px-[--px-xl] theme-section">
        <div className="p-5 sm:px-5 sm:py-10 md:p-10 grid lg:grid-cols-2 gap-y-3 sm:gap-6 bg-[#EAEBF3] rounded-[--round] center-inner">

          <div className="space-y-3 sm:space-y-6">

            {/* Client Testimonials */}
            <ClientTestimonials isContactPage={true} />


            {/* Get in touch */}
            <div className="p-6 sm:p-8 space-y-4 sm:space-y-6 bg-[--black-primary] text-white rounded-[--round]">
              <h2 className="pb-3 max-sm:text-center border-b border-[--white-10]">
                Get In Touch
              </h2>

              <div className="space-y-2 sm:space-y-3 body-lg">
                <p className="caption opacity-80">ADDRESS</p>
                <div className="flex gap-x-2">
                  <img src={location} alt="location" />
                  <p>Karachi - Pakistan</p>
                </div>
              </div>

              <div className="space-y-2 md:space-y-3 body-lg">
                <p className="caption opacity-80">CONTACT INFO</p>
                <div className="flex gap-x-2">
                  <img src={phone} alt="phone" />
                  <p>+92 (313) 129-7309</p>
                </div>
                <div className="flex gap-x-2">
                  <img src={email} alt="email" />
                  <p>contact@techieonix.com</p>
                </div>
              </div>
            </div>
          </div>


          <form className="p-5 sm:p-8 md:p-10 space-y-4 bg-white rounded-[--round] max-lg:-order-1" onSubmit={handleSubmit}>
            <h2 className="pb-5 border-b border-[--black-primary-10] max-md:text-center">Send Us a Message</h2>

            <input type="text" placeholder="First Name*" id="firstName" name="firstName"
              className="input-default w-full" maxLength={50} required
              value={formData.firstName} onChange={handleChange}
            />

            <input type="text" placeholder="Last Name*" id="lastName" name="lastName"
              className="input-default w-full" maxLength={50} required
              value={formData.lastName} onChange={handleChange}
            />

            <input type="email" placeholder="Business Email*" id="businessEmail" name="businessEmail"
              className="input-default w-full" required
              value={formData.businessEmail} onChange={handleChange}
            />

            <input type="tel" placeholder="Business Phone" id="businessPhone" name="businessPhone"
              className="input-default w-full" maxLength={16}
              value={formData.businessPhone} onChange={handleChange}
            />

            <input type="text" placeholder="Company" id="company" name="company"
              className="input-default w-full"
              value={formData.company} onChange={handleChange}
            />

            <textarea placeholder="Tell us about your project*" id="message" name="message"
              className="input-default w-full align-bottom" maxLength={400} required
              value={formData.message} onChange={handleChange}
            />

            <div className="flex items-start gap-x-3">
              <input type="checkbox" name="accept" id="accept" required onChange={handleChange} checked={formData.accept} />
              <label htmlFor="accept" className="text-xs text-[--black-secondary]">
                By sending this form I confirm that I have read and accept Techieonix <Link to="/privacy-policy" className="underline">Privacy Policy</Link>
              </label>
            </div>

            <button type="submit" className="btn btn-blue w-full !mt-8 sm:w-40">Send</button>

            {recaptchaError && <p className="text-red-500 text-xs">{recaptchaError}</p>}
          </form>
        </div>
      </section>




      {/* Follow Us */}
      <section className="py-20 space-y-6 text-center">
        <h5 className="text-[--black-primary]">Follow Us Here</h5>
        <div className="flex gap-x-2.5 justify-center *:p-2 *:border *:border-[--black-primary]">
          <SocialIcons />
        </div>
      </section>




      {/* Location */}
      <section className="theme-section">
        <iframe
          className="w-full h-80 sm:h-[360px] lg:h-[500px]"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57904.638836868464!2d66.9782336360962!3d24.896619668477985!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f085817d809%3A0xa486a8b0bea78ebf!2sTechieonix%20International!5e0!3m2!1sen!2s!4v1724418913108!5m2!1sen!2s"
          title="Techieonix International Location"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </section>




      {/* Our Clients */}
      <OurClients />

      {/* Footer */}
      <Footer />

      {/* Popup */}
      {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
    </>
  );
};
