import React from 'react';
import { Link } from 'react-router-dom';

import { ClockBlog } from '../Components';


export const BlogCard = ({ image, category, link, title, date, readTime, description, headingLarge = false }) => {
    return (
        <div className="p-5 rounded-[--round] bg-[--blue-primary-10] group">
            <div className="overflow-hidden rounded-[--round]">
                <img src={image} alt={category + " Blog"} className="rounded-[10px] lg:group-hover:scale-110 transition-transform duration-300" />
            </div>


            <div className="py-5 space-y-4 md:space-y-6">
                <span className="chip">{category}</span>


                {/* Title & Timestamp */}
                <div className="space-y-1">
                    <Link to={link} className="block max-lg:underline lg:hover:underline">
                        {headingLarge ? <h3>{title}</h3> : <h4>{title}</h4>}
                    </Link>
                    <div className="flex justify-between">
                        <span className="text-xs">{date}</span>

                        <div className="flex gap-x-1 items-center px-2">
                            <ClockBlog fill="#232326" />
                            <span className="text-xs">{readTime}</span>
                        </div>
                    </div>
                </div>


                <p className="line-clamp-2">{description}</p>
            </div>
        </div>
    )
};