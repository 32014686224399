import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import { Chevron } from "../Components"
import { caseComponentBeTravel, caseComponentEntraSign, caseComponentIms, caseComponentMba, caseComponentSk } from '../assets'


const caseStudies = [
    {
        project: "Transforming Online Presence: A Custom Website for M.B.A Consultants",
        client: "M.B.A Consultants",
        text: "We built a custom, responsive website from scratch for M.B.A Consultants, a financial and management consulting firm. Using the MERN stack, we ensured seamless performance across all devices, with a focus on SEO optimization and easy navigation. The result was a significant boost in client inquiries and a professional online presence reflecting the firm's expertise.",
        image: caseComponentMba,
        link: "/case-studies/mba-consultants"
    },
    {
        project: "Building a Modern and Responsive Website for SK Printing Solution",
        client: "SK Printing Solution",
        text: "We developed a responsive, visually appealing website for SkPrintingSolution to enhance their online presence and showcase their products. Using ReactJS and deploying via Vercel and Heroku, we ensured scalability and seamless user experience across devices. Our comprehensive branding strategy further strengthened their identity, driving increased engagement and business growth.",
        image: caseComponentSk,
        link: "/case-studies/sk-printing-solution"
    },
    {
        project: "Automated User Sign-In Tracking and Compliance Monitoring System for Microsoft Entra ID",
        client: "Entrasign",
        text: "We partnered with an organization using Microsoft Entra ID for identity management to create a system for tracking user sign-ins and ensuring compliance. The challenge was capturing the last sign-in details and automating proactive monitoring. Our solution leveraged Azure tools to extract user data, identify inactive users, send notifications, and provide detailed reporting, helping the client maintain compliance and streamline operations.",
        image: caseComponentEntraSign,
        link: "/case-studies/entrasign-compliance-tracker"
    },
    {
        project: "End-to-End Development of a Comprehensive Sales and Inventory Management Platform",
        client: "IMS SK Printing Solution",
        text: "We built a Sales and Inventory Management Platform for a growing printing solutions company facing inefficiencies due to manual processes. Using the MERN stack, we developed a scalable solution with real-time analytics, sales management, and inventory control. The platform improved operational efficiency, enhanced data accuracy, and supported the client’s transition to online sales, driving increased revenue and growth.",
        image: caseComponentIms,
        link: "/case-studies/ims-sk-printing-solution"
    },
    {
        project: "Building a User-Centric and Simplified Travel Website",
        client: "BeTravel",
        text: "We developed a user-centric travel website that simplifies trip planning by allowing users to enter their destination, check-in, and check-out dates. The website was built using canva, focusing on user-friendly design. The website shows top destinations for users to decide easily, and provides promo codes for travellers to avail discounts, and handles all your worries during trip, from flight and hotel bookings to managing whole tour activities, thus ensuring the customer satisfaction. This not only streamlines the user experience but also boosts customer engagement and drives business growth by converting casual browsers into loyal travellers.",
        image: caseComponentBeTravel,
        link: "/case-studies/be-travel"
    }
];


export const CaseStudyComp = ({ isBlack = false, hideCase = false }) => {
    const [caseNo, setCaseNo] = useState(1);
    const [activeCaseOnMobile, setActiveCaseOnMobile] = useState(null);


    const fillteredCases = hideCase ? caseStudies.filter(caseStudy => caseStudy.link !== window.location.pathname) : caseStudies;

    const toggleCardOnMobile = i => activeCaseOnMobile === i ? setActiveCaseOnMobile(null) : setActiveCaseOnMobile(i);


    return (
        <section className={`${isBlack && "text-white bg-[--black-primary]"} theme-section`}>
            <div className="py-10 lg:py-[--py-lg] xl:py-20 space-y-10 center">
                <div className="px-5 md:px-10 xl:p-0 xl:w-[--title-intro-width] space-y-6 text-center mx-auto">
                    <h1>{hideCase ? "Discover More Case Studies" : "Our Case Studies"}</h1>
                    {!hideCase && <p>Explore our case studies to see how we’ve helped businesses achieve their goals through innovative IT solutions. Each case study highlights our approach, the challenges we addressed, and the results we delivered.</p>}
                    <Link to="/case-studies" className='btn btn-blue max-sm:w-full md:hidden'>View All Case Studies</Link>
                </div>



                <Swiper
                    navigation={{
                        nextEl: '.next-popup',
                        prevEl: '.prev-popup'
                    }}
                    modules={[Navigation]}
                    slidesPerView={1.1}
                    spaceBetween={0}
                    loop={true}
                    className="mySwiper"
                    onSlideChange={e => setCaseNo(e.realIndex)}
                >
                    {fillteredCases.map((caseStudy, i) => (
                        <SwiperSlide key={i}>
                            <div className={`${isBlack && "text-white"} max-sm:px-2.5 max-md:px-5 md:pl-10 md:space-y-6 lg:space-y-0 lg:flex items-end gap-x-6 xl:gap-x-10`}>

                                <img src={caseStudy.image}
                                    alt={caseStudy.project}
                                    title={caseStudy.project}
                                    className="max-md:hidden max-xl:h-[440px] w-full lg:w-auto bg-center bg-cover rounded-2xl"
                                />

                                <div
                                    className={`max-sm:h-[400px] max-md:h-[496px] max-sm:p-5 max-md:px-5 max-md:py-10 lg:pb-10 max-md:bg-cover max-md:rounded-[--round] md:!bg-none max-md:bg-[#19185fe6] lg:w-2/5 xl:w-auto ${activeCaseOnMobile === i ? "max-md:bg-blend-multiply" : "max-md:content-end"}`}
                                    style={{ backgroundImage: activeCaseOnMobile === i ? `url("${caseStudy.image}")` : `linear-gradient(180deg, transparent 45%, var(--black-primary)), url("${caseStudy.image}")` }}
                                    onClick={() => toggleCardOnMobile(i)}
                                >
                                    <h4 className={`${activeCaseOnMobile === i && "max-md:hidden"} text-white md:hidden`}>{caseStudy.project}</h4>

                                    <div className={`space-y-6 lg:space-y-12 flex-col justify-between items-start max-lg:h-full ${i === activeCaseOnMobile ? "slide-from-bottom max-md:flex" : "max-md:hidden"}`}>
                                        <div className='space-y-6 max-md:text-white'>
                                            <h4>{caseStudy.project}</h4>
                                            <p className="line-clamp-4 sm:line-clamp-[7] md:line-clamp-none lg:line-clamp-4 xl:line-clamp-none">{caseStudy.text}</p>
                                        </div>


                                        {/* Link (0 - 767) */}
                                        <Link to={caseStudy.link} aria-label={`View ${caseStudy.client} case study`} className="link-underline text-white after:bg-white md:hidden">Read more</Link>

                                        {/* Link (0 - 768) */}
                                        <Link to={caseStudy.link} aria-label={`View ${caseStudy.client} case study`} className={`btn ${isBlack ? "btn-outline-white" : "btn-outline"} max-md:hidden max-lg:!mb-px`}>Read more</Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>



                <div className='px-5 md:px-10 xl:px-[--px-xl] flex items-center justify-end gap-x-5'>
                    <div className={`prev-popup p-2 rounded-md rotate-90 cursor-pointer ${isBlack ? "bg-white" : "bg-[--black-primary]"}`}>
                        <Chevron className={`${isBlack ? "fill-[#232326]" : "fill-white"}`} />
                    </div>
                    <h6 className="select-none">{caseNo + 1} / {fillteredCases.length}</h6>
                    <div className={`next-popup p-2 rounded-md -rotate-90 cursor-pointer ${isBlack ? "bg-white" : "bg-[--black-primary]"}`}>
                        <Chevron className={`${isBlack ? "fill-[#232326]" : "fill-white"}`} />
                    </div>
                </div>

                <Link to="/case-studies" className='btn btn-blue block w-fit mx-auto max-md:hidden'>View All Case Studies</Link>
            </div>
        </section>
    )
}