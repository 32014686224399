import React, { useEffect, useRef } from "react";

import { ClientTestimonials, Footer, Navbar, OurClients, ContactUsForm, Cta } from "../Components";
import { aboutValue1, aboutValue2, aboutValue3, aboutValue4 } from "../assets";


const values = [
  {
    name: "Integrity",
    description: "We uphold the highest standards of integrity in all our actions.",
    bgImage: aboutValue1
  },
  {
    name: "Innovation",
    description: "We foster a culture of creativity and continuous improvement.",
    bgImage: aboutValue2
  },
  {
    name: "Excellence",
    description: "We strive for excellence in every project we undertake.",
    bgImage: aboutValue3
  },
  {
    name: "Collaboration",
    description: "We believe in the power of teamwork and partnership.",
    bgImage: aboutValue4
  }
];

const expertise = [
  {
    name: "Web Development",
    description: "Our custom web development services ensure your website is not only visually appealing but also highly functional and user-friendly. We create responsive, scalable, and secure websites tailored to your business needs."
  },
  {
    name: "Mobile App Development",
    description: "We develop innovative mobile applications that provide seamless user experiences, helping you engage your audience on the go. Our apps are designed to be intuitive, robust, and compatible across various devices."
  },
  {
    name: "IT Consulting",
    description: "Our IT consulting services offer strategic guidance and expert advice to optimize your IT infrastructure. We help you navigate the complexities of technology, ensuring your IT strategy aligns with your business objectives."
  },
  {
    name: "UI/UX Design",
    description: "Our UI/UX design services focus on creating engaging and intuitive user experiences. We ensure that your digital products are not only functional but also visually captivating, enhancing user satisfaction and loyalty."
  },
  {
    name: "QA & Testing",
    description: "Our comprehensive QA and testing services guarantee the reliability and performance of your software. We conduct thorough testing to identify and resolve issues, ensuring a flawless user experience."
  },
  {
    name: "Software Architecture Solutions",
    description: "We provide software architecture solutions that ensure your applications are scalable, maintainable, and aligned with your business goals. Our architects design robust frameworks that support long - term growth."
  },
  {
    name: "Cloud Solutions",
    description: "Our cloud solutions enhance your business agility and scalability. We offer secure and efficient cloud services, from migration to management, helping you leverage the full potential of cloud technology."
  },
  {
    name: "DevOps Solutions",
    description: "Our DevOps solutions help you streamline your software development process by automating repetitive tasks, improving collaboration, and reducing errors. We offer guidance on implementing DevOps practices and best practices to ensure your software is efficient, secure, and reliable."
  }
];


export const AboutUs = () => {
  const whyUsRef = useRef(null);


  const applyBackground = (i, image) => {
    if (whyUsRef.current && window.outerWidth > 1023) {
      whyUsRef.current.style.backgroundImage = `url("${image}")`;

      Array.from(whyUsRef.current.children).forEach((_, index) => {
        const hoveredCard = whyUsRef.current.children[index];
        const cardText = hoveredCard.children[1];

        if (index === i) {
          cardText.classList.remove("hidden");
          hoveredCard.style.backgroundColor = "#00000080";
        } else {
          cardText.classList.add("hidden");
          hoveredCard.style.backgroundColor = "transparent";
        }
      });
    } else {
      whyUsRef.current.style.backgroundImage = "none";
    }
  };

  useEffect(() => applyBackground(0, aboutValue1), []);


  return (
    <>
      {/* Navbar */}
      <Navbar />




      {/* Hero */}
      <section className="aboutUs-hero-bg bg-right-bottom theme-section md:content-end md:h-[340px]">
        <div className="max-sm:pt-[110px] max-md:pt-[130px] max-md:p-5 sm:pb-10 md:px-10 xl:px-[--px-xl] text-white center">
          <h1>About Us</h1>
        </div>
      </section>




      {/* Introduction */}
      <section className="max-sm:px-5 max-md:px-[52px] max-xl:px-10 py-10 lg:py-[--py-lg] xl:py-20 theme-section">
        <div className="md:px-10 xl:px-0 xl:w-[--title-intro-width] space-y-6 mx-auto text-center text-[--black-primary]">
          <h1>Our Story</h1>
          <p>At TechieOnix, we believe in harnessing the power of technology to drive innovation and success for our clients. Founded in 2023, our mission has always been to deliver top-notch solutions that meet the unique needs of businesses across various industries.</p>
        </div>
      </section>




      {/* Our Mission and Vision */}
      <section className="max-sm:px-5 max-md:px-[52px] max-xl:px-20 py-10 lg:py-[--py-lg] xl:py-20 theme-section mission-bg text-white rounded-[--round]">
        <div className="lg:max-w-[--title-intro-width] space-y-10 lg:space-y-[--py-lg] xl:space-y-20 mx-auto">

          <div className="space-y-3 sm:space-y-6 text-center">
            <h1 className="pb-3 sm:pb-6 border-b border-[#ffffff33]">Our Mission</h1>
            <p>To provide cutting-edge technological solutions that empower businesses to achieve their goals and stay ahead in a rapidly evolving digital landscape.</p>
            <p>Since our founding in 2023, Techieonix has been at the forefront of technological innovation, specializing in Web Development, UI/UX Design, Cloud Solutions, Software Architecture Solutions, IT Consulting, QA & Testing, DevOps Solutions, FinOps, and more. Our mission is to deliver tailored solutions that empower businesses to thrive in the digital age.</p>
          </div>


          <div className='p-5 md:p-10 space-y-3 sm:space-y-6 rounded-[--round] bg-[--white-10] text-center'>
            <h1>Our Vision</h1>
            <p>To be a global leader in technology services, known for our commitment to excellence, innovation, and customer satisfaction.</p>
          </div>
        </div>
      </section>




      {/* Our Values */}
      <section className="sm:px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 space-y-6 md:space-y-10 center theme-section">
        <h1 className="max-sm:px-5">Our Values</h1>

        <div
          ref={whyUsRef}
          className="flex max-lg:gap-x-[5px] max-lg:overflow-x-auto lg:grid grid-cols-4 lg:bg-center lg:bg-cover lg:rounded-[--round] max-lg:!bg-none lg:divide-x divide-[#ffffff80] text-white"
        >
          {values.map((item, i) => (
            <div
              key={i}
              className={`px-8 xl:px-10 pt-10 lg:pt-[--py-lg] max-sm:w-4/5 max-md:w-3/4 max-lg:w-2/5 h-52 md:h-60 lg:h-72 lg:!bg-none bg-cover bg-center max-lg:shrink-0 max-lg:rounded-[10px]
              ${i === 0 && "lg:rounded-l-[--round]"}
              ${i === values.length - 1 && "lg:rounded-r-[--round]"}`}
              style={{ backgroundImage: `url("${item.bgImage}")` }}
              onMouseEnter={() => applyBackground(i, item.bgImage)}
            >
              <h4 className="text-wrap">{item.name}</h4>
              <p className="mt-5 text-fadein max-lg:!block">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </section>




      {/* Our Expertise */}
      <section className="bg-[--black-primary] rounded-[--round] text-white theme-section">
        <div className="px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 space-y-6 sm:space-y-10 center">

          <div className="sm:px-8 md:px-10 xl:px-0 xl:w-[--title-intro-width] space-y-3 sm:space-y-6 text-center mx-auto">
            <h1>Our Expertise</h1>
            <p>At Techieonix, we specialize in delivering cutting-edge solutions across various domains to help our clients achieve their business goals. Our core areas of expertise include:</p>
          </div>


          <div>
            {expertise.map((item, i) => (
              <div key={i} className="p-5 pl-10 sm:px-12 md:px-[--py-lg] sm:py-8 space-y-3 sm:space-y-6 group border-l-gray-600 border-l-2 hover:border-l-white hover:bg-[--white-10] relative transition-colors
              before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 backdrop:w-3 before:h-5 before:border-y-[10px] before:border-l-[10px] before:border-l-transparent hover:before:border-l-white before:border-y-transparent before:scale-0 hover:before:scale-100 before:transition-transform before:duration-300">
                <h3>{item.name}</h3>
                <p className="hidden group-hover:block text-fadein">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>




      {/* CTA */}
      <Cta
        title="Let's Collaborate"
        text="Ready to take your business to the next level? Our team at Techieonix is here to help you achieve your goals with innovative solutions tailored to your needs. Whether you have a project in mind or just want to learn more about what we do, we'd love to hear from you."
        linkText="Contact Us Today"
      />




      {/* What Our Clients Say */}
      <section className="bg-[--black-primary] theme-section">
        <div className="px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 max-md:space-y-6 md:flex gap-x-6 justify-between center">
          <h1 className="max-md:text-center sm:px-8 md:px-0 text-white">What Our Clients Say</h1>

          <ClientTestimonials />
        </div>
      </section>




      {/* Our Clients */}
      <OurClients />

      {/* Contact Us */}
      <ContactUsForm />

      {/* Footer */}
      <Footer />
    </>
  );
};
