import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import services from "../Data/Services";
import { Navbar, OurClients, Footer, CaseStudyComp, Cta } from "../Components";


export const Service = () => {
  const [service, setService] = useState({});
  const [activeFeatureOnMobile, setActiveFeatureOnMobile] = useState(null);
  const { serviceParam } = useParams();


  useEffect(() => setService(services.find((el) => el.title.replace(/&/g, "and").replace(/[ /]/g, "-").toLowerCase() === serviceParam)), [serviceParam]);

  const toggleKeyFeaturesOnMobile = i => activeFeatureOnMobile === i ? setActiveFeatureOnMobile(null) : setActiveFeatureOnMobile(i);


  return (
    <>
      {/* Navbar */}
      <Navbar />




      {/* Hero */}
      <section className="service-hero-bg theme-section md:content-end md:h-[340px]">
        <div className="max-sm:pt-[110px] max-md:pt-[130px] max-md:p-5 sm:pb-10 md:px-10 xl:px-[--px-xl] text-white center">
          <h1>{service.title}</h1>
        </div>
      </section>




      {/* Service */}
      <section className="px-5 md:px-10 xl:px-[--px-xl] pt-10 lg:pt-[--py-lg] space-y-6 sm:space-y-10 lg:space-y-[--py-lg] xl:space-y-20 center mb-[5px]">
        <div className="space-y-3 sm:space-y-6">
          <h1>{service.title} Service</h1>
          <p>{service.details}</p>
        </div>

        <div className="max-lg:space-y-6 lg:grid grid-cols-2 gap-x-6">
          <div className="bg-cover rounded-[--round] max-sm:h-[280px] max-md:h-80 max-lg:h-[440px]" style={{ backgroundImage: `url("${service.image}")` }} />

          <ul className="content-center divide-y divide-[#23232633]">
            {service.keyPoints?.map((item, i) => (
              <li key={i} className={`max-lg:py-3 flex gap-x-5 items-center ${service.keyPoints?.length > 5 ? "lg:py-2.5" : "lg:py-6"}`}>
                <img src={item.icon} alt={"Point " + (i + 1)} />
                <h5>{item.point}</h5>
              </li>
            ))}
          </ul>
        </div>
      </section>




      {/* Key Features */}
      <section className="pt-10 space-y-6 sm:space-y-10 theme-section">
        <h1 className="px-5 md:px-10 xl:px-[--px-xl] center">Key Features</h1>


        <div className="md:flex flex-wrap bg-[#00000080] bg-blend-multiply rounded-[--round] text-white max-md:bg-center md:bg-cover divide-x divide-y divide-[#ffffff80]" style={{ backgroundImage: `url('${service.featuresBgImage}')` }}>
          {service.keyFeatures?.map((item, i) => (
            <div key={item.name} className={`group max-md:py-5 px-5 pt-20 md:space-y-5 md:hover:pb-10 md:h-[300px] md:w-[370px] lg:w-[450px] content-end grow md:hover:bg-[#3047c380] transition-all duration-300
              ${i === 0 && "max-md:rounded-t-[--round]"}
              ${i === service.keyFeatures?.length - 1 && "max-md:rounded-b-[--round]"}
              ${activeFeatureOnMobile === i ? "max-md:bg-[#3047c380]" : "max-md:content-end"}`}
              onClick={() => toggleKeyFeaturesOnMobile(i)}
            >
              <h4 className="md:pb-10">{item.name} </h4>
              <p className={`max-h-0 max-md:pt-5 overflow-y-hidden md:group-hover:max-h-96 transition-[max-height] duration-300 ${activeFeatureOnMobile === i && "max-md:max-h-96"}`}>
                {item.feature || item.description}
              </p>
            </div>
          ))}
        </div>
      </section>




      {/* CTA */}
      <Cta title={service.cta?.title} text={service.cta?.description} linkText="Start a Project" />




      {/* Why Us */}
      <section className="bg-[--white-primary] theme-section">
        <div className="py-10 lg:py-[--py-lg] xl:py-20 px-5 md:px-10 xl:px-[--px-xl] space-y-10 center">
          <h1 className="sm:px-8 md:px-10 xl:px-0 md:mx-auto xl:w-[--title-intro-width] text-center">
            Why Choose Our {service.title} Services?
          </h1>

          <div className="max-md:space-y-3 md:flex flex-wrap gap-6">
            {service.whyUs?.map((choose, i) => (
              <div key={i} className="rounded-[--round] border border-[--black-primary] p-5 space-y-4 sm:space-y-5 md:w-[258px] lg:w-[295px] lg_xl:w-[400px] grow hover:bg-[--black-primary] hover:text-white transition-colors">
                <h4>{choose.name}</h4>
                <p>{choose.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>




      {/* Our Case Studies */}
      <CaseStudyComp isBlack={true} />

      {/* Our Clients */}
      <OurClients />

      {/* Footer */}
      <Footer />
    </>
  );
};
