import React from 'react';
import { Link } from 'react-router-dom';

export const Cta = ({ title, text, linkText }) => {
    return (
        <section className="theme-section bg-cover cta-bg">
            <div className="space-y-6 px-5 sm:px-[52px] md:px-10 xl:px-[--px-xl] py-10 lg:py-20 text-center center">
                <h1>{title}</h1>

                <p className="md:px-10 xl:px-0 xl:w-[--title-intro-width] mx-auto">{text}</p>

                <Link to="/contact-us" className="btn btn-blue mx-auto max-sm:w-full">{linkText}</Link>
            </div>
        </section>
    )
};