import React, { useState } from 'react'

import { testimonialMba, testimonialSk, testimonialAcloudTechie, testimonialHomeHeart } from '../assets';
import { Arrow } from '../Components';


const testimonials = [
    {
        companyLogo: testimonialSk,
        description: "Techieonix International exceeded our expectations with their efficient project management and professional service. Their team delivered our website on time and within budget, with outstanding attention to detail.",
        name: "Muhammad Shereyar",
        position: "CEO SK Printing Solutions",
    },
    {
        companyLogo: testimonialSk,
        description: "We were impressed by Techieonix's ability to develop innovative solutions tailored to our unique business needs. Their expertise in software development transformed our operations and increased our productivity significantly.",
        name: "Muhammad",
        position: "Inventory Manager at SK Printing Solutions",
    },
    {
        companyLogo: testimonialMba,
        description: "The team at Techieonix is incredibly responsive and collaborative. They kept us informed at every stage of the project and welcomed our feedback. The final product was exactly what we envisioned, and we're thrilled with the results.",
        name: "Majid Bashir",
        position: "CEO of MBA Consultants",
    },
    {
        companyLogo: testimonialAcloudTechie,
        description: "Working with Techieonix was a great experience. Their developers are highly skilled and adaptable, providing us with cutting-edge technology and seamless integration. We highly recommend them for any IT project.",
        name: "Abdullah",
        position: "Cloud Architect - acloudtechie.com",
    },
    {
        companyLogo: testimonialHomeHeart,
        description: "The post-launch support from Techieonix has been exceptional. They are always available to address any issues or make updates as needed. Their commitment to client satisfaction is truly commendable.",
        name: "Arif - Smart GGZ",
        position: "Operations Manager at Home Heart Residency",
    },
];


export const ClientTestimonials = ({ isContactPage = false }) => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const { companyLogo, description, name, position } = testimonials[currentSlide - 1];
    const totalSlides = testimonials.length;


    const goToPreviousSlide = () => setCurrentSlide(prevSlide => prevSlide === 1 ? totalSlides : prevSlide - 1);
    const goToNextSlide = () => setCurrentSlide((prevSlide) => prevSlide === totalSlides ? 1 : prevSlide + 1);


    return (
        <div className={`space-y-6 md:space-y-10 px-5 sm:px-8 pt-6 sm:pt-8 pb-[76px] sm:pb-20 md:py-10 rounded-3xl max-md:relative
        ${isContactPage ? "bg-white" : "client-gradient md:w-[620px] xl:w-[615px]"}`}
        >
            <div className="flex justify-between items-center">
                <img src={companyLogo} alt="Logo" title="Company Logo" className="h-8 md:h-10" />

                <div className="max-md:absolute bottom-6 left-1/2 max-md:-translate-x-1/2 max-md:mx-auto flex gap-x-2 justify-center items-center select-none">
                    <div className="cursor-pointer" onClick={goToPreviousSlide}><Arrow className="fill-[#1C1B1F]" /></div>
                    <h6>{currentSlide} / {totalSlides}</h6>
                    <div className="cursor-pointer rotate-180" onClick={goToNextSlide}><Arrow className="fill-[#1C1B1F]" /></div>
                </div>
            </div>

            <p>{description}</p>

            <div className="space-y-0.5">
                <h6>{name}</h6>
                <p className="body-sm">{position}</p>
            </div>
        </div>
    )
};