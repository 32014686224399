import React, { useEffect, useState } from 'react'
import { arrow } from '../assets'

export const GoToTop = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => window.addEventListener('scroll', () => window.pageYOffset > 1100 ? setShowButton(true) : setShowButton(false)), [])


    return (
        <>
            {showButton &&
                <div className="z-10 fixed bottom-20 right-6 p-4 rounded-full border border-[#ffffff99] bg-[#27358133] hover:bg-[#273581cc] transition-colors cursor-pointer rotate-180" onClick={() => window.scrollTo(0, 0)}>
                    <img src={arrow} alt="Go to top" title="Go to top" />
                </div>
            }
        </>
    )
}