import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Footer, Navbar, Popup, TechStack } from "../Components";
import { careerLifeBalance, careerLifeCollaborative, careerLifeHealth, careerLifeLearning, careerLifeRecognition } from '../assets';
import { validateTextArea, validateEmail, validateName, validatePhoneNumber } from '../utils/formvalidate';


const lifeAtTechieonix = [
    {
        icon: careerLifeCollaborative,
        title: "Collaborative Environment",
        description: "Work alongside passionate and talented professionals dedicated to excellence."
    },
    {
        icon: careerLifeLearning,
        title: "Continuous Learning",
        description: "Access to training programs, workshops, and conferences to enhance your skills."
    },
    {
        icon: careerLifeBalance,
        title: "Work-Life Balance",
        description: "Flexible working hours and remote work options to help you balance personal and professional life."
    },
    {
        icon: careerLifeHealth,
        title: "Health & Wellness",
        description: "Comprehensive health benefits and wellness programs to keep you healthy and happy."
    },
    {
        icon: careerLifeRecognition,
        title: "Employee Recognition",
        description: "Regular recognition and rewards for outstanding performance and contributions."
    }
]


export const Careers = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        position: "",
        coverLetter: "",
        accept: false
    });

    // Popup
    const [popup, setPopup] = useState({ show: false, state: null, message: "" });

    // Recaptcha
    const [recaptchaError, setRecaptchaError] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState("");


    useEffect(() => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute("6LdvvlUqAAAAANRjjb-ZtQWsAiuqzZF6VO_quDwZ", { action: "submit" }).then((token) => setRecaptchaToken(token));
        });
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, phoneNumber, position, coverLetter, accept } = formData;

        // Name validation
        const nameValidation = validateName("name", name);
        if (nameValidation) {
            setPopup({
                show: true,
                state: "error",
                message: nameValidation
            });
            return;
        }

        // Email validation
        if (validateEmail(email)) {
            setPopup({
                show: true,
                state: "error",
                message: "Please provide a valid email"
            });
            return;
        }

        // Phone number validation
        const phoneValidation = validatePhoneNumber(phoneNumber);
        if (phoneValidation) {
            setPopup({
                show: true,
                state: "error",
                message: phoneValidation
            });
            return;
        }

        // Position validation
        if (!position.trim()) {
            setPopup({
                show: true,
                state: "error",
                message: "Please select the position you are interested in"
            });
            return;
        }

        // Cover letter validation
        const letterValidation = validateTextArea("cover letter", coverLetter, 700);
        if (letterValidation) {
            setPopup({
                show: true,
                state: "error",
                message: letterValidation
            });
            return;
        }

        // Resume file validation
        // const resumeValidation = validateResume(resume);
        // if (resumeValidation) {
        //     setPopup({
        //         show: true,
        //         state: "error",
        //         message: resumeValidation
        //     });
        //     return;
        // }

        // Privacy acceptance check
        if (!accept) {
            setPopup({
                show: true,
                state: "error",
                message: "Please accept the Privacy Policy before submitting"
            });
            return;
        }

        // Checking reCAPTCHA verification
        if (!recaptchaToken) {
            setRecaptchaError("Please complete the reCAPTCHA.");
            return;
        }

        try {
            const payload = {
                ...formData,
                name: name.trim(),
                phoneNumber: phoneNumber.trim(),
                position: position.trim(),
                coverLetter: coverLetter.trim(),
                "g-recaptcha-response": recaptchaToken
            };
            console.log("Submitting form with payload:", payload);

            const response = await fetch("https://formspree.io/f/mnnaekol", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                setFormData({
                    name: "",
                    email: "",
                    phoneNumber: "",
                    position: "",
                    coverLetter: "",
                    accept: false
                });
                setPopup({
                    show: true,
                    state: "success",
                    message: "Thank you! We’ll review your application and be in touch soon."
                });
            } else {
                throw new Error("Network response was not ok");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            setPopup({
                show: true,
                state: "error",
                message: "Something went wrong while submitting the form! Please try again or contact our support at contact@techieonix.com"
            });
        }
    };


    return (
        <>
            {/* Navbar */}
            <Navbar />




            {/* Hero */}
            <section className="career-hero-bg theme-section md:content-end md:h-[340px]">
                <div className="max-sm:pt-[110px] max-md:pt-[130px] max-md:p-5 sm:pb-10 md:px-10 xl:px-[--px-xl] space-y-2 text-white center">
                    <h1>Join Our Team</h1>
                    <h5>Build Your Future with Techieonix</h5>
                </div>
            </section>




            {/* Form */}
            <section className="px-5 sm:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 bg-[--blue-primary-10] theme-section">
                <div className="sm:p-8 lg:p-10 max-sm:space-y-10 max-md:space-y-6 md:flex gap-x-6 justify-between sm:bg-white rounded-xl center-inner">

                    {/* Title */}
                    <div className="md:w-1/2 lg:max-w-[468px] lg:pt-5 space-y-3 sm:space-y-6 max-sm:text-center">
                        <h1>Interested in Joining Us?</h1>
                        <p>While we are not actively hiring at the moment, we are always on the lookout for talented and passionate individuals to join our team. If you're interested in working with us, please submit your resume, and we'll keep it on file for future opportunities.</p>
                    </div>



                    {/* Form */}
                    <form className="p-5 lg:p-10 space-y-3 lg:space-y-4 md:w-1/2 lg:max-w-[474px] bg-white sm:bg-[--blue-primary-10] rounded-xl" onSubmit={handleSubmit}>
                        <input type="text" placeholder="Name*" id="name" name="name"
                            className="w-full input-default" maxLength={50} required
                            value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />

                        <input type="email" placeholder="Email*" id="email" name="email"
                            className="w-full input-default" required
                            value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />

                        <input type="tel" placeholder="Phone Number*" id="phoneNumber" name="phoneNumber"
                            className="w-full input-default" maxLength={17} required
                            value={formData.phoneNumber} onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                        />

                        <select name="position" id="position" className="w-full input-default"
                            onChange={(e) => setFormData({ ...formData, position: e.target.value })}
                        >
                            <option value="">-- Position Interested In --</option>
                            <option value="Full Stack Developer">Full Stack Developer</option>
                            <option value="DevOps Engineer">DevOps Engineer</option>
                            <option value="Data Scientist">Data Scientist</option>
                            <option value="Cloud Ops">Cloud Ops</option>
                            <option value="Solution Architect">Solution Architect</option>
                            <option value="UIUX Designer">UIUX Designer</option>
                            <option value="Site Reliability Engineer">Site Reliability Engineer</option>
                            <option value="ML Engineer">ML Engineer</option>
                            <option value="Python Developer">Python Developer</option>
                        </select>

                        {/* Cover Letter */}
                        <textarea placeholder="Write your cover letter here" id="coverLetter" name="coverLetter"
                            className="w-full input-default align-bottom" maxLength={700}
                            value={formData.coverLetter} onChange={(e) => setFormData({ ...formData, coverLetter: e.target.value })}
                        />

                        <p>Send your resume at contact@techieonix.io</p>


                        {/* Resume */}
                        {/* <div className="space-y-2">
                            <p className="caption tracking-normal text-[#232326b3]">Upload Resume Here*</p>

                            <div className="flex items-center gap-x-2">
                                <label htmlFor="resume" className="py-1 flex items-center gap-x-2 cursor-pointer w-fit">
                                    <span className="px-4 py-1 bg-[--blue-primary-10] hover:bg-[#232326cc] border border-[#27358166] hover:border-[#232326cc] hover:text-white rounded-full transition-colors">Choose file</span>
                                    <span className="select-none">{formData.resume ? formData.resume.name : "No file choosen"}</span>
                                </label>
                                <input type="file" accept=".pdf,.doc,.docx" name="resume" id="resume" className="hidden"
                                    onChange={(e) => setFormData({ ...formData, resume: e.target.files[0] })}
                                />
                            </div>

                            <p className="text-xs text-[--black-secondary]">Acceptable formats are <b>.docx</b> or <b>.pdf</b> with a maximum file size of <b>2MB</b></p>
                        </div> */}


                        {/* Privacy check */}
                        <div className="flex items-start gap-x-3 !mt-8">
                            <input type="checkbox" name="accept" id="accept" required
                                value={formData.accept} onChange={(e) => setFormData({ ...formData, accept: e.target.checked })}
                            />
                            <label htmlFor="accept" className="text-xs text-[--black-secondary]">
                                By sending this form I confirm that I have read and accept Techieonix <Link to="/privacy-policy" className="underline">Privacy Policy</Link>
                            </label>
                        </div>

                        <button type="submit" className="btn btn-blue w-full !mt-6 sm:w-40">Submit</button>

                        {recaptchaError && <p className="text-red-500 text-xs">{recaptchaError}</p>}
                    </form>
                </div>
            </section>




            {/* Tech Stack */}
            <TechStack isServicePage={false} />




            {/* Why Techieonix */}
            <section className="bg-[--black-primary] text-white theme-section">
                <div className="py-10 lg:py-[--py-lg] xl:py-20 px-5 md:px-10 xl:px-[--px-xl] space-y-10 center">
                    <h1 className="sm:px-8 md:px-10 xl:px-0 md:mx-auto xl:w-[--title-intro-width] text-center">Why Techieonix?</h1>


                    <div className="max-md:space-y-3 md:grid grid-cols-2 lg:grid-cols-4 gap-6">
                        <div className="p-5 space-y-4 sm:space-y-5 bg-[--white-10] lg:hover:bg-transparent ring-0 lg:hover:ring-1 ring-white rounded-[--round] transition-colors">
                            <h4>Innovative Projects</h4>
                            <p>Engage in cutting-edge projects that challenge and inspire you.</p>
                        </div>

                        <div className="p-5 space-y-4 sm:space-y-5 bg-[--white-10] lg:hover:bg-transparent ring-0 lg:hover:ring-1 ring-white rounded-[--round] transition-colors">
                            <h4>Career Growth</h4>
                            <p>Opportunities for advancement and professional development.</p>
                        </div>

                        <div className="p-5 space-y-4 sm:space-y-5 bg-[--white-10] lg:hover:bg-transparent ring-0 lg:hover:ring-1 ring-white rounded-[--round] transition-colors">
                            <h4>Inclusive Culture</h4>
                            <p>A diverse and welcoming workplace where every voice is heard.</p>
                        </div>

                        <div className="p-5 space-y-4 sm:space-y-5 bg-[--white-10] lg:hover:bg-transparent ring-0 lg:hover:ring-1 ring-white rounded-[--round] transition-colors">
                            <h4>Competitive Benefits</h4>
                            <p>Attractive compensation packages and benefits tailored to your needs.</p>
                        </div>
                    </div>
                </div>
            </section>





            {/* Life at Techieonix */}
            <section className="px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 space-y-10 xl:space-y-[54px] center theme-section">
                <div className="md:px-10 xl:p-0 xl:w-[--title-intro-width] space-y-6 text-center mx-auto">
                    <h1>Life at Techieonix</h1>
                    <p>At Techieonix, we believe that our people are our greatest asset. We foster a collaborative and inclusive environment where creativity and innovation thrive. Here’s what you can expect when you join our team.</p>
                </div>


                <div className="flex justify-center flex-wrap">
                    {lifeAtTechieonix.map((point, i) => (
                        <div key={i} className="p-6 space-y-5 w-[310px] lg_xl:w-[360px] shrink-0">
                            <img src={point.icon} alt={point.title} title={point.title} className="mx-auto" />

                            <div className="space-y-1.5 text-center">
                                <h5>{point.title}</h5>
                                <p>{point.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>





            {/* Footer */}
            <Footer />

            {/* Popup */}
            {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
        </>
    )
}