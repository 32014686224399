import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Popup } from "../Components";
import { validateEmail, validateName, validatePhoneNumber, validateTextArea } from "../utils/formvalidate";


export const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessEmail: "",
    businessPhone: "",
    company: "",
    message: "",
    accept: false
  });

  // Popup
  const [popup, setPopup] = useState({ show: false, state: null, message: "" });

  // Recaptcha
  const [recaptchaError, setRecaptchaError] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");


  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LdvvlUqAAAAANRjjb-ZtQWsAiuqzZF6VO_quDwZ", { action: "submit" }).then((token) => setRecaptchaToken(token));
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, businessEmail, businessPhone, message, accept } = formData;

    // Validating first and last names
    const firstNameValidation = validateName("first name", firstName);
    const lastNameValidation = validateName("last name", lastName);
    if (firstNameValidation || lastNameValidation) {
      setPopup({
        show: true,
        state: "error",
        message: firstNameValidation || lastNameValidation
      });
      return;
    }

    // Email validation
    if (validateEmail(businessEmail)) {
      setPopup({
        show: true,
        state: "error",
        message: "Please provide a valid email"
      });
      return;
    }

    // Validating Phone Number
    if (businessPhone && businessPhone.trim() !== "") {
      const phoneValidation = validatePhoneNumber(businessPhone);
      if (phoneValidation) {
        setPopup({
          show: true,
          state: "error",
          message: phoneValidation
        });
        return;
      }
    }

    // Message validation
    const messageValidation = validateTextArea("message", message, 400);
    if (messageValidation) {
      setPopup({
        show: true,
        state: "error",
        message: messageValidation
      });
      return;
    }

    // Checking whether the checkbox for accepting the Privacy Policy has been checked
    if (!accept) {
      setPopup({
        show: true,
        state: "error",
        message: "Please accept the Privacy Policy before submitting"
      });
      return;
    }

    // Checking reCAPTCHA verification
    if (!recaptchaToken) {
      setRecaptchaError("Please complete the reCAPTCHA.");
      return;
    }

    try {
      const payload = {
        ...formData,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        "g-recaptcha-response": recaptchaToken
      };
      console.log("Submitting form with payload:", payload);

      const response = await fetch("https://formspree.io/f/mnnaekol", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        setFormData({
          firstName: "",
          lastName: "",
          businessEmail: "",
          businessPhone: "",
          company: "",
          message: "",
          accept: ""
        });
        setPopup({
          show: true,
          state: "success",
          message: "Form submitted successfully!"
        });
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setPopup({
        show: true,
        state: "error",
        message: "Something went wrong while submitting the form! Please try again or contact our support at contact@techieonix.com"
      });
    }
  };


  return (
    <>
      <section className="px-5 sm:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 bg-[--blue-primary-10] theme-section">
        <div className="sm:p-8 lg:p-10 max-sm:space-y-10 max-md:space-y-6 md:flex gap-x-6 justify-between sm:bg-white rounded-xl center-inner">

          {/* Title */}
          <div className="md:w-1/2 lg:max-w-[468px] lg:pt-5 space-y-3 sm:space-y-6 max-sm:text-center">
            <h1>Lets connect and explore possibilities</h1>
            <p>Ready to transform your business with innovative IT solutions? Get in touch with us today. Our team is here to answer your questions and discuss how we can help you achieve your goals.</p>
          </div>



          {/* Form */}
          <form className="p-5 lg:p-10 space-y-3 lg:space-y-4 md:w-1/2 lg:max-w-[474px] bg-white sm:bg-[--blue-primary-10] rounded-xl" onSubmit={handleSubmit}>
            <input type="text" placeholder="First Name*" id="firstName" name="firstName"
              className="input-default w-full" maxLength={50} required
              value={formData.firstName} onChange={handleChange}
            />

            <input type="text" placeholder="Last Name*" id="lastName" name="lastName"
              className="input-default w-full" maxLength={50} required
              value={formData.lastName} onChange={handleChange}
            />

            <input type="email" placeholder="Business Email*" id="businessEmail" name="businessEmail"
              className="input-default w-full" required
              value={formData.businessEmail} onChange={handleChange}
            />

            <input type="tel" placeholder="Business Phone" id="businessPhone" name="businessPhone"
              className="input-default w-full" maxLength={16}
              value={formData.businessPhone} onChange={handleChange}
            />

            <input type="text" placeholder="Company" id="company" name="company"
              className="input-default w-full"
              value={formData.company} onChange={handleChange}
            />

            <textarea placeholder="Tell us about your project*" id="message" name="message"
              className="input-default w-full align-bottom" maxLength={400} required
              value={formData.message} onChange={handleChange}
            />

            <div className="flex items-start gap-x-3">
              <input type="checkbox" name="accept" id="accept" required onChange={handleChange} checked={formData.accept} />
              <label htmlFor="accept" className="text-xs text-[--black-secondary]">
                By sending this form I confirm that I have read and accept Techieonix <Link to="/privacy-policy" className="underline">Privacy Policy</Link>
              </label>
            </div>

            <button type="submit" className="btn btn-blue w-full !mt-8 sm:w-40">Send</button>

            {recaptchaError && <p className="text-red-500 text-xs">{recaptchaError}</p>}
          </form>
        </div>
      </section>



      {/* Popup */}
      {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
    </>
  );
};